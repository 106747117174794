import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ValueOf } from '../@core/domain/entities/common/Common';
import { setInternetConnection } from '../components/utils/actions/serverActions';

enum ConnectionStatus {
  online = 'ONLINE',
  offline = 'OFFLINE',
}

function getNavigatorOnlineString(): ValueOf<ConnectionStatus> {
  return window.navigator.onLine ? ConnectionStatus.online : ConnectionStatus.offline;
}

export function useNavigatorOnline() {
  const [status, setStatus] = useState<ValueOf<ConnectionStatus>>(ConnectionStatus.online);

  const dispatch = useDispatch();

  const handleOnlineStatus = useCallback(() => {
    const connectionStatus = getNavigatorOnlineString();
    setStatus(connectionStatus);
    dispatch(setInternetConnection(connectionStatus));
  },[dispatch]);

  useEffect(() => {
    const connectionStatus = getNavigatorOnlineString();
    if (connectionStatus !== status) {
      handleOnlineStatus();
    }

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, [status, setStatus, handleOnlineStatus]);

  return status;
}
