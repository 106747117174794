import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authSelector, companiesSelector } from '../../../@core/infrastructure/redux/selectors';
import { isAdmin } from '../../utils/functions';

const withCompanySetter = (Component) => (props) => {
  function WrappedComponent() {
    const [CID, setCID] = useState(null);

    const { list: companies } = useSelector(companiesSelector);
    const { user } = useSelector(authSelector);

    const isCompaniesLoaded = companies.length > 0;

    const getCurrentCompany = useCallback(() => {
      if (!isCompaniesLoaded) {
        return;
      }

      if (isAdmin()) {
        setCID(companies[0].id);
      } else if (user && user.employee) {
        setCID(user.employee.current_company);
      }
    }, [isCompaniesLoaded, companies, user]);

    const handleSetCID = (id) => {
      setCID(id);
    };

    useEffect(() => {
      getCurrentCompany();
    }, [getCurrentCompany]);

    if (!isCompaniesLoaded) {
      return null;
    }

    return <Component {...props} CID={CID} companies={companies} handleSelectCompany={handleSetCID} />;
  }

  return <WrappedComponent />;
};

export default withCompanySetter;
