import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, withWidth, FormControl, FormLabel, Switch } from '@material-ui/core';

import GridItemAnimated from '../utils/GridItemAnimated';
import { currencyFormatDE, format_number } from '../utils/currency';
import PreloaderOverlay from '../utils/PreloaderOverlay';
import { filterJournal, updateCompaniesData, setCompaniesData } from '../utils/functions';
import { analyticsSelector, companiesSelector } from '../../@core/infrastructure/redux/selectors';

import { SalesChart } from './SalesChart';
import withCompanySetter from './hoc/withCompanySetter';
import Compare from './Compare';
import { setLoadJournalCompare } from './actions/analyticsActions';
import SalesCompaniesChart from './SalesCompanyChart';
import CompaniesPieChart from './CompaniesPieChart';
import Periodicity from './Periodicity';
import ProfitMarginBox from './ProfitMarginBox';

function SalesReport(props) {
  const [periodicity, setPeriodicity] = useState('monthly');

  const { list: companies } = useSelector(companiesSelector);
  const analytics = useSelector(analyticsSelector);

  const dispatch = useDispatch();

  const { loadCompare, currentRange } = analytics;
  const [rangeStart, rangeEnd] = currentRange;
  const { width } = props;

  const handleChangePeriodicity = (e) => {
    setPeriodicity(e.target.value);
  };

  const handleChangeShowCompare = () => {
    dispatch(setLoadJournalCompare(!loadCompare));
  };

  useEffect(() => {
    if (moment(rangeStart).month() !== moment(rangeEnd).month() && periodicity !== 'monthly') setPeriodicity('monthly');
  }, [rangeStart, rangeEnd, periodicity]);

  if (!analytics.journal) {
    return null;
  }

  const data = filterJournal(analytics.journal);
  let journal = [];
  let journalCalc = [];
  let journalDayFormatText;
  let totalsTitle;
  let totalsSubtitle = null;

  if (!loadCompare) {
    delete data.compare;
  }

  journal = [...setCompaniesData(data, periodicity)];
  if (data.current.find((item) => item.sum_sales > 0 || item.entries > 0)) {
    journalCalc = [...setCompaniesData(data, periodicity, true)];
  } else {
    journalCalc = { ...journal };
  }

  switch (periodicity) {
    case 'monthly':
      journalDayFormatText = 'por mês';
      totalsTitle = 'Total do período';
      break;

    case 'weekly':
      journalDayFormatText = 'por semana';
      totalsTitle = 'Total do período';
      totalsSubtitle = '(acumulado de semanas)';
      break;

    default:
      journalDayFormatText = 'por dia';
      totalsTitle = 'Total do período';
      break;
  }

  let totalsItems = {
    totalGoal: 0,
    totalSales: 0,
    workDays: 0,
    totalVisits: 0,
    avgDailySales: 0,
    avgDailyVisits: 0,
    efficiency: 0,
    avgDailyEfficiency: 0,
  };
  let totals = {
    current: { ...totalsItems },
    compare: { ...totalsItems },
  };

  Object.values(journalCalc).forEach((item) => {
    Object.keys(totals).forEach((period) => {
      if (item[period]) {
        let parseNames = {
          totalGoal: 'goal',
          totalSales: 'sum_sales',
          totalSalesCost: 'sum_sales_cost',
          totalSalesNet: 'sum_sales_net',
          totalVisits: 'entries',
        };
        Object.entries(parseNames).forEach(([name, fieldName]) => {
          if (!totals[period][name]) totals[period][name] = 0;
          totals[period][name] += item[period].reduce((total, it) => {
            return total + it[fieldName];
          }, 0);
        });

        totals[period]['workDays'] += 1;
        totals[period]['avgDailySales'] = totals[period]['totalSales'] / totals[period]['workDays'];
        totals[period]['avgDailyVisits'] = totals[period]['totalVisits'] / totals[period]['workDays'];
        totals[period]['efficiency'] = totals[period]['totalSales'] / totals[period]['totalVisits'];
        totals[period]['profitMargin'] =
          totals[period]['totalSalesNet'] !== 0 &&
          totals[period]['totalSalesNet'] - totals[period]['totalSalesCost'] !== 0
            ? ((totals[period]['totalSalesNet'] - totals[period]['totalSalesCost']) / totals[period]['totalSalesNet']) *
              100
            : 0;
      }
    });
  });

  /**
   * Update Companies Data
   */
  let journalCompaniesBars = [];
  let journalCompaniesPie = [];

  /** BARS */
  Object.values(journalCalc).forEach((item) => {
    let index = journalCompaniesBars.push({
      xAxis: item.xAxis,
    });
    index--;
    Object.keys(item).forEach((period) => {
      if (['current', 'compare'].includes(period))
        updateCompaniesData(index, period, item, companies, journalCompaniesBars, journalCompaniesPie);
    });
  });

  /** PIE */
  journalCompaniesPie = journalCompaniesPie.map((item) => {
    Object.keys(item).forEach((period) => {
      if (['current', 'compare'].includes(period)) {
        item[period]['pct'] = (item[period]['amount'] / totals[period]['totalSales']) * 100;

        item[period]['profitMarginPct'] =
          item[period]['amountNet'] - item[period]['amountCost'] > 0
            ? format_number(
                ((item[period]['amountNet'] - item[period]['amountCost']) / item[period]['amountNet']) * 100
              )
            : 0;
      }
    });
    return item;
  });

  return (
    <div className="panel">
      <div className="panel__header">
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={12} sm="auto">
            <h1 className="panel__title">Relatórios do período</h1>
          </Grid>
        </Grid>
      </div>
      <div className="panel__body">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <Periodicity periodicity={periodicity} handleChangePeriodicity={handleChangePeriodicity} />
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Mostrar período anterior</FormLabel>
                  <Switch checked={loadCompare} onChange={handleChangeShowCompare} color="primary" />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={3} direction={`${['xs', 'sm'].includes(width) ? 'column-reverse' : 'row'}`}>
                    <GridItemAnimated index={2} xs={12} md={7} xl={8}>
                      {analytics.isLoading && <PreloaderOverlay />}
                      <div>
                        <SalesChart data={journal} />
                        <SalesCompaniesChart data={journalCompaniesBars} />
                      </div>
                    </GridItemAnimated>
                    <GridItemAnimated index={3} xs={12} md={5} xl={4}>
                      <div className="panel__body">
                        {analytics.isLoading && <PreloaderOverlay />}
                        <Grid container spacing={5}>
                          {/* VENDAS */}
                          <Grid item xs={12} className="--ta-center">
                            <Grid container justifyContent="center" spacing={3}>
                              {/* totals  */}
                              <Grid item xs={12} className="--ta-center">
                                <p className="--nomargin">{totalsTitle}</p>
                                {totalsSubtitle && <p className="small --nomargin">{totalsSubtitle}</p>}
                              </Grid>
                              <Grid item xs={12} className="--ta-center">
                                <Grid container justifyContent="center" spacing={3}>
                                  <Grid item xs="auto">
                                    <Compare
                                      justifyContent="center"
                                      value={totals['current']['totalSales']}
                                      compare={totals['compare']['totalSales']}
                                      valueComponent={
                                        <p
                                          className="--nomargin bigger --t-primary"
                                          title={currencyFormatDE(totals['current']['totalSales'], false)}
                                        >
                                          <b>{currencyFormatDE(totals['current']['totalSales'], true, 0, true)}</b>
                                        </p>
                                      }
                                    />
                                    <p className="small --t-bold text-mutted">vendas</p>
                                  </Grid>
                                  <Grid item xs="auto">
                                    <p
                                      className="--nomargin bigger"
                                      title={currencyFormatDE(totals['current']['totalGoal'], false)}
                                    >
                                      <b>{currencyFormatDE(totals['current']['totalGoal'], true, 0, true)}</b>
                                    </p>
                                    <p className="small --t-bold text-mutted">objectivo</p>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* average */}
                              <Grid item xs={12} className="--ta-center">
                                <Grid container justifyContent="center" spacing={3}>
                                  <Grid item>
                                    <p
                                      className="--nomargin --t-bold"
                                      title={currencyFormatDE(totals['current']['avgDailySales'], false)}
                                    >
                                      {currencyFormatDE(totals['current']['avgDailySales'], true, 0, true)}
                                    </p>
                                    <p className="small --t-bold text-mutted">{journalDayFormatText}</p>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <ProfitMarginBox totals={totals} />
                              <Grid item xs={12} className="--ta-center">
                                <CompaniesPieChart data={journalCompaniesPie} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </GridItemAnimated>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withCompanySetter(withWidth()(SalesReport));
