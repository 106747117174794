import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Typography, TextField, MenuItem, Paper } from '@material-ui/core'

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}
function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          style: { display: 'flex', padding: 0 },
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  )
}
function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}
function Menu(props) {
  return (
    <Paper
      square
      {...props.innerProps}
      style={{
        position: 'absolute',
        zIndex: 9,
        marginTop: 5,
        left: 0,
        right: 0,
      }}
    >
      {props.children}
    </Paper>
  )
}
function ValueContainer(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {props.children}
    </div>
  )
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      style={{ position: 'absolute', left: 2, fontSize: 16 }}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

function SingleValue(props) {
  return (
    <Typography {...props.innerProps} style={{ fontSize: 16 }}>
      {props.children}
    </Typography>
  )
}
const selectComponents = {
  Control,
  Menu,
  Option,
  ValueContainer,
  Placeholder,
  SingleValue,
}

const SearchComponent = ({ component: Component, ...props }) => (
  <Component {...props} />
)

const SearchSelect = ({
  isCreatable = true,
  name,
  options,
  value,
  label,
  placeholder,
  handleOnChange,
  handleOnInputChange,
  handleCreate,
  isLoading,
  isDisabled,
}) => {
  const innerProps = {
    components: selectComponents,
    placeholder: placeholder,
    isClearable: true,
    textFieldProps: {
      label: label,
      InputLabelProps: {
        shrink: true,
      },
    },
    name: name,
    value: value,
    options: options,
    onChange: handleOnChange,
    // onInputChange:{handleOnInputChange}
    isDisabled: isDisabled ? isDisabled : isLoading,
    isLoading: isLoading,
  }
  return (
    <SearchComponent
      component={isCreatable ? CreatableSelect : Select}
      {...innerProps}
    />
  )
}

export default SearchSelect
