import { API } from '../../constants/company';
import { CompaniesAPI } from '../infrastructure/interfaces/CompaniesAPI';
import { Adapter } from '../infrastructure/Adapter';

import Gateway from './Gateway';

export class CompaniesHttpGateway implements Gateway {
  baseUrl = API;
  httpClient = Adapter;

  async get(): Promise<CompaniesAPI> {
    try {
      return await this.httpClient.get(this.baseUrl);
    } catch (error) {
      console.log(error);
    }
    return {} as CompaniesAPI;
  }
}
