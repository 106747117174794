const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  userCreated: null,
  recoverPasswordSent: false,
  passwordChanged: false,
  isLoading: false,
  errors: null,
  user: null,
}

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOADED':
      return {
        ...state,
        isLoading: false,
        errors: null,
        user: action.payload,
      }
    case 'SENDING_PASSWORD_RECOVER':
      return {
        ...state,
        isLoading: true,
        errors: null,
        recoverPasswordSent: false,
      }
    case 'PASSWORD_RECOVER_SENT':
      return {
        ...state,
        isLoading: false,
        errors: null,
        recoverPasswordSent: true,
      }
    case 'PASSWORD_RECOVER_FAIL':
      return {
        ...state,
        isLoading: false,
        errors: {
          email: 'Email não encontrado.',
        },
      }
    case 'SETTING_NEW_PASSWORD':
      return {
        ...state,
        isLoading: true,
        errors: null,
        passwordChanged: false,
      }

    case 'LOGIN_START':
      return {
        ...initialState,
        isLoading: true,
      }

    case 'LOGIN_SUCCESSFUL':
      localStorage.setItem('token', action.payload.key)
      return {
        ...state,
        token: action.payload.key,
        isAuthenticated: true,
        isLoading: false,
        errors: null,
        passwordChanged: false,
      }

    case 'PASSWORD_CHANGE_SUCCESS':
      return {
        ...state,
        isLoading: false,
        passwordChanged: true,
        errors: null,
      }
    case 'PASSWORD_CHANGE_FAIL':
      return {
        ...state,
        isLoading: false,
        passwordChanged: false,
        errors: action.errors,
      }

    case 'AUTHENTICATION_ERROR':
      localStorage.removeItem('token')
      return {
        ...state,
        errors: {
          email: 'Credenciais inválidas.',
          password: 'Credenciais inválidas.',
        },
        isAuthenticated: false,
        isLoading: false,
        token: null,
        passwordChanged: false,
      }
    case 'LOGOUT':
      localStorage.removeItem('token')
      return {
        ...initialState,
      }

    default:
      return state
  }
}
