import React from 'react'
import {
  ArrowUpward as UpIcon,
  ArrowDownward as DownIcon,
  Info as InfoIcon,
} from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'
import { format_number } from '../utils/currency'
import { getPct } from '../utils/functions'

const CompareIcon = (value, compare) => {
  let icon
  switch (true) {
    case value > compare:
      icon = <UpIcon className="--t-success" />
      break
    case value < compare:
      icon = <DownIcon className="--t-alert" />
      break
    default:
      icon = <InfoIcon style={{ color: '#dedede' }} />
      break
  }
  return <div className="compare__icon">{icon}</div>
}

const Compare = ({
  valueComponent,
  compareComponent,
  tooltipComponent,
  value = 0,
  valueString = null,
  compare = 0,
  compareString = null,
  justify = 'flex-start',
  children,
}) => {
  value = value > 0 ? value : null
  compare = compare > 0 ? compare : null
  const differencePct =
    value && compare && value !== compare ? getPct(value, compare) : null
  return (
    <div className={`compare ${justify ? '--justify-' + justify : ''}`}>
      <div className="compare__item">
        {valueComponent ? (
          valueComponent
        ) : (
          <b>{valueString ? valueString : value ? value : '-'}</b>
        )}
      </div>
      {compare ? (
        compareComponent ? (
          compareComponent
        ) : (
          <div className="compare__item">
            <Tooltip
              placement="top"
              enterTouchDelay={100}
              title={
                tooltipComponent ? (
                  tooltipComponent
                ) : (
                  <div className="tooltip">{`Anterior: ${
                    compareString ? compareString : format_number(compare, 2)
                  }`}</div>
                )
              }
            >
              <div>
                {CompareIcon(
                  format_number(value, 2, true),
                  format_number(compare, 2, true)
                )}
                {differencePct && (
                  <span className="compare__pct">
                    {format_number(differencePct, 1)}%
                  </span>
                )}
              </div>
            </Tooltip>
          </div>
        )
      ) : null}
    </div>
  )
}

export default Compare
