import { updateLoadingStatus } from '../../utils/reducers'
const initialState = {
  isLoading: false,
  entries: [],
  categories: [],
  wallets: [],
  accounts: [],
  errors: null,
  successMessage: null,
}

export default function cashflowReducer(
  state = JSON.parse(JSON.stringify(initialState)),
  action
) {
  switch (action.type) {
    case 'ENTRIES_LOAD_SUCCESS':
      state.entries = action.payload
      break
    case 'CATEGORIES_LOAD_SUCCESS':
      state.categories = action.payload
      break
    case 'ACCOUNTS_LOAD_SUCCESS':
      state.accounts = action.payload
      break
    case 'WALLETS_LOAD_SUCCESS':
      state.wallets = action.payload
      break

    default:
      break
  }

  updateLoadingStatus(['ENTRIES', 'CATEGORIES', 'ACCOUNTS'], state, action)
  return { ...state }
}
