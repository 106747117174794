import { useState } from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Attachment, Comment } from '@material-ui/icons';

import { currencyFormatDE } from '../../utils/currency';
import { Sale } from '../../../@core/domain/entities/Sale';
import { SageSales } from './SageSales';
import { ToggleExpandIcon } from './ExpandIcon';

type SaleProps = {
  sale: Sale;
  handleOpenLightBox: Function;
};
export function SaleByShift({ sale, handleOpenLightBox }: SaleProps) {
  const [isSageSalesExpanded, setIsSageSalesExpanded] = useState(false);

  const { id, shift, sellers, thumb, comments, amount, sale_list } = sale;

  const hasSageSales = sale_list.length > 0;

  return (
    <Grid key={id} item xs={12} className="journal__sale">
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
        {/* Employee and shift */}
        <Grid item>
          {shift && <Typography variant="body2">{shift.name}</Typography>}
          {sellers && (
            <Typography variant="caption">{sellers.map((seller) => seller.first_name).join(', ')}</Typography>
          )}
        </Grid>
        {/* Photo */}
        <Grid item>
          {thumb && (
            <IconButton className="sale__icon" onClick={() => handleOpenLightBox(thumb)}>
              <Attachment />
            </IconButton>
          )}
        </Grid>
        {/* Comments */}
        <Grid item>
          {comments && (
            <Tooltip placement="top" enterTouchDelay={100} title={<div className="tooltip">{comments}</div>}>
              <IconButton className="sale__icon">
                <Comment />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {/* Amount */}
        <Grid item>
          <Grid container alignItems="center">
            <Grid>
              <Typography variant="body2">{currencyFormatDE(amount, true)}</Typography>
            </Grid>
            <Grid>
              <ToggleExpandIcon
                isExpanded={isSageSalesExpanded}
                setIsExpanded={setIsSageSalesExpanded}
                isDisabled={!hasSageSales}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SageSales saleList={sale_list} isExpanded={isSageSalesExpanded} />
    </Grid>
  );
}
