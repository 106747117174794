import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Grid } from '@material-ui/core'
import SalesAccumulated from '../../../components/Analytics/SalesAccumulated'
import SalesHomeChart from '../../../components/Analytics/SalesHomeChart'
import PreloaderOverlay from '../../../components/utils/PreloaderOverlay'
import MonthSalesList from '../../../components/Analytics/MonthSalesList/MonthSalesList'
import GridItemAnimated from '../../../components/utils/GridItemAnimated'

class Home extends Component {
  render() {
    const { analytics } = this.props

    if (!analytics.journal) return <div />
    let currentData = analytics.journal.current.filter((item) =>
      moment(item.date).isBetween(
        analytics.currentRange[0],
        analytics.currentRange[1],
        null,
        '[]'
      )
    )

    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={7} xl={8}>
          <Grid container spacing={4}>
            <GridItemAnimated index={2} xs={12}>
              {analytics.isLoading && <PreloaderOverlay />}
              <SalesHomeChart data={currentData} />
            </GridItemAnimated>
            <GridItemAnimated index={3} xs={12}>
              {analytics.isLoading && <PreloaderOverlay />}
              <MonthSalesList data={currentData} />
            </GridItemAnimated>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5} xl={4}>
          <Grid container spacing={4}>
            <GridItemAnimated index={4} xs={12}>
              {analytics.isLoadingAccumulated && <PreloaderOverlay />}
              <SalesAccumulated />
            </GridItemAnimated>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    analytics: state.analytics,
  }
}

export default connect(mapStateToProps)(Home)
