import { Redirect } from 'react-router-dom'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Grid, Button } from '@material-ui/core'
import {
  MailOutlined as MailIcon,
  LockOutlined as PassIcon,
} from '@material-ui/icons'

import Base from './Base'
import CustomInput from '../../components/utils/CustomInput'
import Preloader from '../../components/utils/Preloader'

import {
  login,
  sendPasswordRecover,
} from '../../components/Auth/actions/authActions'

class LoginPage extends Component {
  state = {
    errors: [],
    email: '',
    password: '',
    password_confirm: '',
    passwordRecoverSent: false,
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.login(this.state.email, this.state.password)
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  hanldeSendPasswordRecovery = () => {
    this.props.sendPasswordRecover(this.state.email)
  }

  render() {
    const { from } = this.props.location.state ?? { pathname: '/' },
      { auth } = this.props

    if (this.props.isAuthenticated) return <Redirect to={from} />

    return (
      <Base>
        {auth.isLoading ? (
          <div className="--ta-center animated fadeIn">
            <Preloader />
          </div>
        ) : this.props.auth.recoverPasswordSent === true ? (
          <div className="--ta-center animated fadeIn">
            <p>
              Enviámos para o seu email, instruções para redefinir a sua senha.
            </p>
            <p>
              Caso não encontre o email na Caixa de Entrada verifique as caixas
              de SPAM e Lixo Eletrônico.
            </p>
          </div>
        ) : (
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={5} direction="column">
              <Grid
                item
                className="animated fadeInUp fast"
                style={{ animationDelay: `0.1s` }}
              >
                <CustomInput
                  name="email"
                  type="email"
                  label="Email"
                  value={this.state.email}
                  handleChange={this.handleChange}
                  errors={this.props.errors}
                  icon={<MailIcon />}
                  required
                  autoFocus={true}
                  className="login__input"
                />
              </Grid>
              <Grid
                item
                className="animated fadeInUp fast"
                style={{ animationDelay: `0.2s` }}
              >
                <CustomInput
                  name="password"
                  type="password"
                  label="Palavra-passe"
                  value={this.state.password}
                  handleChange={this.handleChange}
                  errors={this.props.errors}
                  icon={<PassIcon />}
                  required
                  className="login__input"
                />
              </Grid>
              <Grid
                item
                className="--ta-center animated fadeInUp fast"
                style={{ animationDelay: `0.3s` }}
              >
                <Button
                  className="action-btn"
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Aceder
                </Button>
              </Grid>
              <Grid
                item
                className="--ta-center animated fadeInUp fast"
                style={{ animationDelay: `0.4s` }}
              >
                <small
                  className="link --t-primary"
                  onClick={this.hanldeSendPasswordRecovery}
                >
                  Esqueci / Redefinir minha senha
                </small>
              </Grid>
            </Grid>
          </form>
        )}
      </Base>
    )
  }
}
const mapStateToProps = (state) => {
  let errors = {}
  if (state.auth.errors) {
    Object.keys(state.auth.errors).map(
      (field) => (errors[field] = state.auth.errors[field])
    )
  }

  return {
    server: state.server,
    auth: state.auth,
    errors,
    isAuthenticated: state.auth.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    sendPasswordRecover: (email) => dispatch(sendPasswordRecover(email)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage)
