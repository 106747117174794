import { Collapse, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';

import { SageSale } from '../../../@core/domain/entities/SageSale';
import { currencyFormatDE } from '../../utils/currency';

type SageSalesProps = {
  saleList: SageSale[];
  isExpanded: boolean;
};

export function SageSales({ saleList, isExpanded }: SageSalesProps) {
  return (
    <Grid item xs={12} className="--ta-right">
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <div style={{ overflowX: 'auto', marginTop: 16 }}>
          <Table size="small">
            <TableHead>
              <TableCell style={{ fontSize: 10 }} title="transdocument_id">
                TDID
              </TableCell>
              <TableCell style={{ fontSize: 10 }} title="transserial_id">
                TSID
              </TableCell>
              <TableCell style={{ fontSize: 10 }} title="transdocnumber">
                TDN
              </TableCell>
              <TableCell style={{ fontSize: 10 }} title="systementrydate">
                Data
              </TableCell>
              <TableCell style={{ fontSize: 10, textAlign: 'right' }} title="subtotal_cost_amount">
                Custo
              </TableCell>
              <TableCell style={{ fontSize: 10, textAlign: 'right' }} title="subtotal_net_amount">
                Líquido
              </TableCell>
              <TableCell style={{ fontSize: 10, textAlign: 'right' }} title="profit_margin_pct">
                ML %
              </TableCell>
              <TableCell style={{ fontSize: 10, textAlign: 'right' }} title="subtotal_amount">
                Venda
              </TableCell>
            </TableHead>
            <TableBody>
              {saleList.map((sageSale, index: number) => (
                <SageSaleRow key={index} sale={sageSale} />
              ))}
            </TableBody>
          </Table>
        </div>
      </Collapse>
    </Grid>
  );
}

function SageSaleRow({ sale }: { sale: SageSale }) {
  const {
    profit_margin_pct,
    subtotal_amount,
    subtotal_cost_amount,
    subtotal_net_amount,
    systementrydate,
    transdocnumber,
    transdocument_id,
    transserial_id,
  } = sale;

  return (
    <TableRow key={transdocnumber}>
      <TableCell style={{ fontSize: 10 }}>{transdocument_id}</TableCell>
      <TableCell style={{ fontSize: 10 }}>{transserial_id}</TableCell>
      <TableCell style={{ fontSize: 10 }}>{transdocnumber}</TableCell>
      <TableCell style={{ fontSize: 10 }}>{moment(systementrydate).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
      <TableCell style={{ fontSize: 10, textAlign: 'right' }}>
        {currencyFormatDE(subtotal_cost_amount, false)}
      </TableCell>
      <TableCell style={{ fontSize: 10, textAlign: 'right' }}>{currencyFormatDE(subtotal_net_amount, false)}</TableCell>
      <TableCell style={{ fontSize: 10, textAlign: 'right' }}>{currencyFormatDE(profit_margin_pct, false)}</TableCell>
      <TableCell style={{ fontSize: 10, textAlign: 'right' }}>{currencyFormatDE(subtotal_amount, false)}</TableCell>
    </TableRow>
  );
}
