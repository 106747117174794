import React from 'react'
import { connect } from 'react-redux'
import 'moment/locale/pt'
import CustomComposedChart from './CustomComposedChart'
import { Bar } from 'recharts'

const SalesCompaniesChart = ({ data, companies }) => {
  let companiesBars = [],
    periods = ['current']
  if (data.find((item) => 'compare' in item)) periods.unshift('compare')

  Object.values(companies).forEach((company) => {
    periods.forEach((period) => {
      let color = company.bg_color,
        name = company.name
      if (period === 'compare') {
        color = '#999'
        name += ' (anterior)'
      }
      companiesBars.push(
        <Bar
          key={`${period}.${company.id}`}
          name={name}
          dataKey={`${period}.${company.id}.sum_sales`}
          fill={color}
          stackId={`${period}.${company.id}.sum_sales`}
          unit="€"
        />
      )
      companiesBars.push(
        <Bar
          key={`${period}.${company.id}-2`}
          name={period === 'compare' ? 'ML (anterior)' : 'ML'}
          dataKey={`${period}.${company.id}.profit_pct`}
          fill={period === 'compare' ? '#999' : '#6ae490'}
          stackId={`${period}.${company.id}.sum_sales`}
          unit="%"
        />
      )
    })
  })

  return (
    <CustomComposedChart data={data} syncId="sales">
      {[...companiesBars]}
    </CustomComposedChart>
  )
}

const mapStateToProps = (state) => {
  return {
    companies: state.companies.list,
  }
}
export default connect(mapStateToProps)(SalesCompaniesChart)
