export const currencyFormatDE = (
  num,
  sign = true,
  fixed = 2,
  round = false,
  neg = '-',
  trailneg = ''
) => {
  if (num === 0) return 0
  if (!num) return '-'

  num = format_number(num, fixed, false, '.', ',', round)

  if (neg !== '-' && num.includes('-')) {
    num = num.replace('-', neg) + trailneg
  }

  return `${sign === true ? '€ ' : ''}` + num
}

export const format_number = (
  num,
  decimalPlaces = 2,
  returnNumeric = false,
  thousandSep = '.',
  decimalSep = ',',
  round = false
) => {
  if (!num) return 0
  if (typeof num === 'string') {
    // Find and remove the thousand separator in order to parseFloat
    let reThousandSep = new RegExp(`\\${thousandSep}`, 'g')
    let reDecimalSep = new RegExp(`\\${decimalSep}`, 'g')
    let countThousand = (num.match(reThousandSep) || []).length
    let countDecimal = (num.match(reDecimalSep) || []).length

    if (
      countThousand > countDecimal ||
      num.indexOf(thousandSep) < num.indexOf(decimalSep)
    )
      num = num.replace(reThousandSep, '')
    else num = num.replace(reDecimalSep, '')

    // Just making sure the decimal is dot
    num = num.replace(',', '.')

    num = parseFloat(num)
  }

  if (round) num = Math.round(num)

  num = num.toFixed(decimalPlaces)

  // Parse to float again if needs numeric return
  if (returnNumeric) return parseFloat(num)

  return num
    .replace(thousandSep, decimalSep)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandSep}`)
}
