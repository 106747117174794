import React from 'react'
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { currencyFormatDE } from '../utils/currency'
import { Grid } from '@material-ui/core'
import Compare from './Compare'

const ShiftsPieChart = ({ data }) => {
  let tempCurrent = data
      .filter((item) => item.current)
      .sort((a, b) => b.current.amount - a.current.amount),
    tempCompare = data
      .filter((item) => !item.current)
      .sort((a, b) => b.compare.amount - a.compare.amount)
  if (tempCurrent.length > 0) data = [...tempCurrent]
  if (tempCurrent.length > 0 && tempCompare.length > 0)
    data.push(...tempCompare)
  if (!tempCurrent.length > 0 && tempCompare.length > 0) data = [...tempCompare]

  const pieChart = (
    <div
      className="chart-wrapper"
      style={{ width: 120, height: 120, margin: '0 auto' }}
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            dataKey="current.amount"
            nameKey="data.first_name"
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={60}
            fill="#82ca9d"
          >
            {Object.values(data).map((item, index) => (
              <Cell
                key={index}
                fill={item.shift.bg_color ? item.shift.bg_color : '#82ca9d'}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )

  const shiftsList = (
    <div className="list">
      {Object.values(data).map((item, index) => {
        let currentAmount = item.current && item.current.amount,
          compareAmount = item.compare && item.compare.amount,
          currentPct = item.current && item.current.pct

        return (
          <div key={index} className="list__item">
            <div className="content">
              {item.shift.bg_color && (
                <span
                  className="item__chip"
                  style={{
                    backgroundColor: item.shift.bg_color,
                  }}
                />
              )}

              {currentPct && (
                <span className="item__text">{`${currencyFormatDE(
                  currentPct,
                  false,
                  1
                )}%`}</span>
              )}

              <span className="item__text">{item.shift.name}</span>
              <span className="item__text">
                <Compare
                  value={currentAmount}
                  valueString={currencyFormatDE(currentAmount, true, 0, true)}
                  compare={compareAmount}
                />
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {pieChart}
      </Grid>
      <Grid item xs={12}>
        {shiftsList}
      </Grid>
    </Grid>
  )
}

export default ShiftsPieChart
