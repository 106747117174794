import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import 'moment/locale/pt'

import { BASE_API_URL } from './urls'
import {
  Grid,
  Button,
  FormControl,
  Input,
  InputAdornment,
  FormHelperText,
  IconButton,
  Collapse,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import {
  Repeat as RecurrenceIcon,
  Message as CommentsIcon,
  Attachment as AttachmentIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import NumberFormat from 'react-number-format'

import ResponsiveDialog from '../utils/ResponsiveDialog'
import { setHeadersToken } from '../utils/helpers/store'
import { loadFromCashflow, loadEntries } from './actions/cashflowActions'
import CustomInput from '../utils/CustomInput'
import SearchSelect from '../utils/SearchSelect'
import PreviewFileByUrl from '../utils/PreviewFileByUrl'
import Installments from './Installments'
import { format_number } from '../utils/currency'

const NEW_ENTRY = {
  amount: '',
  date: moment(),
  category: '',
  account: '',
  tax_pct: '',
  comments: '',
  recurrence_frequency: '',
  is_forecast: false,
  activity_type: 1,
  installments: { items: [] },
  wallet_source: '',
  wallet_destination: '',
}
const NEW_INSTALLMENT = {
  date: moment().format('YYYY-MM-DD'),
  amount: 0,
  wallet_destination: '',
}
const iniState = {
  isEditing: false,
  isLoading: false,
  failedMessage: null,
  successMessage: null,
  showComments: false,
  showSelectFrequency: false,
  showInstallments: false,
  errors: null,
  attachmentPreviewUrl: '',
  transfer_to_confirming: '',
  ...JSON.parse(JSON.stringify(NEW_ENTRY)),
}

function formatValueByType(value, type) {
  if (type === 'date') value = value.format('YYYY-MM-DD')
  if (type === 'number') value = format_number(value, 2, true)

  return value
}

class EntryDialog extends Component {
  state = JSON.parse(JSON.stringify(iniState))

  toggle = (what) => {
    let state = {
      [what]: !this.state[what],
    }
    if (what === 'showSelectFrequency')
      Object.assign(state, {
        recurrence_frequency: '',
        installments: { items: [] },
      })

    this.setState({ ...state })
  }
  removeAttachment = () => {
    this.setState({ attachment: '', attachmentPreviewUrl: '' })
  }
  setEntry = (entry) => {
    let state = { ...JSON.parse(JSON.stringify(entry)) }
    Object.keys(state).forEach((key) => {
      if (!state[key]) state[key] = ''
    })
    state.account = entry.account
      ? { label: entry.account.name, value: entry.account.id }
      : ''
    state.category = entry.category
      ? { label: entry.category.name, value: entry.category.id }
      : ''

    state.isEditing = true

    state.attachmentPreviewUrl = entry.attachment
      ? entry.attachment_thumb
        ? entry.attachment_thumb
        : entry.attachment
      : ''
    state.attachmentUrl = entry.attachment ? entry.attachment : ''
    state.transfer_to_confirming =
      entry.wallet_destination && entry.wallet_destination.slug === 'confirming'
        ? true
        : ''
    this.setState({
      ...state,
    })
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.open !== nextProps.open && nextProps.open === true) {
      this.props.loadFromCashflow('categories')
      this.props.loadFromCashflow('accounts')
      if (nextProps.entry) {
        this.setEntry(nextProps.entry)
      } else {
        let state = {}
        if (nextProps.is_income) {
          state = { activity_type: 2 }
          if (nextProps.is_forecast) state['is_forecast'] = true
        }

        state['wallet_source'] = nextProps.wallet_source

        this.setState({ ...state })
      }
    }
    return true
  }

  handleClose = () => {
    this.props.handleCloseDialog()
    setTimeout(() => {
      this.setState({ ...JSON.parse(JSON.stringify(iniState)) })
    }, 500)
  }

  addToCashflow = (target, data) => {
    let targetDB
    switch (target) {
      case 'account':
        targetDB = 'accounts'
        break
      case 'category':
        targetDB = 'categories'
        break

      default:
        break
    }
    this.setState(
      {
        isLoading: true,
        failedMessage: null,
        successMessage: null,
        errors: null,
      },
      () => {
        axios
          .post(`${BASE_API_URL}${targetDB}/`, data, {
            headers: setHeadersToken(),
          })
          .then((res) => {
            this.props.loadFromCashflow(targetDB)
            this.setState({
              isLoading: false,
              [targetDB]: { label: res.data.name, value: res.data.id },
            })
          })
          .catch((error) => {
            if (error.response.data) {
              let errors = {}
              Object.keys(error.response.data).forEach(
                (field) => (errors[field] = error.response.data[field])
              )
              this.setState({ errors: errors, isLoading: false })
            }
            if (error.response.data.msg) {
              this.setState({ failedMessage: error.response.data.msg })
            }
          })
      }
    )
  }

  handleSearchChange = (newValue, actionMeta, target) => {
    switch (actionMeta.action) {
      case 'clear':
        this.setState({ [target]: '' })
        break
      case 'create-option':
        this.addToCashflow(target, { name: newValue.value })
        break
      case 'select-option':
        if (target === 'account') {
          let account = this.props.cashflow.accounts.find(
              (account) => account.id === newValue.value
            ),
            tax = ''
          if (account && account.tax_rate) tax = account.tax_rate.pct
          this.setState({ tax_pct: tax })
        }
        this.setState({ [target]: newValue })
        break
      default:
        break
    }
  }

  handleChangeInput = (event, key = null, value = null, type = null) => {
    if (!event && !key && !value) return

    let inputValue = formatValueByType(value || event.target.value, type),
      stateKey = key || event.target.name

    this.setState({ [stateKey]: inputValue })
  }

  handleChangeCustomInput = (prop) => (event) => {
    this.handleChangeInput(event, prop)
  }

  handleChangeImage = (e) => {
    e.preventDefault()
    let state = {
      attachmentPreviewUrl: '',
    }
    const imgTypes = ['jpg', 'jpeg', 'gif', 'png', 'bmp']

    let file = e.target.files[0]

    if (file) {
      state['file'] = file
      let ext = file.name.split('.').pop().toLowerCase()

      if (imgTypes.includes(ext)) {
        let reader = new FileReader()
        reader.onloadend = () => {
          state.attachmentPreviewUrl = reader.result
          this.setState({ ...state })
        }
        return reader.readAsDataURL(file)
      } else {
        state.attachmentPreviewUrl = file.name
      }
    }

    this.setState({ ...state })
  }

  /**
   * ----------------------------------------------------------------------
   * INSTALLMENTS
   * ----------------------------------------------------------------------
   */

  handleChangeInstallmentInput =
    (index) =>
    (event, key = null, value = null, type = null) => {
      if (!event && !key && !value) return
      let inputValue = formatValueByType(value || event.target.value, type),
        stateKey = key || event.target.name
      let installments = this.state.installments
      if (type === 'checkbox' && !event.target.checked) inputValue = ''
      let update = installments.items.map((installment, idx) => {
        if (idx === index) {
          installment[stateKey] = inputValue
        }
        return installment
      })
      installments.items = update
      this.setState({ installments: installments })
    }

  addInstallment = () => {
    let installments = this.state.installments
    installments.items.push({ ...this.getNewInstallment() })
    this.setState({ installments: installments })
  }

  deleteInstallment = (index) => {
    let installments = this.state.installments
    installments.items.splice(index, 1)
    this.setState({ installments: installments })
  }

  getNewInstallment = () => {
    let newInstallment = { ...NEW_INSTALLMENT }
    let totalInstallments = this.getTotalInstallments()
    if (this.state.amount > totalInstallments)
      newInstallment.amount = this.state.amount - totalInstallments

    return newInstallment
  }
  getTotalInstallments = () => {
    return this.state.installments.items.length > 0
      ? this.state.installments.items.reduce(
          (total, installment) =>
            installment.amount ? installment.amount + total : total,
          0
        )
      : 0
  }

  /**
   * ----------------------------------------------------------------------
   * SUBMIT
   * ----------------------------------------------------------------------
   */
  handleSubmit = (e, addAnother = false) => {
    let data = {
      category: this.state.category && this.state.category.value,
      account: this.state.account && this.state.account.value,
      is_forecast: this.state.is_forecast,
      activity_type: this.state.activity_type,
      amount: this.state.amount,
      comments: this.state.comments,
      date: moment(this.state.date).format('YYYY-MM-DD'),
      tax_pct: this.state.tax_pct,
      recurrence_frequency:
        this.state.recurrence_frequency &&
        this.state.recurrence_frequency.value,
      installments: this.state.installments.items,
      wallet_source: this.state.wallet_source && this.state.wallet_source.id,
      wallet_destination: '',
    }
    if (this.state.transfer_to_confirming) {
      let wallet = this.props.cashflow.wallets.find(
        (wallet) => wallet.slug === 'confirming'
      )
      data.wallet_destination = wallet ? wallet.id : null
    }

    if (this.state.attachment === '') data.attachment = ''
    if (this.state.file) data.attachment = this.state.file

    this.setState(
      {
        isLoading: true,
        failedMessage: null,
        successMessage: null,
        errors: null,
      },
      () => {
        var formData = new FormData()
        Object.keys(data).forEach((key) => {
          if (key === 'installments') {
            formData.append(key, JSON.stringify(data[key]))
          } else {
            formData.append(key, data[key])
          }
        })

        let axiosConfig = {
          url: `${BASE_API_URL}entries/`,
          method: 'post',
          data: formData,
          headers: setHeadersToken(),
        }
        if (this.props.entry && this.state.isEditing) {
          axiosConfig['method'] = 'patch'
          axiosConfig['url'] += this.props.entry.id + '/'
        }

        axios({ ...axiosConfig })
          .then((res) => {
            if (!addAnother) {
              this.setState({ successMessage: res.data.msg, isLoading: false })
            } else {
              this.setState({
                ...iniState,
                is_forecast: this.state.is_forecast,
                activity_type: this.state.activity_type,
              })
              document.getElementById('amount').focus()
            }
            this.props.loadEntries()
            this.props.loadFromCashflow('accounts')
            this.props.loadFromCashflow('categories')
          })
          .catch((error) => {
            if (error.response.data) {
              let errors = {}
              Object.keys(error.response.data).forEach(
                (field) => (errors[field] = error.response.data[field])
              )
              this.setState({ errors: errors, isLoading: false })
            }
            if (error.response.data.msg) {
              this.setState({
                failedMessage: error.response.data.msg,
                isLoading: false,
              })
            }
          })
      }
    )
  }

  render() {
    const { open, entry, cashflow, handleDelete } = this.props,
      {
        activity_type,
        is_forecast,
        isLoading,
        failedMessage,
        successMessage,
        errors,
        amount,
        date,
        comments,
        showComments,
        showSelectFrequency,
        showInstallments,
        is_installment,
        has_installments,
        recurrence_index,
        installments,
        installment_index_of,
        recurrence_frequency,
        recurrence_id,
        category,
        account,
        tax_pct,
        attachmentPreviewUrl,
        isEditing,
        attachmentUrl,
        wallet_source,
        transfer_to_confirming,
      } = this.state
    const is_simple_expense = wallet_source
      ? wallet_source.is_default
        ? false
        : true
      : false
    const totalInstallments = this.getTotalInstallments()
    const dialogTitle = this.props.dialogTitle
      ? this.props.dialogTitle
      : `${isEditing ? 'Editando' : 'Nova'} ${
          activity_type === 1 ? 'saída' : is_forecast ? 'previsão' : 'entrada'
        }`

    const categoryOptions = cashflow.categories.map((category) => {
        return { value: category.id, label: category.name }
      }),
      accountsOptions = cashflow.accounts.map((account) => {
        return { value: account.id, label: account.name }
      }),
      recurrenceOptions =
        cashflow.entries.recurrence_options &&
        cashflow.entries.recurrence_options.map((opt) => {
          return { value: opt.value, label: opt.label }
        })

    return (
      <ResponsiveDialog
        scroll="body"
        open={open}
        handleCloseDialog={this.handleClose}
        failedMessage={failedMessage}
        successMessage={successMessage}
        isLoading={isLoading}
        actions={
          <Fragment>
            {entry && isEditing && (
              <IconButton
                className="action-btn"
                style={{ marginRight: 'auto' }}
                onClick={handleDelete}
              >
                <DeleteIcon className="text-color danger" />
              </IconButton>
            )}
            <Button
              onClick={(e) => this.handleSubmit(e, true)}
              className="action-btn"
              color="primary"
              disabled={amount === '' && true}
            >
              Gravar e adicionar outro
            </Button>
            <Button
              onClick={this.handleSubmit}
              className="action-btn"
              color="primary"
              variant="contained"
              disabled={amount === '' && true}
            >
              Gravar
            </Button>
          </Fragment>
        }
        header={
          !successMessage &&
          !failedMessage && (
            <div
              className={`dialog__header entry-dialog__header ${
                activity_type === 1
                  ? 'expense'
                  : is_forecast
                  ? 'predict'
                  : 'income'
              }`}
            >
              <h1 className="entry-dialog__title">{dialogTitle}</h1>
              <FormControl
                fullWidth
                error={errors && errors['amount'] && true}
                className="entry__input"
              >
                <NumberFormat
                  autoFocus={true}
                  id="amount"
                  name="amount"
                  value={amount}
                  onChange={(e) =>
                    this.handleChangeInput(e, null, null, 'number')
                  }
                  customInput={Input}
                  decimalSeparator=","
                  thousandSeparator="."
                  decimalScale={2}
                  endAdornment={
                    <InputAdornment position="end">€</InputAdornment>
                  }
                />
                {errors && errors['amount'] && (
                  <FormHelperText className="--t-danger">
                    {errors['sellerAmount']}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          )
        }
      >
        <div className="wrapper">
          <Grid container spacing={5}>
            {recurrence_id && (
              <Grid item xs={12} container spacing={1} alignItems="center">
                <RecurrenceIcon fontSize="small" />

                <Grid item>
                  <label className="recurrence-label">
                    {is_installment
                      ? `${recurrence_index} de ${installment_index_of}`
                      : recurrence_frequency.label}
                  </label>
                </Grid>
              </Grid>
            )}
            {/* Date */}
            <Grid item xs={12} sm={4}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  fullWidth
                  label="Data"
                  name="date"
                  id="date"
                  error={errors && errors['date'] ? true : false}
                  helperText={errors && errors['date']}
                  showTodayButton={true}
                  todayLabel="Hoje"
                  value={date}
                  format="DD MMM YYYY"
                  onChange={(date) =>
                    this.handleChangeInput(null, 'date', date, 'date')
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {!is_simple_expense && !is_forecast && (
              <Fragment>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    className="form-group"
                    fullWidth
                    error={errors && errors['category'] && true}
                  >
                    <SearchSelect
                      placeholder="Selecione..."
                      id="select-category"
                      name="category"
                      label="Categoria"
                      value={category}
                      options={categoryOptions}
                      handleOnChange={(v, a) =>
                        this.handleSearchChange(v, a, 'category')
                      }
                      // handleCreate={this.handleAddCategory}
                      isLoading={isLoading}
                    />
                  </FormControl>
                </Grid>
                {activity_type === 1 && (
                  <Grid item xs={12} sm={9}>
                    <FormControl
                      className="form-group"
                      fullWidth
                      error={errors && errors['account'] && true}
                    >
                      <SearchSelect
                        placeholder="Selecione..."
                        label="Fornecedor"
                        value={account}
                        name="account"
                        id="select-supplier"
                        options={accountsOptions}
                        handleOnChange={(v, a) =>
                          this.handleSearchChange(v, a, 'account')
                        }
                        isLoading={isLoading}
                      />
                    </FormControl>
                  </Grid>
                )}
                {activity_type === 1 && (
                  <Grid item xs={12} sm={3}>
                    <CustomInput
                      id="tax_pct"
                      name="tax_pct"
                      placeholder="%"
                      className="form-group"
                      handleChange={this.handleChangeCustomInput}
                      value={tax_pct}
                      type="text"
                      label="IVA"
                      errors={errors}
                    />
                  </Grid>
                )}
                {!is_simple_expense &&
                  !showInstallments &&
                  activity_type === 1 && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={transfer_to_confirming}
                            onChange={() =>
                              this.toggle('transfer_to_confirming')
                            }
                            color="primary"
                          />
                        }
                        label="Pagar com Confirming"
                      />
                    </Grid>
                  )}
              </Fragment>
            )}
            {!is_simple_expense && !recurrence_id && (
              <Fragment>
                {!is_forecast &&
                  !transfer_to_confirming &&
                  activity_type === 1 && (
                    <Collapse
                      in={has_installments ? true : !showSelectFrequency}
                      timeout="auto"
                      style={{ width: '100%' }}
                    >
                      <Grid
                        container
                        style={{ borderBottom: '1px solid rgba(0,0,0,0.15)' }}
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className="clickable"
                          onClick={() => this.toggle('showInstallments')}
                          style={{ padding: '10px 20px' }}
                        >
                          <Grid item>
                            {!has_installments ? 'Agendar' : ''} Pagamentos
                          </Grid>
                          <Grid item>
                            <IconButton>
                              <ExpandMoreIcon
                                className={`expand-icon ${
                                  showInstallments ? 'expanded' : ''
                                } `}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Collapse in={showInstallments} style={{ width: '100%' }}>
                        <Installments
                          totalInstallments={totalInstallments}
                          installments={installments.items}
                          addInstallment={this.addInstallment}
                          deleteInstallment={this.deleteInstallment}
                          handleChangeInput={this.handleChangeInstallmentInput}
                          total={amount === '' ? 0 : amount}
                        />
                      </Collapse>
                    </Collapse>
                  )}
                <Collapse in={showSelectFrequency} style={{ width: '100%' }}>
                  <div style={{ padding: 20 }}>
                    <SearchSelect
                      isCreatable={false}
                      placeholder="Selecione..."
                      name="recurrence"
                      label="Recorrência"
                      id="select-recurrence"
                      value={recurrence_frequency}
                      options={recurrenceOptions}
                      handleOnChange={(v, a) =>
                        this.handleSearchChange(v, a, 'recurrence_frequency')
                      }
                      isLoading={isLoading}
                    />
                  </div>
                </Collapse>
              </Fragment>
            )}

            {(!comments || !attachmentPreviewUrl || !recurrence_frequency) && (
              <Grid item xs={12}>
                {!comments && (
                  <IconButton onClick={() => this.toggle('showComments')}>
                    <CommentsIcon />
                  </IconButton>
                )}
                {!attachmentPreviewUrl && (
                  <Fragment>
                    <input
                      id="attachment"
                      type="file"
                      name="attachment"
                      style={{ display: 'none' }}
                      onChange={this.handleChangeImage}
                    />
                    <label htmlFor="attachment">
                      <IconButton
                        component="span"
                        color={attachmentPreviewUrl ? 'primary' : 'default'}
                      >
                        <AttachmentIcon />
                      </IconButton>
                    </label>
                  </Fragment>
                )}

                {!is_simple_expense && !recurrence_frequency && (
                  <IconButton
                    onClick={() => this.toggle('showSelectFrequency')}
                  >
                    <RecurrenceIcon />
                  </IconButton>
                )}
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Collapse in={comments ? true : showComments} timeout="auto">
                <TextField
                  id="comments"
                  name="comments"
                  value={comments}
                  label="Observações"
                  fullWidth
                  multiline
                  margin="normal"
                  onChange={this.handleChangeInput}
                />
              </Collapse>
            </Grid>

            {attachmentPreviewUrl && (
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <PreviewFileByUrl
                  handleDelete={this.removeAttachment}
                  url={attachmentUrl ? attachmentUrl : attachmentPreviewUrl}
                  thumb={attachmentPreviewUrl}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </ResponsiveDialog>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    cashflow: state.cashflow,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadFromCashflow: (what) => dispatch(loadFromCashflow(what)),
    loadEntries: () => dispatch(loadEntries()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EntryDialog)
