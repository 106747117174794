import React from 'react'
import Preloader from '../utils/Preloader'
import EntryItem from './EntryItem'
import CashflowCollapsible from './CashflowCollapsible'
import GridItemAnimated from '../utils/GridItemAnimated'

const EntriesForecast = ({ entries, animateIndex }) => {
  if (!entries) return <Preloader />

  const entries_taxes = entries.filter(entry => entry.is_tax === true)
  let total = entries_taxes.reduce((total, entry) => entry.amount + total, 0)
  if (total <= 0) return <div />

  return (
    <GridItemAnimated index={animateIndex} xs={12} animation="fadeInDown">
      <CashflowCollapsible title="Impostos" total={total} collapsed>
        <div className="list">
          {entries_taxes.map((entry, index) => (
            <EntryItem
              entry={entry}
              extraClass="entry--list-item"
              key={index}
            />
          ))}
        </div>
      </CashflowCollapsible>
    </GridItemAnimated>
  )
}

export default EntriesForecast
