import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

const Preloader = ({ width = 48, height = 48 }) => (
  <Grid container justifyContent="center" alignItems="center">
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
)

export default Preloader
