import React, { Fragment } from 'react'
import { IconButton, Grid } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import Installment from './Installment'
import { currencyFormatDE } from '../utils/currency'

const Installments = ({
  total,
  installments,
  totalInstallments,
  addInstallment,
  deleteInstallment,
  handleChangeInput
}) => {
  const renderTotals = (
    <Fragment>
      <p>
        Total <b>{currencyFormatDE(totalInstallments, false)}</b>
      </p>
      {totalInstallments < total && (
        <p className="small">
          Falta {currencyFormatDE(total - totalInstallments, false)}
        </p>
      )}
    </Fragment>
  )

  return (
    <div className="installments__container">
      <Grid container>
        {installments.map((installment, index) => (
          <Grid item key={index} className="installment-wrapper" xs={12}>
            <Installment
              index={index}
              date={installment.date}
              amount={installment.amount}
              wallet_destination={installment.wallet_destination}
              handleDelete={() => deleteInstallment(index)}
              handleChange={() => handleChangeInput(index)}
            />
          </Grid>
        ))}
      </Grid>
      <div className="installments__footer">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <IconButton color="primary" onClick={addInstallment}>
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item className="--ta-right">
            {renderTotals}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Installments
