import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { default as serverReducer } from '../../components/utils/reducers/serverReducer';
import { default as authReducer } from '../../components/Auth/reducers/authReducer';
import { default as analyticsReducer } from '../../components/Analytics/reducers/analyticsReducer';
import { default as cashflowReducer } from '../../components/Cashflow/reducers/cashflowReducer';
import { default as teamReducer } from '../../components/Team/reducers/teamReducer';
import { companiesReducer } from '../../@core/infrastructure/redux/reducers/companiesReducers';

export const createRootReducer = (history:History) =>
  combineReducers({
    router: connectRouter(history),
    server: serverReducer,
    auth: authReducer,
    companies: companiesReducer,
    analytics: analyticsReducer,
    cashflow: cashflowReducer,
    team: teamReducer,
  });
