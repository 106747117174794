export function updateLoadingStatus(startsWith = [], state, action) {
  if (startsWith.find(item => action.type.startsWith(item))) {
    let started = ['_LOAD', '_LOADING', '_START'].find(item =>
        action.type.endsWith(item)
      ),
      finished = ['_SUCCESS', '_FAILURE', '_LOADED', '_FINISHED'].find(item =>
        action.type.endsWith(item)
      ),
      failure = ['_FAILURE', '_FAILED'].find(item => action.type.endsWith(item))
    if (started) Object.assign(state, { isLoading: true })
    if (finished) Object.assign(state, { isLoading: false })
    if (failure) Object.assign(state, { errors: action.errors })
  }
}
