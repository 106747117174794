import {
  Home as HomeIcon,
  Assessment as ReportIcon,
  InsertChartOutlined as ChartOutlinedIcon,
  AccountBalance as AccountBalanceIcon,
} from '@material-ui/icons/';

import Home from '../views/Dashboard/Home';
import Reports from '../views/Dashboard/Reports';
import CompaniesReports from '../views/Dashboard/CompaniesReports';
import Cashflow from '../views/Dashboard/Cashflow';

type DashboardRoute = {
  path: string;
  navbarName: string;
  pageTitle?: string;
  icon?: React.ElementType;
  component?: React.ElementType;
  permissions?: string[];
  redirect?: boolean;
  to?: string;
};

const dashboardRoutes: DashboardRoute[] = [
  {
    path: '/home',
    pageTitle: 'Home',
    navbarName: 'Home',
    icon: HomeIcon,
    component: Home,
  },
  {
    path: '/relatorios',
    pageTitle: 'Relatórios de Vendas',
    navbarName: 'Relatórios',
    icon: ReportIcon,
    component: Reports,
  },
  {
    path: '/lojas',
    pageTitle: 'Resumo das Lojas',
    navbarName: 'Lojas',
    icon: ChartOutlinedIcon,
    component: CompaniesReports,
    permissions: ['Managers', 'Admins'],
  },
  // {
  //   path: '/meu-relatorio',
  //   pageTitle: 'Meu Relatório',
  //   navbarName: 'Meu Relatório',
  //   icon: BarChartIcon,
  //   component: PersonalReport,
  //   permissions: ['Employees']
  // },
  {
    path: '/cashflow',
    pageTitle: 'Cashflow',
    navbarName: 'Cashflow',
    icon: AccountBalanceIcon,
    component: Cashflow,
    permissions: ['Admins'],
  },

  { redirect: true, path: '/', to: '/home', navbarName: '' },
];

export default dashboardRoutes;
