import { Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';

import { ConnectionStatus } from './components/utils/ConnectionStatus';
import { indexRoutes } from './routes';
import PrivateRoute from './routes/PrivateRoute';

function App() {
  return (
    <div className="App">
      <ConnectionStatus />
      <CssBaseline />
      <Switch>
        {indexRoutes.map(({ path, component, strict, privateRoute }, index) => {
          const componentProps = {
            key: index,
            path,
            component,
            strict,
          };
          return privateRoute ? <PrivateRoute {...componentProps} /> : <Route {...componentProps} />;
        })}
      </Switch>
    </div>
  );
}

export default App;
