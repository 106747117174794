import Dashboard from '../views/Dashboard';
import LoginPage from '../views/Auth/Login';
import LogoutPage from '../views/Auth/Logout';
import ResetPassword from '../views/Auth/ResetPassword';
import { ComponentType } from 'react';

export type IndexRoute = {
  path: string;
  component: ComponentType;
  strict?: boolean;
  privateRoute?: boolean;
};

export const indexRoutes: IndexRoute[] = [
  { path: '/users/auth/password/confirm', component: ResetPassword },
  { path: '/login', component: LoginPage },
  { path: '/logout', component: LogoutPage },
  { path: '/', strict: true, component: Dashboard, privateRoute: true },
];
