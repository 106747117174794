import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt';

import { BASE_API_URL as SALES_URL } from './urls';
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import { CameraAlt as PhotoIcon, Place as PlaceIcon } from '@material-ui/icons';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import ResponsiveDialog from '../utils/ResponsiveDialog';
import { setHeadersToken } from '../utils/helpers/store';
import Preloader from '../utils/Preloader';
import { setDate } from './actions/analyticsActions';

const iniState = {
  isLoading: null,
  failedMessage: null,
  successMessage: null,
  errors: null,
  sellers: null,
  shifts: null,
  shiftSelected: null,
  date: moment(),
  employee: '',
  company: '',
  photo: '',
  photoPreviewUrl: '',
  comments: '',
  selectedEmployees: [],
};

class AddSalesDialog extends Component {
  state = JSON.parse(JSON.stringify(iniState));

  shouldComponentUpdate(nextProps) {
    if (this.props.open === false && nextProps.open === true) {
      if (this.props.user.employee && this.props.user.employee.current_company) {
        let employee = this.props.employees.find((employee) => employee.id === this.props.user.employee.id),
          company = this.props.companies.find((company) => company.id === this.props.user.employee.current_company);
        this.setState({
          selectedEmployees: [employee.id],
          employee: this.props.user.employee,
          company: company,
        });
      }
    }
    return true;
  }

  handleClose = () => {
    this.props.handleCloseDialog();
    setTimeout(() => {
      this.setState({ ...JSON.parse(JSON.stringify(iniState)) });
    }, 500);
  };

  handleSelectShift = (index) => {
    this.setState({ shiftSelected: index });
  };
  handleToggleEmployee = (id) => {
    let selectedEmployees = [...this.state.selectedEmployees];
    if (!selectedEmployees.includes(id)) {
      selectedEmployees.push(id);
    } else {
      selectedEmployees = selectedEmployees.filter((employee) => employee !== id);
    }
    this.setState({ selectedEmployees: selectedEmployees });
  };

  handleSelectSeller = (index) => {
    this.setState({ sellerSelected: index });
  };

  handleChangeInput = (event, date = null) => {
    if (typeof event === 'string' && date !== null) return this.setState({ [event]: date.format('YYYY-MM-DD') });

    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        this.setState({
          photo: file,
          photoPreviewUrl: reader.result,
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        photo: '',
        photoPreviewUrl: '',
      });
    }
  };

  handleSubmit = (e) => {
    const data = {
      comments: this.state.comments,
      shift: this.state.shiftSelected,
      user: this.props.user.id,
      sellers: this.state.selectedEmployees,
      company: this.state.company.id,
      date: this.state.date,
      photo: this.state.photo,
    };
    this.setState(
      {
        isLoading: true,
        failedMessage: null,
        successMessage: null,
        errors: null,
      },
      () => {
        var formData = new FormData();
        Object.keys(data).forEach((key) => formData.append(key, data[key]));
        axios
          .post(SALES_URL, formData, {
            headers: setHeadersToken(),
          })
          .then((res) => {
            this.setState({ successMessage: res.data.msg });
            this.props.setDate();
          })
          .catch((error) => {
            if (error.response.data) {
              let errors = {};
              Object.keys(error.response.data).forEach((field) => (errors[field] = error.response.data[field]));
              this.setState({ errors: errors, isLoading: false });
            }
            if (error.response.data.msg) {
              this.setState({ failedMessage: error.response.data.msg });
            }
          });
      }
    );
  };

  render() {
    const { open, companies, employees, shifts, user } = this.props,
      {
        isLoading,
        failedMessage,
        successMessage,
        errors,
        shiftSelected,
        date,
        company,
        photoPreviewUrl,
        comments,
        selectedEmployees,
      } = this.state;

   
    let employees_filtered =
      employees &&
      employees.filter((employee) => employee.is_active && !employee.is_sales_handler && !employee.is_sales_splitter);
    if (company) {
      employees_filtered = employees_filtered.filter((employee) => {
        return !employee.current_company || (employee.current_company && employee.current_company === company.id);
      });
    }
    const is_manager = user.groups.includes('Admins') || user.groups.includes('Managers') ? true : false;

    const displayShifts = shifts && (
      <Grid container spacing={2}>
        {Object.values(shifts).map((shift) => (
          <Grid item xs={6} key={shift.id}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.handleSelectShift(shift.id)}
              disabled={shiftSelected === shift.id && true}
              className={`btn--shift ${shiftSelected === shift.id ? 'selected' : ''}`}
            >
              <img src={shift.icon} alt={shift.name} />
              <span className="text">{shift.name}</span>
            </Button>
          </Grid>
        ))}
      </Grid>
    );

    const displayEmployees = employees && (
      <Fragment>
        <p>Quem trabalhou nesse turno?</p>
        <Grid container spacing={1}>
          {employees_filtered.map((employee) => (
            <Grid item key={employee.id}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.handleToggleEmployee(employee.id)}
                className={`btn btn--primary rounded ${selectedEmployees.includes(employee.id) ? 'flat' : 'outlined'}`}
              >
                <span className="text --nopadding">{employee.first_name}</span>
              </Button>
            </Grid>
          ))}
          {errors && errors['sellers'] && <small className="--t-danger --t-bold">{errors['sellers']}</small>}
        </Grid>
      </Fragment>
    );

    const displayCompanies = companies && (
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item>
          <PlaceIcon />
        </Grid>
        <Grid item>
          {is_manager ? (
            <FormControl style={{ width: 160 }} className="form-group" error={errors && errors['company'] && true}>
              <InputLabel htmlFor="company">Loja</InputLabel>
              <Select value={company} onChange={this.handleChangeInput} input={<Input name="company" id="company" />}>
                {Object.values(companies).map((company) => (
                  <MenuItem key={company.id} value={company}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
              {errors && errors['company'] && <FormHelperText>{errors['company']}</FormHelperText>}
            </FormControl>
          ) : (
            company && <p>{company.name}</p>
          )}
        </Grid>
      </Grid>
    );

    return (
      <ResponsiveDialog
        open={open}
        scroll="body"
        handleCloseDialog={this.handleClose}
        dialogTitle={'Registo de Venda'}
        failedMessage={failedMessage}
        successMessage={successMessage}
        actions={
          isLoading ? (
            <Preloader />
          ) : (
            <Button
              onClick={this.handleSubmit}
              className="action-btn"
              color="primary"
              variant="contained"
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
              disabled={!shiftSelected || company === '' ? true : false}
            >
              Registar
            </Button>
          )
        }
      >
        <div className="wrapper">
          <Grid container spacing={5}>
            {/* Date */}
            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  fullWidth
                  label="Dia"
                  name="date"
                  id="date"
                  error={errors && errors['date'] ? true : false}
                  helperText={errors && errors['date']}
                  // disablePast={true}
                  disableFuture={true}
                  showTodayButton={true}
                  todayLabel="Hoje"
                  value={date}
                  format="dddd, DD \d\e MMMM \d\e YYYY "
                  onChange={(date) => {
                    this.handleChangeInput('date', date);
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {/* Company and employee */}
            <Grid item xs={12}>
              {displayCompanies}
            </Grid>
            <Grid item xs={12}>
              {displayEmployees}
            </Grid>
            {/* Shift */}
            <Grid item xs={12}>
              <p>Selecione o turno</p>
              {displayShifts}
            </Grid>

            {shiftSelected !== null && (
              <Fragment>
                {/* Photo */}
                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    id="photo"
                    type="file"
                    name="photo"
                    style={{ display: 'none' }}
                    onChange={this.handleChangeImage}
                  />
                  <label className="btn btn--primary outlined rounded" htmlFor="photo">
                    <div className="chip grid__container --align-center spacing-8">
                      <PhotoIcon />

                      <div className="grid__item"> Inserir foto</div>
                    </div>
                  </label>
                  {photoPreviewUrl && (
                    <img src={photoPreviewUrl} alt="Prévia" style={{ width: 'auto', height: 'auto', maxHeight: 250 }} />
                  )}
                </Grid>
                {/* Comments */}
                <Grid item xs={12}>
                  <TextField
                    id="comments"
                    name="comments"
                    value={comments}
                    label="Observações"
                    fullWidth
                    multiline
                    margin="normal"
                    variant="outlined"
                    onChange={this.handleChangeInput}
                  />
                </Grid>
              </Fragment>
            )}
          </Grid>
        </div>
      </ResponsiveDialog>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    companies: state.companies.list,
    employees: state.companies.employees,
    shifts: state.companies.shifts,
    user: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDate: () => dispatch(setDate()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSalesDialog);
