import axios from 'axios'
import { BASE_AUTH_API_URL, BASE_API_URL } from '../urls'
import { setHeadersToken } from '../../utils/helpers/store'
import { handleErrors } from '../../utils/helpers/errors'

export const loadUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'USER_LOADING' })
    axios
      .get(`${BASE_API_URL}`, { headers: setHeadersToken() })
      .then(res => {
        dispatch({ type: 'USER_LOADED', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch, 'LOGOUT')
      })
  }
}

export const login = (email, password) => {
  return (dispatch, getState) => {
    dispatch({ type: 'LOGIN_START' })
    const data = {
      username: email,
      password: password
    }
    axios
      .post(`${BASE_AUTH_API_URL}login/`, data)
      .then(res => {
        dispatch({ type: 'LOGIN_SUCCESSFUL', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch, 'AUTHENTICATION_ERROR')
      })
  }
}

export const sendPasswordRecover = email => {
  return (dispatch, getState) => {
    dispatch({ type: 'SENDING_PASSWORD_RECOVER' })
    axios
      .post(`${BASE_AUTH_API_URL}password/reset/`, { email: email })
      .then(res => {
        dispatch({ type: 'PASSWORD_RECOVER_SENT', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch, 'PASSWORD_RECOVER_FAIL')
      })
  }
}
export const setNewPassword = data => {
  return (dispatch, getState) => {
    dispatch({ type: 'SETTING_NEW_PASSWORD' })

    axios
      .post(``, data)
      .then(res => {
        dispatch({ type: 'PASSWORD_CHANGE_SUCCESS', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch, 'PASSWORD_CHANGE_FAIL')
      })
  }
}

export const logout = () => {
  return (dispatch, getState) => {
    axios
      .post(`${BASE_AUTH_API_URL}logout/`)
      .then(res => {
        dispatch({ type: 'LOGOUT', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch, 'LOGIN_FAILED')
      })
  }
}
