import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { withWidth, IconButton } from '@material-ui/core'
import {
  Delete as DeleteIcon
  // Repeat as RepeatIcon
} from '@material-ui/icons'
import { currencyFormatDE } from '../utils/currency'

const EntryItem = ({
  entry,
  handleEdit,
  width,
  isLoading,
  handleOpenDelete,
  extraClass,
  hideActionButtons
}) => {
  const {
    activity_type,
    is_forecast,
    date,
    amount,
    category,
    account,
    wallet_source,
    wallet_destination,
    is_installment,
    installment_index_of,
    recurrence_id,
    recurrence_index,
    recurrence_frequency,
    is_tax
  } = entry

  return (
    <div
      className={`entry ${extraClass} ${
        activity_type === 2 ? (is_forecast ? 'predict' : 'income') : ''
      }`}
    >
      <div
        className={`container ${!is_tax ? 'clickable' : ''} ${
          !hideActionButtons ? 'md-11' : ''
        }`}
        onClick={() => !is_tax && handleEdit()}
      >
        <div className="entry__item xs-12 md-2">
          <div className="entry__date">
            <label className="date">{moment(date).format('DD/MM')}</label>
            {recurrence_id && (
              <label className="recurrence-label">
                {is_installment
                  ? `${recurrence_index} de ${installment_index_of}`
                  : recurrence_frequency.label}
              </label>
            )}
          </div>
        </div>

        <div className="entry__item xs-7 md-8">
          <div className="entry__col">
            <p className="entry__account">
              {account && account.name}
              {is_forecast && 'Vendas'}
            </p>
            <p className="entry__category">
              {wallet_destination &&
              wallet_destination.slug === 'confirming' ? (
                category && category.name ? (
                  <span>
                    <s>{category.name}</s> Confirming
                  </span>
                ) : (
                  'Confirming'
                )
              ) : (
                category && category.name
              )}
              {is_forecast && 'Previsão'}
              {wallet_source &&
                wallet_source.slug === 'confirming' &&
                'Pgto de confirming'}
            </p>
          </div>
        </div>
        <div className="entry__item xs-5 md-2">
          <div className="entry__col">
            <p className="entry__amount">{currencyFormatDE(amount, false)}</p>
          </div>
        </div>
      </div>
      {!hideActionButtons && (
        <div className="container md-1">
          <div className="entry__action-btns">
            {handleOpenDelete && !is_installment && !is_tax && (
              <IconButton className="action-btn" onClick={handleOpenDelete}>
                <DeleteIcon className="text-color danger" />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoading: state.cashflow.isLoading
  }
}
export default connect(mapStateToProps)(withWidth()(EntryItem))
