import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'

const Periodicity = ({
  periodicity,
  handleChangePeriodicity,
  currentRange
}) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Periodicidade</FormLabel>
      <RadioGroup
        name="periodicity"
        value={periodicity}
        onChange={handleChangePeriodicity}
        row
      >
        <FormControlLabel
          value="daily"
          control={<Radio color="primary" />}
          label="Diário"
        />
        <FormControlLabel
          value="weekly"
          control={<Radio color="primary" />}
          label="Semanal"
        />
        <FormControlLabel
          value="monthly"
          control={<Radio color="primary" />}
          label="Mensal"
        />
        {moment(currentRange[0]).month() !==
          moment(currentRange[1]).month() && (
          <FormControlLabel
            value="quarterly"
            control={<Radio color="primary" />}
            label="Trimestral"
          />
        )}
      </RadioGroup>
    </FormControl>
  )
}

const mapStateToProps = state => {
  return {
    currentRange: state.analytics.currentRange
  }
}

export default connect(mapStateToProps)(Periodicity)
