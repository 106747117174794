import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Preloader from '../utils/Preloader';
import ResponsiveDialog from '../utils/ResponsiveDialog';
import axios from 'axios';
import { BASE_API_URL } from '../Analytics/urls';
import { setHeadersToken } from '../utils/helpers/store';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { format_number } from '../utils/currency';
import { companiesSelector } from '../../@core/infrastructure/redux/selectors';

function formatValueByType(value, type) {
  if (type === 'date') {
    if (!(value instanceof moment)) value = moment(value);
    value = value.format('YYYY-MM-DD');
  }

  if (type === 'number') if (value) value = format_number(value, 2, true);

  return value;
}

const fields = {
  company: {
    type: 'select',
    label: 'Empresa',
    value: '',
    choices: [],
    errors: [],
  },
  date_start: {
    type: 'date',
    label: 'Data Início',
    value: moment().startOf('month'),
    errors: [],
  },
  date_end: {
    type: 'date',
    label: 'Data Fim',
    value: moment().endOf('month'),
    errors: [],
  },
  goal: { type: 'number', label: 'Objectivo', value: '' },
  profit_goal: {
    type: 'number',
    endAdornment: '%',
    label: 'ML',
    value: '',
    errors: [],
  },
  goal_secondary: {
    type: 'number',
    label: 'Objectivo Sec.',
    value: '',
    errors: [],
  },
  profit_goal_secondary: {
    type: 'number',
    endAdornment: '%',
    label: 'ML Sec.',
    value: '',
    errors: [],
  },
};

const SetGoalsDialog = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [failedMessage, setFailedMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [APIError, setAPIError] = useState('');
  const [APISuccess, setAPISuccess] = useState('');
  const [formFields, setFormFields] = useState({
    ...fields,
  });
  const { open } = props;

  const { list: companies } = useSelector(companiesSelector);
  const isCompaniesLoaded = companies.length > 0;

  useEffect(() => {
    if (isCompaniesLoaded) {
      setFormFields((formFields) => {
        return {
          ...formFields,
          company: {
            ...formFields.company,
            choices: [...companies],
            value: companies[0].id.toString(),
          },
        };
      });
    }
  }, [isCompaniesLoaded, companies]);

  const handleChange = (fieldName, value) => {
    setFormFields({
      ...formFields,
      [fieldName]: { ...formFields[fieldName], value: value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let timer = null;
    let formFieldsCleaned = {};
    let newFormFields = JSON.parse(JSON.stringify(formFields));
    if (timer) clearTimeout(timer);

    Object.entries(formFields).forEach(([fieldName, data]) => {
      formFieldsCleaned[fieldName] = formatValueByType(data.value, data.type);
      newFormFields[fieldName]['errors'] = [];
    });
    axios
      .post(`${BASE_API_URL}set-goals/`, formFieldsCleaned, {
        headers: setHeadersToken(),
      })
      .then((res) => setAPISuccess(res.data))
      .catch((error) => {
        if (error.response.status === 500)
          return setAPIError(
            <span>
              <b>
                {error.response.status} - {error.response.statusText}
              </b>{' '}
              <br />
              {error.response.data}
            </span>
          );
        if (error.response.data)
          Object.entries(formFields).forEach(([fieldName, data]) => {
            if (error.response.data[fieldName]) newFormFields[fieldName]['errors'] = error.response.data[fieldName];
          });
        if (error.response.data['non-field-errors']) setAPIError(error.response.data['non-field-errors']);
        setFormFields(newFormFields);
      })
      .finally(() => {
        setIsLoading(false);
        timer = setTimeout(() => {
          setAPIError('');
          setAPISuccess('');
        }, 7000);
      });
  };
  return (
    <ResponsiveDialog
      open={open}
      scroll="body"
      handleCloseDialog={() => {
        setFormFields({ ...fields });
        setFailedMessage('');
        setSuccessMessage('');
        props.handleClose();
      }}
      dialogTitle={'Definir Objectivos'}
      failedMessage={failedMessage}
      successMessage={successMessage}
    >
      <form className="grid" onSubmit={(e) => handleSubmit(e)}>
        <div className="grid__container grid__container--column spacing-32">
          <div className="grid__item">
            <div className="grid__container spacing-8">
              {Object.entries(formFields).map(([fieldName, data], idx) => {
                let gridClass = data.type === 'select' ? 'xs-12' : 'xs-6';

                return (
                  <div key={idx} className={`grid__item ${gridClass}`}>
                    <Field fieldName={fieldName} data={data} handleChange={handleChange} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="grid__item">
            {isLoading ? (
              <Preloader />
            ) : (
              <>
                <Button
                  className="action-btn"
                  color="primary"
                  variant="contained"
                  disabled={isLoading}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  type="submit"
                >
                  Gravar
                </Button>
                {(APISuccess || APIError) && (
                  <div
                    className={`api-response mt-8 p-8 bg--${APISuccess ? 'success' : APIError ? 'alert' : ''}--light`}
                  >
                    <p>
                      {APISuccess}
                      {APIError}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state) => {
  return { companies: state.companies.list };
};
export default connect(mapStateToProps)(SetGoalsDialog);

const Field = ({ fieldName, data, handleChange }) => {
  let content = null;
  switch (data.type) {
    case 'select':
      content =
        data.choices.length > 5 ? (
          <>
            <InputLabel htmlFor={fieldName}>{data.label}</InputLabel>
            <Select
              native
              value={data.value}
              onChange={(e) => handleChange(fieldName, e.target.value)}
              inputProps={{
                name: fieldName,
                id: fieldName,
              }}
            >
              {Object.values(data.choices).map((choice, idx) => (
                <option key={idx} value={choice.id}>
                  {choice.name}
                </option>
              ))}
            </Select>
          </>
        ) : (
          <>
            <FormLabel component="legend">{data.label}</FormLabel>
            <RadioGroup
              row
              name={fieldName}
              value={data.value}
              onChange={(e) => handleChange(fieldName, e.target.value)}
            >
              {Object.values(data.choices).map((choice, idx) => (
                <FormControlLabel
                  key={idx}
                  value={choice.id.toString()}
                  control={<Radio color="primary" />}
                  label={choice.name}
                />
              ))}
            </RadioGroup>
          </>
        );
      break;

    case 'date':
      content = (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            fullWidth
            label={data.label}
            name={fieldName}
            id={fieldName}
            // error={errors && errors['date'] ? true : false}
            // helperText={errors && errors['date']}
            // disablePast={true}
            showTodayButton={true}
            todayLabel="Hoje"
            value={data.value}
            format="DD/MM/YYYY"
            onChange={(date) => {
              handleChange(fieldName, date);
            }}
          />
        </MuiPickersUtilsProvider>
      );
      break;

    case 'number':
      content = (
        <>
          <InputLabel htmlFor={fieldName}>{data.label}</InputLabel>
          <NumberFormat
            id={fieldName}
            name={fieldName}
            value={data.value}
            onChange={(e) => handleChange(fieldName, e.target.value)}
            customInput={Input}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
            endAdornment={
              data.endAdornment ? <InputAdornment position="end">{data.endAdornment}</InputAdornment> : null
            }
          />
        </>
      );
      break;

    default:
      content = (
        <TextField
          error={data.errors && data.errors.length > 0}
          name={fieldName}
          value={data.value}
          label={data.label}
          onChange={(e) => handleChange(fieldName, e.target.value)}
        />
      );

      break;
  }
  return (
    <FormControl error={data.errors && data.errors.length > 0}>
      {content}
      <FormHelperText>{data.errors && data.errors.map((error) => error).join(', ')}</FormHelperText>
    </FormControl>
  );
};
