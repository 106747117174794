import axios from 'axios'

import moment from 'moment'

import { BASE_API_URL } from '../urls'
import { setHeadersToken } from '../../utils/helpers/store'
import { handleErrors } from '../../utils/helpers/errors'
import { loadEntries } from '../../Cashflow/actions/cashflowActions'
import { checkServer } from '../../utils/actions/serverActions'

export const setDate = (range = null) => {
  return (dispatch, getState) => {
    dispatch(
      checkServer(function () {
        if (getState().server.on) {
          if (!(range instanceof Array)) {
            range = getState().analytics.currentRange
          }
          if (range.length > 0) {
            dispatch(loadJournal(range))
            dispatch(loadEntries(range))
            dispatch({ type: 'SET_RANGE', payload: range })
          }
        }
      })
    )
  }
}
export const setLoadJournalCompare = (bool = true) => {
  return (dispatch, getState) => {
    if (getState().server.on) {
      if (bool) dispatch(loadJournal(null, true))

      dispatch({ type: 'LOAD_COMPARE', payload: bool })
    }
  }
}
export const setLoading = (bool = true) => {
  return (dispatch, getState) => {
    bool && dispatch({ type: '__START' })
    !bool && dispatch({ type: '__END' })
  }
}
export const loadJournal = (range = null, getCompare = false) => {
  return (dispatch, getState) => {
    if (getState().server.on) {
      dispatch({ type: 'JOURNAL_START' })

      let { currentRange } = getState().analytics

      if (!range) range = currentRange
      if (!range) console.log('Error: loadJournal whithout range')

      let url = `${BASE_API_URL}journal/${range[0]}/${range[1]}/`,
        url_accumulated = `${BASE_API_URL}journal/${range[0]}/${range[1]}/accumulated/`

      /**
       *  If getCompare, means the journal and accumulated have already been loaded
       */

      if (!getCompare) {
        dispatch({ type: 'CLEAR_COMPARE' })
        dispatch({ type: 'LOAD_COMPARE', payload: false })

        axios
          .get(url_accumulated, { headers: setHeadersToken() })
          .then((res) =>
            dispatch({ type: 'ACCUMULATED_LOADED', payload: res.data })
          )
          .catch((error) => handleErrors(error, dispatch))

        axios
          .get(url, { headers: setHeadersToken() })
          .then((res) =>
            dispatch({ type: 'JOURNAL_LOADED', payload: res.data })
          )
          .catch((error) => handleErrors(error, dispatch))
          .finally(() => dispatch({ type: 'JOURNAL_FINISHED' }))
      }

      /**
       * getCompare will only load the compare and loadCompare means
       * everytime that loads the journal, the compare will be loaded too
       */
      if (!(getState().analytics.journal.compare.length > 0)) {
        let compare_range,
          compare_url,
          { loadCompare } = getState().analytics
        if (getCompare || loadCompare) {
          compare_range = [
            moment(range[0], 'YYYY-MM-DD')
              .subtract(1, 'years')
              .format('YYYY-MM-DD'),
            moment(range[1], 'YYYY-MM-DD')
              .subtract(1, 'years')
              .format('YYYY-MM-DD'),
          ]
          compare_url = `${BASE_API_URL}journal/${compare_range[0]}/${compare_range[1]}/`

          axios
            .get(compare_url, { headers: setHeadersToken() })
            .then((res) =>
              dispatch({ type: 'JOURNAL_COMPARE_LOADED', payload: res.data })
            )
            .catch((error) => handleErrors(error, dispatch))
            .finally(() => dispatch({ type: 'JOURNAL_COMPARE_FINISHED' }))
        }
      }
    }
  }
}
