import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

import './index.scss';
import App from './App';
import configureStore, { history } from './store';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: ['Open Sans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
  },
  palette: {
    primary: {
      main: '#1175bd',
      contrastText: '#fff',
    },
    success: green,
    error: red,
    danger: {
      color: '#fff',
      backgroundColor: red[50],
    },
  },
});

export const store = configureStore();

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
