import React from 'react'
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid
} from 'recharts'
import CustomTooltip from './CustomTooltip'
import PreloaderOverlay from '../utils/PreloaderOverlay'

const CustomComposedChart = ({
  data,
  syncId,
  children,
  height = 300,
  isLoading = false,
  withGrid = true
}) => {
  return (
    <div className="chart-wrapper" style={{ height: height }}>
      {isLoading && <PreloaderOverlay />}
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          syncId={syncId}
          barGap={0.5}
        >
          <XAxis
            tick={{ fontSize: 11 }}
            dataKey="xAxis"
            stroke="#333"
            tickLine={false}
          />
          <YAxis
            tick={{ fontSize: 11 }}
            width={45}
            stroke="#999"
            tickLine={false}
          />
          <Tooltip content={<CustomTooltip />} />
          {withGrid && <CartesianGrid stroke="#F0F0F0" />}

          {children}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default CustomComposedChart
