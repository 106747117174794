import React from 'react'
import { currencyFormatDE } from '../utils/currency'

const getSellers = (p) => {
  let dataKeys = p.dataKey.split('.'),
    periodKey = dataKeys[0],
    salesKey = dataKeys[1],
    shiftKey = salesKey ? dataKeys[2] : null,
    sellers = shiftKey
      ? p.payload[periodKey][salesKey][shiftKey]['sellers']
      : null
  if (sellers && sellers.length > 0) {
    let names = sellers.map((seller) => seller.first_name)
    return <small>{names.join(', ')}</small>
  }

  return null
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && label) {
    return (
      <div className="card">
        <div className="card__body">
          <p
            className="card__title"
            style={{ marginBottom: 0 }}
          >{`${label}`}</p>
          {Object.values(payload).map(
            (p, index) =>
              p.name !== 'hide' && (
                <div key={index}>
                  <p style={{ color: p.color, marginBottom: 0, marginTop: 15 }}>
                    {p.name}:{' '}
                    <b>
                      {p.unit && p.unit === '€'
                        ? currencyFormatDE(p.value, true, 0, true)
                        : p.unit === '€€'
                        ? currencyFormatDE(p.value, true, 2)
                        : p.unit === '%'
                        ? `${p.value}%`
                        : p.value}
                    </b>
                  </p>
                  {getSellers(p)}
                </div>
              )
          )}
        </div>
      </div>
    )
  }

  return null
}

export default CustomTooltip
