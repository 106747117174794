import React, { Component } from 'react'

import Entries from '../../../components/Cashflow/Entries'

class Cashflow extends Component {
  render() {
    return <Entries />
  }
}

export default Cashflow
