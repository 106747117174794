import React from 'react';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { Cell, ResponsiveContainer, PieChart, Pie } from 'recharts';
import { Grid } from '@material-ui/core';

import { currencyFormatDE, format_number } from '../utils/currency';
import { getDiffBetweenDates } from '../utils/helpers/dates';
import CompaniesButtons from './CompaniesButtons';
import withCompanySetter from './hoc/withCompanySetter';
import Compare from './Compare';
import ProfitMarginIcon from './ProfitMarginIcon';
import { getProfitMarginBGColor } from './utils';
import { analyticsSelector, companiesSelector } from '../../@core/infrastructure/redux/selectors';

function SalesAccumulated({ handleSelectCompany, CID }) {
  const analytics = useSelector(analyticsSelector);
  const { shifts } = useSelector(companiesSelector);

  const { accumulated } = analytics;

  if (!accumulated.length > 0) {
    return null;
  }

  const company = Object.values(accumulated).find((item) => item.company === CID);
  if (!company) {
    return null;
  }

  const SHIFT_DATA = Object.entries(company.current.year_sales_by_shift).map(([k, v]) => {
    return {
      shift: shifts.find((i) => i.slug === k) || {
        name: 'Off',
        bg_color: '#82ca9d',
      },
      amount: v,
      company_sales_pct: format_number((v / company.current.year_sales) * 100, 2, true),
      prev_amount: company.prev.year_sales_by_shift[k],
    };
  });

  const pieChart = () => {
    return (
      <div style={{ width: 120, height: 120, margin: '0 auto' }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={SHIFT_DATA}
              dataKey="amount"
              nameKey="name"
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={60}
              fill="#82ca9d"
            >
              {Object.values(SHIFT_DATA).map((item, index) => (
                <Cell key={index} fill={item.shift.bg_color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const shiftList = () => (
    <div className="list">
      {SHIFT_DATA.map((item, index) => {
        if (item.shift && company.current.year_sales > 0) {
          let currentAmount = item.amount,
            compareAmount = item.prev_amount;

          return (
            <div key={index} className="list__item">
              <div className="content">
                {item.shift.bg_color && (
                  <span
                    className="item__chip"
                    style={{
                      backgroundColor: item.shift.bg_color,
                    }}
                  />
                )}
                {item.company_sales_pct && (
                  <span className="item__text">{`${item.company_sales_pct.toFixed(1)}%`}</span>
                )}
                <span className="item__text">{item.shift.name} </span>
                {item.year_sales && (
                  <span className="item__text">
                    <Compare
                      value={currentAmount}
                      valueString={currencyFormatDE(currentAmount, true, 0, true)}
                      compare={compareAmount}
                    />
                  </span>
                )}
              </div>
            </div>
          );
        }
        return <div key={index} />;
      })}
    </div>
  );

  const range = analytics.currentRange;
  const minDate = moment(range[0]).startOf('year');
  let maxDate = moment(range[1]).endOf('month');

  if (moment() < moment(maxDate)) {
    maxDate = moment();
  }

  const monthDiff = moment(maxDate).diff(moment(minDate), 'months') + 1;
  const weekDiff = moment(maxDate).diff(moment(minDate), 'weeks') + 1;
  const totalSales = company.current.year_sales;
  const totalPrevSales = company.prev.year_sales;
  const skipIsoWeekdays = company.company_obj.slug !== 'online' ? [7] : [];
  const totalPerDay = totalSales / getDiffBetweenDates(moment().startOf('year'), moment(), 'days', skipIsoWeekdays);
  const totalPerMonth = totalSales / monthDiff;
  const totalPerWeek = totalSales / weekDiff;

  // const profitMarginPct = 30
  const profitMarginPct = company.current.year_net_profit_margin_pct,
    profitMarginBGColor = getProfitMarginBGColor(profitMarginPct);

  return (
    <div className="panel">
      <div className="panel__header">
        <h1 className="panel__title">Acumulado do ano</h1>
        <CompaniesButtons data={accumulated} selectedCompanyId={CID} handleSelectCompany={handleSelectCompany} />
      </div>
      <div className="card__body">
        <div className="--ta-center">
          <div className="wrapper wrapper--hidden">
            <Grid container spacing={5}>
              <Grid item xs={12} className="--ta-center">
                <Grid container spacing={3}>
                  <Grid item xs={12} className="--ta-center">
                    <Compare
                      value={totalSales}
                      valueString={currencyFormatDE(totalSales, true, 0, true)}
                      compare={totalPrevSales}
                      justifyContent="center"
                      compareComponent={company.company_obj.slug === 'online' && <div></div>}
                      valueComponent={
                        <p className="--nomargin bigger" title={currencyFormatDE(totalSales, false)}>
                          <b>{currencyFormatDE(totalSales, true, 0, true)}</b>
                        </p>
                      }
                    />

                    <p className="small --t-bold text-mutted">total de vendas</p>
                  </Grid>

                  <Grid item xs={12} className="--ta-center">
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item>
                        <p className="--nomargin --t-bold">{currencyFormatDE(totalPerMonth, true, 0)}</p>
                        <p className="small --t-bold text-mutted">por mês</p>
                      </Grid>
                      <Grid item>
                        <p className="--nomargin --t-bold">{currencyFormatDE(totalPerWeek, true, 0)}</p>
                        <p className="small --t-bold text-mutted">por semana</p>
                      </Grid>
                      <Grid item>
                        <p className="--nomargin --t-bold">{currencyFormatDE(totalPerDay, true, 0)}</p>
                        <p className="small --t-bold text-mutted">por dia</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="--ta-center">
                    <div
                      style={{
                        padding: '12px 20px',
                        display: 'inline-block',
                        borderRadius: '3px',
                      }}
                      className={`box--${profitMarginBGColor} light`}
                    >
                      <div className="grid__container spacing-24 --align-center">
                        <div className="grid__item xs-3">
                          <ProfitMarginIcon style={{ fontSize: 32 }} value={profitMarginPct} />
                        </div>
                        <div className="grid__item xs-9">
                          <Compare
                            value={profitMarginPct}
                            justifyContent="center"
                            valueComponent={
                              <p className="--nomargin" title={format_number(profitMarginPct, 2)}>
                                <b>{format_number(profitMarginPct, 2)}%</b>
                              </p>
                            }
                            valueString={format_number(profitMarginPct, 0)}
                            compare={company.prev.year_net_profit_margin_pct}
                            compareComponent={company.company_obj.slug === 'online' && <div></div>}
                          />
                          <p className="small --t-bold text-mutted">ML</p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {company.slug !== 'online' && (
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                      {pieChart()}
                    </Grid>
                    <Grid item xs={12}>
                      {shiftList()}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    analytics: state.analytics,
    shifts: state.companies.shifts,
  };
};
const Wrapped = connect(mapStateToProps)(SalesAccumulated);
export default withCompanySetter(Wrapped);
