import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

const PreloaderOverlay = ({ width = 48, height = 48 }) => (
  <Grid
    className="preloader-overlay"
    container
    justifyContent="center"
    alignItems="center"
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
)

export default PreloaderOverlay
