import HttpClient from './HttpClient';
import axios from 'axios';
import { setHeadersToken } from '../../components/utils/helpers/store';

export default class AxiosAdapter implements HttpClient {
  async get(url: string): Promise<any> {
    const response = await axios.get(url, { headers: setHeadersToken() });
    return response.data;
  }

  async post(url: string, body: any): Promise<any> {
    const response = await axios.post(url, body, { headers: setHeadersToken() });
    return response.data;
  }
  async put(url: string, body: any): Promise<any> {
    const response = await axios.put(url, body, { headers: setHeadersToken() });
    return response.data;
  }
  async delete(url: string): Promise<any> {
    const response = await axios.delete(url, { headers: setHeadersToken() });
    return response.data;
  }
}
