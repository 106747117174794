import React, { Component, Fragment } from 'react'
import { Grid } from '@material-ui/core'

import moment from 'moment'
moment.locale('pt')

const release = moment('10/10/2018 10:00:00', 'DD/MM/YYYY hh:mm:ss')
let now = moment()

class Base extends Component {
  state = {
    released: false,
  }
  componentWillMount() {
    if (now.isAfter(release)) {
      this.setState({
        released: true,
      })
    }
  }
  componentDidMount() {
    if (now.isBefore(release)) {
      this.timer = setInterval(this.tick, 1000)
    }
  }
  tick = () => {
    now = moment()
    if (now.isAfter(release)) {
      clearInterval(this.timer)
      this.setState({
        released: true,
      })
    }
  }
  render() {
    return (
      <div className="login-page">
        <div className="login__box">
          <Grid container direction="column">
            <Grid item className="login__box-header">
              <img
                src="static/assets/img/logo-white.png"
                alt="SON OF A GUN"
                className="login__logo"
              />
            </Grid>
            <Grid item className="login__box-body">
              {this.state.released === true ? (
                <Fragment>{this.props.children}</Fragment>
              ) : (
                <Grid container spacing={5}>
                  <Grid item xs={12} className="--ta-center">
                    <h1 className="card__title --title">Bem vindo</h1>

                    <p className="card__text --subheading">
                      ao nosso sistema de membros.
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p className="card__text --subheading">
                      O sistema estará disponível a partir de
                    </p>
                    <p className="card__text --title --t-success">
                      {release.calendar()}
                    </p>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        {/* <Grid container style={{ marginTop: 32 }}>
          <Grid item style={{ textAlign: 'center' }} xs={12}>
            <a
              href="https://www.iapmei.pt/PRODUTOS-E-SERVICOS/Qualificacao-Certificacao/PME-Lider.aspx"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={logoPme}
                alt="Logotipo PME Lider 2018"
                style={{
                  maxHeight: 56,
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              />
            </a>
          </Grid>
        </Grid> */}
      </div>
    )
  }
}

export default Base
