import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Warning, WifiOff } from '@material-ui/icons';

import { checkServer } from './actions/serverActions';
import { serverSelector } from '../../@core/infrastructure/redux/selectors';
import { useNavigatorOnline } from '../../hooks/useNavigatorOnline';

export function ConnectionStatus() {
  const server = useSelector(serverSelector);

  const dispatch = useDispatch();

  const isOnline = useNavigatorOnline();

  const isServerOn = server.on;

  useEffect(() => {
    let timer: number | null;

    if (isOnline && !isServerOn) {
      dispatch(checkServer());
      timer = window.setInterval(() => {
        dispatch(checkServer());
      }, 5000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isServerOn, isOnline, dispatch]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={server.errors.length > 0}
      ContentProps={{
        className: 'alert--danger',
        'aria-describedby': 'message-id',
      }}
      message={
        server.errors &&
        server.errors.map((error, idx) => (
          <span key={idx} id="message-id" style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10 }}>{error.code === 'off' ? <Warning /> : <WifiOff />}</span>
            {error.content}
          </span>
        ))
      }
    />
  );
}
