import { CompaniesAPI } from "../../interfaces/CompaniesAPI";

export enum CompaniesActions {
  LOAD_COMPANIES = '[companies] load',
  LOAD_COMPANIES_SUCCESS = '[companies] load success',
  LOAD_COMPANIES_FAILURE = '[companies] load failure',
}

export const loadCompanies = { type: CompaniesActions.LOAD_COMPANIES };
export const loadCompaniesSuccess = (companies: CompaniesAPI) => ({
  type: CompaniesActions.LOAD_COMPANIES_SUCCESS,
  payload: companies,
});
export const loadCompaniesFailure = (error: unknown) => ({
  type: CompaniesActions.LOAD_COMPANIES_FAILURE,
  payload: error,
});
