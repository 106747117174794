import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { Grid, Button, withWidth, IconButton } from '@material-ui/core'
import { UnfoldMore as ChangeIcon } from '@material-ui/icons'
import { setDate } from './actions/analyticsActions'
import PreloaderOverlay from '../utils/PreloaderOverlay'

import Slider from 'react-slick'

const DateButton = ({
  isInitialSlide,
  range,
  currentRange,
  handleClick,
  displayBy,
  titleDateFmt,
  clickEnabled = false,
}) => {
  return (
    <Button
      variant={
        range[0] === currentRange[0] && range[1] === currentRange[1]
          ? 'outlined'
          : 'text'
      }
      color='primary'
      className='btn rounded'
      style={{ display: 'inline-block', width: '100%', minHeight: '47px' }}
      onClick={() => (isInitialSlide || clickEnabled) && handleClick(range)}
    >
      <div className='btn__content'>
        {titleDateFmt && (
          <span className='btn__text'>
            {moment(range[0]).format(titleDateFmt)}
          </span>
        )}

        {displayBy === 'month' && (
          <span
            className={`btn__caption ${
              range[0] === currentRange[0] && range[1] === currentRange[1]
                ? ''
                : 'text-mutted'
            }`}
          >
            {moment(range[0]).format('YYYY')}
          </span>
        )}
      </div>
    </Button>
  )
}

class DateSetter extends Component {
  state = {
    displayBy: 'month',
    clickEnabled: false,
  }

  handleChangeButtons = () => {
    if (this.state.displayBy === 'year') this.setState({ displayBy: 'month' })
    else if (this.state.displayBy === 'month')
      this.setState({ displayBy: 'year' })
  }
  toggleEnabledClick(enabled = false) {
    this.setState({ clickEnabled: enabled })
  }

  render() {
    const { analytics, setDate, width } = this.props,
      currentRange = analytics.currentRange
    let { displayBy, clickEnabled } = this.state

    let initialSlide = 0,
      sliderMonths = [],
      sliderYears = [],
      display = ['xs', 'sm'].includes(width)
        ? 3
        : ['md', 'lg'].includes(width)
        ? 13
        : 17,
      variableWidth = ['xs', 'sm'].includes(width) ? true : false,
      startYear = moment('2016-1-1', 'YYYY-MM-DD'),
      endYear = moment().add(1, 'years'),
      loopYear = moment(startYear)

    while (loopYear <= endYear) {
      let year = moment(loopYear).startOf('year'),
        month = moment(loopYear).startOf('month')

      sliderYears.push([
        moment(year, 'YYYY').startOf('year').format('YYYY-MM-DD'),
        moment(year, 'YYYY').endOf('year').format('YYYY-MM-DD'),
      ])
      for (let i = 0; i < 12; i++) {
        sliderMonths.push([
          moment(month).startOf('month').format('YYYY-MM-DD'),
          moment(month).endOf('month').format('YYYY-MM-DD'),
        ])

        month.add(1, 'month')
      }
      loopYear.add(1, 'years')
    }

    let sliderSettings = {
        arrows: false,
        centerMode: true,
        centerPadding: 0,
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        slidesToScroll: 1,
        variableWidth: variableWidth,
        swipeToSlide: true,
        initialSlide: initialSlide,
        slidesToShow: display,
        onSwipe: () => {
          this.setState({ clickEnabled: false })
        },
        beforeChange: (current, next) => {
          this.setState({ clickEnabled: false })
        },
        afterChange: (current) => {
          this.setState({ clickEnabled: true })
        },
      },
      defaultSlideBtnProps = {
        currentRange: currentRange,
        clickEnabled: clickEnabled,
        handleClick: setDate,
        displayBy: displayBy,
      },
      slideBtnsProps = []

    switch (displayBy) {
      case 'month':
        sliderMonths.forEach((range, index) => {
          if (
            (moment(range[0]).isSame(currentRange[0], 'month') &&
              moment(range[1]).isSame(currentRange[1], 'month')) ||
            (moment(range[0]).isSame(moment(), 'month') &&
              moment(range[1]).isSame(moment(), 'month'))
          )
            initialSlide = index

          slideBtnsProps.push({
            ...defaultSlideBtnProps,
            key: index,
            range: range,
            isInitialSlide: index === initialSlide,
            titleDateFmt: 'MMM',
          })
        })
        sliderSettings = {
          ...sliderSettings,
          initialSlide: initialSlide,
          key: 'month',
        }

        break
      case 'year':
        sliderYears.forEach((range, index) => {
          if (
            moment(range[0]).isSame(currentRange[0], 'year') &&
            moment(range[1]).isSame(currentRange[1], 'year')
          )
            initialSlide = index

          slideBtnsProps.push({
            ...defaultSlideBtnProps,
            key: index,
            range: range,
            isInitialSlide: index === initialSlide,
            titleDateFmt: 'YYYY',
          })
        })
        sliderSettings = {
          ...sliderSettings,
          initialSlide: initialSlide,
          slidesToShow: 3,
          key: 'year',
        }
        break

      default:
        break
    }

    return (
      <Grid container alignItems='center' spacing={1} wrap='nowrap'>
        <Grid item style={{ width: 'calc(100% - 56px)' }}>
          <div className='panel date-setter__buttons'>
            {analytics.isLoading && <PreloaderOverlay />}

            <Slider
              {...sliderSettings}
              ref={(slider) => (this.slider = slider)}
            >
              {slideBtnsProps.map((btnProps) => (
                <DateButton {...btnProps}></DateButton>
              ))}
            </Slider>
          </div>
        </Grid>
        <Grid item style={{ width: 56 }}>
          <IconButton onClick={this.handleChangeButtons}>
            <ChangeIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    analytics: state.analytics,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDate: (date) => dispatch(setDate(date)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(DateSetter))
