import { format_number } from '../utils/currency'

const ProfitMarginIcon = ({ value, ...rest }) => {
  let icon
  value = format_number(value, 2, true)
  const icons = [
    {
      name: 'sentiment_very_satisfied',
      colorClass: '--t-status-very-good',
    },
    {
      name: 'sentiment_satisfied',
      colorClass: '--t-status-good',
    },
    {
      name: 'sentiment_dissatisfied',
      colorClass: '--t-status-warning',
    },
    {
      name: 'sentiment_very_dissatisfied',
      colorClass: '--t-status-alert',
    },
    {
      name: 'sentiment_neutral',
      colorClass: '--t-text-primary',
    },
  ]
  switch (true) {
    case value >= 50:
      icon = Object.values(icons).find(
        (item) => item.name === 'sentiment_very_satisfied'
      )
      break
    case value >= 40 && value < 50:
      icon = Object.values(icons).find(
        (item) => item.name === 'sentiment_satisfied'
      )
      break
    case value >= 30 && value < 40:
      icon = Object.values(icons).find(
        (item) => item.name === 'sentiment_dissatisfied'
      )
      break
    case value > 0 && value < 30:
      icon = Object.values(icons).find(
        (item) => item.name === 'sentiment_very_dissatisfied'
      )
      break
    default:
      icon = Object.values(icons).find(
        (item) => item.name === 'sentiment_neutral'
      )
      break
  }

  return (
    <span className={`material-icons ${icon.colorClass}`} {...rest}>
      {icon.name}
    </span>
  )
}
export default ProfitMarginIcon
