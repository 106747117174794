import React from 'react'

import { Grid } from '@material-ui/core'
import ProfitMarginIcon from './ProfitMarginIcon'
import { format_number } from '../utils/currency'
import Compare from './Compare'
import { getProfitMarginBGColor } from './utils'

const ProfitMarginBox = ({ totals }) => {
  const profitMarginPct = totals['current']['profitMargin'],
    profitMarginBGColor = getProfitMarginBGColor(profitMarginPct)

  return (
    <Grid item xs={12} className="--ta-center">
      <div
        style={{
          padding: '12px 20px',
          display: 'inline-block',
          borderRadius: '3px',
        }}
        className={`box--${profitMarginBGColor} light`}
      >
        <div className="grid__container spacing-24 --align-center">
          <div className="grid__item xs-3">
            <ProfitMarginIcon
              style={{ fontSize: 32 }}
              value={profitMarginPct}
            />
          </div>
          <div className="grid__item xs-9">
            <Compare
              value={totals['current']['profitMargin']}
              compare={totals['compare']['profitMargin']}
              justifyContent="center"
              valueComponent={
                <p
                  className="--nomargin"
                  title={format_number(totals['current']['profitMargin'], 2)}
                >
                  <b>{format_number(totals['current']['profitMargin'], 2)}%</b>
                </p>
              }
              valueString={format_number(totals['current']['profitMargin'], 0)}
            />

            <p className="small --t-bold text-mutted">ML</p>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default ProfitMarginBox
