export const getProfitMarginBGColor = (profitMarginPct) => {
  if (!profitMarginPct) return 'status-neutral'
  switch (true) {
    case profitMarginPct >= 50:
      return 'status-very-good'
    case profitMarginPct >= 40 && profitMarginPct < 50:
      return 'status-good'
    case profitMarginPct >= 30 && profitMarginPct < 40:
      return 'status-warning'
    case profitMarginPct < 30:
      return 'status-alert'
    default:
      break
  }
}
