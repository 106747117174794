import React from 'react'
import 'moment/locale/pt'
import CustomComposedChart from './CustomComposedChart'
import { Line, Bar } from 'recharts'

const SalesChartByHour = ({ data }) => {
  let barData = []
  Object.values(data).forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (['current', 'compare'].includes(key)) {
        item[key]['sales_by_hour'].forEach((sale) => {
          let idx = barData.findIndex((i) => i.xAxis === sale.hour)

          if (idx < 0)
            idx =
              barData.push({
                xAxis: sale.hour,
              }) - 1

          if (!barData[idx].hasOwnProperty(key))
            Object.assign(barData[idx], {
              [key]: {
                amount: 0,
              },
            })

          barData[idx][key]['amount'] += sale.amount
        })
      }
    })
  })
  barData.sort((a, b) => (a.xAxis > b.xAxis ? 1 : -1))

  return (
    <CustomComposedChart data={barData} height={250}>
      {barData.find((item) => item.compare) && (
        <Bar
          name="Vendas (anterior)"
          dataKey="compare.amount"
          fill="#999"
          unit="€"
        />
      )}
      <Bar name="Vendas" dataKey="current.amount" fill="#56CCF2" unit="€" />
      <Line
        type="monotone"
        name="hide"
        connectNulls
        dataKey="current.amount"
        stroke="#1175BD"
        dot={false}
      />
    </CustomComposedChart>
  )
}

export default SalesChartByHour
