import { legacy_createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from '@redux-devtools/extension';

import { createRootReducer } from './redux/reducers/rootReducer';
import { loadCompaniesMiddleware } from './@core/infrastructure/redux/middleware/companiesMiddleware';

export const history = createBrowserHistory();

const middleware = [thunk, routerMiddleware(history), loadCompaniesMiddleware];

export default function configureStore(initialState = {}) {
  if (process.env.NODE_ENV === 'production') {
    return legacy_createStore(createRootReducer(history), initialState, compose(applyMiddleware(...middleware)));
  }

  return legacy_createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
