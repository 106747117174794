import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ResponsiveContainer, PieChart, Pie, Cell, Bar, Line } from 'recharts'
import { Grid } from '@material-ui/core'
import CustomComposedChart from './CustomComposedChart'

class VisitsPieChart extends Component {
  render() {
    const { data, shifts } = this.props
    let barData = [],
      pieData = []

    pieData = Object.values(shifts).map((shift) => {
      let item = {
        shift,
      }
      return item
    })

    Object.values(data).forEach((item) =>
      Object.keys(item).forEach((key) => {
        if (['current', 'compare'].includes(key) && item[key]['visits']) {
          item[key]['visits']['by_hour'].forEach((visit) => {
            let idx = barData.findIndex((it) => it.xAxis === visit.hour)

            if (idx < 0) {
              idx = barData.push({
                xAxis: visit.hour,
              })
              idx--
            }

            if (!barData[idx][key]) {
              Object.assign(barData[idx], { [key]: visit.entries })
            } else {
              barData[idx][key] += visit.entries
            }
          })
          item[key]['visits']['by_shift'].forEach((visit) => {
            let pieIdx = pieData.findIndex(
              (pieDataItem) => pieDataItem.shift.id === visit.shift
            )
            if (pieIdx >= 0) {
              if (!pieData[pieIdx][key]) {
                Object.assign(pieData[pieIdx], {
                  [key]: visit.entries,
                })
              } else {
                pieData[pieIdx][key] += visit.entries
              }
            }
          })
        }
      })
    )

    barData = barData.sort((a, b) => a.xAxis - b.xAxis)

    const RADIAN = Math.PI / 180
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      payload,
      value,
      index,
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5
      const x = cx + (outerRadius + 45) * Math.cos(-midAngle * RADIAN)
      const y = cy + (outerRadius + 30) * Math.sin(-midAngle * RADIAN)
      const xInner = cx + radius * Math.cos(-midAngle * RADIAN)
      const yInner = cy + radius * Math.sin(-midAngle * RADIAN)

      return (
        <g>
          <text
            x={x}
            y={y}
            fill="#333"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            fontWeight={600}
          >
            {value}
          </text>
          <text
            x={x}
            y={y}
            fill="#999"
            textAnchor={x > cx ? 'start' : 'end'}
            dominantBaseline="central"
            dy={16}
            fontWeight={600}
            fontSize={13}
          >
            {payload.shift.name.toLowerCase()}
          </text>
          <text
            x={xInner}
            y={yInner}
            fill="white"
            textAnchor="middle"
            dominantBaseline="central"
            fontWeight="bold"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </g>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={7} xl={8}>
          <p className="--ta-center">por hora</p>
          <CustomComposedChart data={barData} height={250}>
            {barData.find((item) => item.compare) && (
              <Bar name="Clientes (anterior)" dataKey="compare" fill="#999" />
            )}
            <Bar name="Clientes" dataKey="current" fill="#56CCF2" />
            <Line
              type="monotone"
              name="hide"
              connectNulls
              dataKey="current"
              stroke="#1175BD"
              dot={false}
            />
          </CustomComposedChart>
        </Grid>
        <Grid item xs={12} md={5} xl={4}>
          <div
            className="chart-wrapper"
            style={{ width: '100%', height: 250, margin: '0 auto' }}
          >
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  isAnimationActive={false}
                  data={pieData}
                  dataKey="current"
                  nameKey="shift.name"
                  cx="50%"
                  cy="50%"
                  //   innerRadius={40}
                  outerRadius={60}
                  fill="#82ca9d"
                  label={renderCustomizedLabel}
                  paddingAngle={0}
                >
                  {Object.values(pieData).map((item, index) => (
                    <Cell
                      key={index}
                      fill={
                        item.shift.bg_color ? item.shift.bg_color : '#82ca9d'
                      }
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    shifts: state.companies.shifts,
  }
}
export default connect(mapStateToProps)(VisitsPieChart)
