import React from 'react'
import { connect } from 'react-redux'

import moment from 'moment'
import { currencyFormatDE } from '../utils/currency'
import { Grid, Tooltip } from '@material-ui/core'
import Compare from '../Analytics/Compare'
import Preloader from '../utils/Preloader'

const Accumulated = ({ cashflow, currentRange }) => {
  if (!cashflow) return <Preloader />

  const { accumulated } = cashflow.entries
  const period_accumulated = accumulated.filter((item) =>
    moment(item.date).isBetween(currentRange[0], currentRange[1], null, '[]')
  )
  const sales = period_accumulated.reduce(
    (total, item) => item.sales + total,
    0
  )
  const expenses_plus_taxes = period_accumulated.reduce(
    (total, item) => item.expenses_plus_taxes + total,
    0
  )
  const expenses = period_accumulated.reduce(
    (total, item) => item.expenses + total,
    0
  )
  const taxes = period_accumulated.reduce(
    (total, item) =>
      item.previous_quarter_taxes ? item.previous_quarter_taxes + total : total,
    0
  )

  const balance =
    period_accumulated.length > 0
      ? period_accumulated.slice(-1).pop().balance
      : 0
  const first_item_with_initial_balance = period_accumulated.find(
    (item) => item.initial_balance
  )
  const initial_balance = first_item_with_initial_balance
    ? first_item_with_initial_balance.initial_balance
    : 0
  const period_balance = period_accumulated.reduce(
    (total, item) => item.month_balance + total,
    0
  )

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <p className="--ta-center">Total do período</p>
      </Grid>
      <Grid item xs={12} className="--ta-center">
        <Compare
          justifyContent="center"
          value={balance}
          valueComponent={
            <p
              className={`--nomargin bigger  ${
                balance > 0 ? '--t-success' : '--t-alert'
              }`}
              title={currencyFormatDE(balance, false)}
            >
              <b>{currencyFormatDE(balance, true, 0, true)}</b>
            </p>
          }
        />
        <p className="small text-mutted --ta-center --t-bold">saldo</p>
        {moment(currentRange[0]).month() ===
          moment(currentRange[1]).month() && (
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs="auto">
              <Compare
                justifyContent="center"
                value={initial_balance}
                valueComponent={
                  <p
                    className="--nomargin --t-bold"
                    title={currencyFormatDE(initial_balance, false)}
                  >
                    {currencyFormatDE(initial_balance, true, 0, true)}
                  </p>
                }
              />
              <p className="small --t-bold text-mutted">inicial</p>
            </Grid>
            <Grid item xs="auto">
              <Compare
                justifyContent="center"
                value={period_balance}
                valueComponent={
                  <p
                    className={`--nomargin  --t-bold ${
                      period_balance < 0 ? '--t-danger' : ''
                    }`}
                    title={currencyFormatDE(period_balance, false)}
                  >
                    {currencyFormatDE(period_balance, true, 0, true)}
                  </p>
                }
              />
              <p className="small --t-bold text-mutted">mês corrente</p>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} className="--ta-center">
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Compare
              justifyContent="center"
              value={sales}
              valueComponent={
                <p
                  className="--nomargin bigger --t-primary"
                  title={currencyFormatDE(sales, false)}
                >
                  <b>{currencyFormatDE(sales, true, 0, true)}</b>
                </p>
              }
            />
            <p className="small --t-bold text-mutted">vendas</p>
          </Grid>

          <Grid item>
            <Compare
              justifyContent="center"
              value={expenses_plus_taxes}
              valueComponent={
                <p
                  className="--nomargin bigger"
                  title={currencyFormatDE(expenses_plus_taxes, false)}
                >
                  <Tooltip
                    placement="top"
                    enterTouchDelay={100}
                    title={
                      <div className="tooltip">
                        <p>
                          Despesas: {currencyFormatDE(expenses, false, 0, true)}
                        </p>
                        <p className="--nomargin">
                          Impostos: {currencyFormatDE(taxes, false, 0, true)}
                        </p>
                      </div>
                    }
                  >
                    <b>
                      {currencyFormatDE(expenses_plus_taxes, true, 0, true)}
                    </b>
                  </Tooltip>
                </p>
              }
            />
            <p className="small --t-bold text-mutted">despesas</p>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="--ta-center">
        <p className="--ta-center">Confirming</p>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs="auto">
            <Compare
              justifyContent="center"
              value={cashflow.entries.confirming.to_pay}
              valueComponent={
                <p
                  className={`--nomargin bigger ${
                    cashflow.entries.confirming.to_pay > 0 ? '--t-danger' : ''
                  }`}
                  title={currencyFormatDE(
                    cashflow.entries.confirming.to_pay,
                    false
                  )}
                >
                  <b>
                    {currencyFormatDE(
                      cashflow.entries.confirming.to_pay,
                      true,
                      0,
                      true
                    )}
                  </b>
                </p>
              }
            />
            <p className="small --t-bold text-mutted">a pagar</p>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs="auto">
                <Compare
                  justifyContent="center"
                  value={cashflow.entries.confirming.available}
                  valueComponent={
                    <p
                      className="--nomargin --t-bold"
                      title={currencyFormatDE(
                        cashflow.entries.confirming.available,
                        false
                      )}
                    >
                      {currencyFormatDE(
                        cashflow.entries.confirming.available,
                        true,
                        0,
                        true
                      )}
                    </p>
                  }
                />
                <p className="small --t-bold text-mutted">disponível</p>
              </Grid>
              <Grid item xs="auto">
                <Compare
                  justifyContent="center"
                  value={cashflow.entries.confirming.credit}
                  valueComponent={
                    <p
                      className={`--nomargin  --t-bold ${
                        cashflow.entries.confirming.credit < 0
                          ? '--t-danger'
                          : ''
                      }`}
                      title={currencyFormatDE(
                        cashflow.entries.confirming.credit,
                        false
                      )}
                    >
                      {currencyFormatDE(
                        cashflow.entries.confirming.credit,
                        true,
                        0,
                        true
                      )}
                    </p>
                  }
                />
                <p className="small --t-bold text-mutted">limite</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    cashflow: state.cashflow,
    currentRange: state.analytics.currentRange,
  }
}
export default connect(mapStateToProps)(Accumulated)
