import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, withWidth, FormControl, FormLabel, Switch } from '@material-ui/core';
import { ViewList as IconViewList } from '@material-ui/icons';

import GridItemAnimated from '../utils/GridItemAnimated';
import { currencyFormatDE } from '../utils/currency';
import PreloaderOverlay from '../utils/PreloaderOverlay';
import { filterJournal, updateShiftsData, setReportData } from '../utils/functions';
import Collapsible from '../utils/Collapsible';
import { analyticsSelector } from '../../@core/infrastructure/redux/selectors';

import CompaniesButtons from './CompaniesButtons';
import { SalesChart } from './SalesChart';
import VisitsChart from './VisitsChart';
import SalesShiftChart from './SalesShiftChart';
import ShiftsPieChart from './ShiftsPieChart';
import withCompanySetter from './hoc/withCompanySetter';
import Compare from './Compare';
import VisitsPieChart from './VisitsPieChart';
import SalesChartByHour from './SalesChartByHour';
import TableAccumulated from './TableAccumulated';
import { setLoadJournalCompare } from './actions/analyticsActions';
import Periodicity from './Periodicity';
import ProfitMarginBox from './ProfitMarginBox';

function SalesReport({ width, CID, handleSelectCompany }) {
  const [periodicity, setPeriodicity] = useState('monthly');

  const analytics = useSelector(analyticsSelector);

  const dispatch = useDispatch();

  const { loadCompare, currentRange } = analytics;
  const [rangeStart, rangeEnd] = currentRange;

  const handleChangePeriodicity = (e) => {
    setPeriodicity(e.target.value);
  };

  const handleChangeShowCompare = () => {
    dispatch(setLoadJournalCompare(!loadCompare));
  };

  useEffect(() => {
    if (moment(rangeStart).month() !== moment(rangeEnd).month() && periodicity === 'daily') {
      setPeriodicity('monthly');
    }
  }, [periodicity, rangeStart, rangeEnd]);

  if (!analytics.journal) {
    return null;
  }

  let data = filterJournal(analytics.journal, CID),
    journal = [],
    journalCalc = [],
    journalDayFormatText,
    totalsTitle = 'Total do período',
    totalsSubtitle = null;

  if (!loadCompare) delete data.compare;

  journal = [...setReportData(data, periodicity)];
  if (data.current.find((item) => item.sum_sales > 0 || item.entries > 0)) {
    journalCalc = [...setReportData(data, periodicity, true)];
  } else {
    journalCalc = { ...journal };
  }

  switch (periodicity) {
    case 'quarterly':
      journalDayFormatText = 'por trimestre';
      break;
    case 'monthly':
      journalDayFormatText = 'por mês';
      break;
    case 'weekly':
      journalDayFormatText = 'por semana';
      totalsSubtitle = '(acumulado de semanas)';
      break;
    default:
      journalDayFormatText = 'por dia';
      break;
  }

  let totals = {
    current: {},
    compare: {},
  };

  Object.keys(totals).forEach((key) => {
    // Goals
    totals[key]['totalGoal'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + journalCalc[k][key]['goal'] : total),
      0
    );
    totals[key]['totalGoalSecondary'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + journalCalc[k][key]['goal_secondary'] : total),
      0
    );
    let countProfitGoal = 0;
    totals[key]['totalProfitGoal'] = Object.keys(journalCalc).reduce((total, k) => {
      if (journalCalc[k][key]) {
        total = total + journalCalc[k][key]['profit_goal'];
        countProfitGoal++;
      }
      return total;
    }, 0);
    totals[key]['totalProfitGoal'] =
      totals[key]['totalProfitGoal'] > 0 ? totals[key]['totalProfitGoal'] / countProfitGoal : 0;
    let countProfitGoalSecondary = 0;
    totals[key]['totalProfitGoalSecondary'] = Object.keys(journalCalc).reduce((total, k) => {
      if (journalCalc[k][key]) {
        total = total + journalCalc[k][key]['profit_goal_secondary'];
        countProfitGoalSecondary++;
      }
      return total;
    }, 0);
    totals[key]['totalProfitGoalSecondary'] =
      totals[key]['totalProfitGoalSecondary'] > 0
        ? totals[key]['totalProfitGoalSecondary'] / countProfitGoalSecondary
        : 0;

    // Sales
    totals[key]['totalSales'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + journalCalc[k][key]['sum_sales'] : total),
      0
    );
    totals[key]['totalSalesNet'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + journalCalc[k][key]['sum_sales_net'] : total),
      0
    );
    totals[key]['totalSalesCost'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + journalCalc[k][key]['sum_sales_cost'] : total),
      0
    );
    totals[key]['workDays'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + 1 : total),
      0
    );
    totals[key]['totalVisits'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + journalCalc[k][key]['entries'] : total),
      0
    );
    totals[key]['totalEfficiency'] = Object.keys(journalCalc).reduce(
      (total, k) => (journalCalc[k][key] ? total + journalCalc[k][key]['efficiency'] : total),
      0
    );
    totals[key]['avgDailySales'] =
      totals[key]['totalSales'] > 0 ? totals[key]['totalSales'] / totals[key]['workDays'] : 0;
    totals[key]['avgDailyVisits'] =
      totals[key]['totalVisits'] > 0 ? totals[key]['totalVisits'] / totals[key]['workDays'] : 0;

    totals[key]['efficiency'] =
      totals[key]['totalVisits'] > 0 ? totals[key]['totalSales'] / totals[key]['totalVisits'] : 0;

    totals[key]['avgDailyEfficiency'] =
      totals[key]['totalSales'] > 0 ? totals[key]['totalEfficiency'] / totals[key]['workDays'] : 0;
    totals[key]['profitMargin'] =
      totals[key]['totalSalesNet'] - totals[key]['totalSalesCost'] > 0
        ? ((totals[key]['totalSalesNet'] - totals[key]['totalSalesCost']) / totals[key]['totalSalesNet']) * 100
        : 0;
  });

  // update dailyShifts
  let journalShiftsBars = [],
    journalShiftsPie = [];

  Object.values(journalCalc).forEach((item) => {
    let index = journalShiftsBars.push({ xAxis: item.xAxis });
    index--;
    Object.keys(item).forEach((key) => {
      if (['current', 'compare'].includes(key)) updateShiftsData(index, key, item, journalShiftsBars, journalShiftsPie);
    });
  });

  // Update Pie Chart Data
  journalShiftsPie = journalShiftsPie.map((item) => {
    Object.keys(item).forEach((key) => {
      if (['current', 'compare'].includes(key)) {
        item[key]['pct'] = (item[key]['amount'] / totals[key]['totalSales']) * 100;
      }
    });
    return item;
  });

  return (
    <div className="panel">
      <div className="panel__header">
        <h1 className="panel__title">Relatórios do período</h1>
        <CompaniesButtons data={analytics.journal.current} selectedCompanyId={CID} handleSelectCompany={handleSelectCompany} />
      </div>
      <div className="panel__body">
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item>
                <Periodicity periodicity={periodicity} handleChangePeriodicity={handleChangePeriodicity} />
              </Grid>
              <Grid item>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Mostrar período anterior</FormLabel>
                  <Switch checked={loadCompare} onChange={handleChangeShowCompare} color="primary" />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapsible title="Vendas">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={5} direction={`${['xs', 'sm'].includes(width) ? 'column-reverse' : 'row'}`}>
                    <GridItemAnimated index={2} xs={12} md={7} lg={8}>
                      {analytics.isLoading && <PreloaderOverlay />}

                      <Grid container direction="column" spacing={3}>
                        <Grid item xs={12}>
                          <SalesChart data={journal} />
                        </Grid>
                        <Grid item xs={12}>
                          <SalesShiftChart data={journalShiftsBars} />
                        </Grid>
                        <Grid item xs={12}>
                          <p className="--ta-center">por hora</p>
                          <SalesChartByHour data={journalCalc} />
                        </Grid>
                      </Grid>
                    </GridItemAnimated>
                    <GridItemAnimated index={3} xs={12} md={5} lg={4}>
                      {analytics.isLoading && <PreloaderOverlay />}
                      <Grid container spacing={5}>
                        {/* VENDAS */}
                        <Grid item xs={12} className="--ta-center">
                          <Grid container justifyContent="center" spacing={3}>
                            {/* totals  */}
                            <Grid item xs={12} className="--ta-center">
                              <p className="--nomargin">{totalsTitle}</p>
                              {totalsSubtitle && <p className="small --nomargin">{totalsSubtitle}</p>}
                            </Grid>
                            <Grid item xs={12} className="--ta-center">
                              <Grid container justifyContent="center" spacing={3}>
                                <Grid item xs="auto">
                                  <Compare
                                    justifyContent="center"
                                    value={totals['current']['totalSales']}
                                    compare={totals['compare']['totalSales']}
                                    valueComponent={
                                      <p
                                        className="--nomargin bigger --t-primary"
                                        title={currencyFormatDE(totals['current']['totalSales'], false)}
                                      >
                                        <b>{currencyFormatDE(totals['current']['totalSales'], true, 0, true)}</b>
                                      </p>
                                    }
                                  />
                                  <p className="small --t-bold text-mutted">vendas</p>
                                </Grid>
                                <Grid item xs="auto">
                                  <p
                                    className="--nomargin bigger"
                                    title={currencyFormatDE(totals['current']['totalGoal'], false)}
                                  >
                                    <b>{currencyFormatDE(totals['current']['totalGoal'], true, 0, true)}</b>
                                  </p>
                                  <p className="small --t-bold text-mutted">objectivo</p>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* average */}
                            <Grid item xs={12} className="--ta-center">
                              <Grid container justifyContent="center" spacing={3}>
                                <Grid item>
                                  <p
                                    className="--nomargin --t-bold"
                                    title={currencyFormatDE(totals['current']['avgDailySales'], false)}
                                  >
                                    {currencyFormatDE(totals['current']['avgDailySales'], true, 0, true)}
                                  </p>
                                  <p className="small --t-bold text-mutted">{journalDayFormatText}</p>
                                </Grid>
                              </Grid>
                            </Grid>

                            <ProfitMarginBox totals={totals} />

                            <Grid item xs={12} className="--ta-center">
                              <ShiftsPieChart data={journalShiftsPie} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </GridItemAnimated>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <GridItemAnimated index={4} xs={12}>
                      <Collapsible
                        icon={<IconViewList className="text-mutted" />}
                        title={<p>Detalhe {journalDayFormatText}</p>}
                        collapsed={true}
                      >
                        <TableAccumulated data={journal} />
                      </Collapsible>
                    </GridItemAnimated>
                  </Grid>
                </Grid>
              </Grid>
            </Collapsible>
          </Grid>
          <Grid item xs={12}>
            <Collapsible title="Clientes">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={5} direction={`${['xs', 'sm'].includes(width) ? 'column-reverse' : 'row'}`}>
                    {/* VISITS  */}

                    <GridItemAnimated index={4} xs={12} md={7} lg={8}>
                      {analytics.isLoading && <PreloaderOverlay />}
                      <p className="--ta-center">{journalDayFormatText}</p>
                      <VisitsChart data={journal} />
                    </GridItemAnimated>
                    <GridItemAnimated index={5} xs={12} md={5} lg={4}>
                      {analytics.isLoading && <PreloaderOverlay />}
                      <Grid container justifyContent="center" spacing={3}>
                        {/* totals  */}
                        <Grid item xs={12} className="--ta-center">
                          <p className="--nomargin">{totalsTitle}</p>
                          {totalsSubtitle && <p className="small --nomargin">{totalsSubtitle}</p>}
                        </Grid>
                        <Grid item xs={12} className="--ta-center">
                          <Grid container justifyContent="center" spacing={3}>
                            <Grid item xs="auto">
                              <Compare
                                justifyContent="center"
                                value={totals['current']['totalVisits']}
                                compare={totals['compare']['totalVisits']}
                                compareString={currencyFormatDE(totals['compare']['totalVisits'], false, 0)}
                                valueComponent={
                                  <p
                                    className="--nomargin bigger --t-visits"
                                    title={currencyFormatDE(totals['current']['totalVisits'], false)}
                                  >
                                    <b>{currencyFormatDE(totals['current']['totalVisits'], false, 0)}</b>
                                  </p>
                                }
                              />
                              <p className="small --t-bold text-mutted">clientes</p>
                            </Grid>

                            <Grid item xs="auto">
                              <Compare
                                justifyContent="center"
                                value={totals['current']['efficiency']}
                                compare={totals['compare']['efficiency']}
                                valueComponent={
                                  <p
                                    className="--nomargin bigger"
                                    title={currencyFormatDE(totals['current']['efficiency'], false)}
                                  >
                                    <b>{currencyFormatDE(totals['current']['efficiency'], true, 2)}</b>
                                  </p>
                                }
                              />
                              <p className="small --t-bold text-mutted">eficiência</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* average */}
                        <Grid item xs={12} className="--ta-center">
                          <Grid container justifyContent="center" spacing={3}>
                            <Grid item>
                              <p className="--nomargin --t-bold">
                                {currencyFormatDE(totals['current']['avgDailyVisits'], false, 0)}
                              </p>
                              <p className="small --t-bold text-mutted">{journalDayFormatText}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </GridItemAnimated>
                  </Grid>
                  <GridItemAnimated index={6} xs={12}>
                    {analytics.isLoading && <PreloaderOverlay />}
                    <VisitsPieChart data={journal} />
                  </GridItemAnimated>
                </Grid>
              </Grid>
            </Collapsible>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withCompanySetter(withWidth()(SalesReport));
