import React, { Component } from 'react'

import CompaniesSalesReport from '../../../components/Analytics/CompaniesSalesReport'

class CompaniesReports extends Component {
  render() {
    return <CompaniesSalesReport />
  }
}

export default CompaniesReports
