import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Fab, Menu, MenuItem, Grid, Divider, ListSubheader } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';

import dashboardRoutes from '../../routes/dashboard';
import { loadUser } from '../../components/Auth/actions/authActions';
import Preloader from '../../components/utils/Preloader';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import AddSalesDialog from '../../components/Analytics/AddSalesDialog';
import { setDate } from '../../components/Analytics/actions/analyticsActions';

import DateSetter from '../../components/Analytics/DateSetter';
import { isPermitted } from '../../components/utils/functions';
import { loadEntries, loadFromCashflow } from '../../components/Cashflow/actions/cashflowActions';
import EntryDialog from '../../components/Cashflow/EntryDialog';
import { loadPendingSurveys } from '../../components/Team/actions/teamActions';
import SurveyDialog from '../../components/Team/SurveyDialog';
import {
  // analyticsSelector,
  authSelector,
  // cashflowSelector,
  pendingSurveysSelector,
  serverSelector,
} from '../../@core/infrastructure/redux/selectors';
import { loadCompanies } from '../../@core/infrastructure/redux/actions/companiesActions';

type BaseDialog = {
  open: boolean;
};
type EntryDialogProps = BaseDialog & {
  open: boolean;
  dialogTitle?: string;
  is_income?: boolean;
  is_forecast?: boolean;
  wallet_source?: unknown;
};

const entryDialogInitialProps: EntryDialogProps = {
  open: false,
  is_income: false,
  is_forecast: false,
  wallet_source: null,
  dialogTitle: '',
};

type AddSalesDialogProps = BaseDialog;
const addSalesDialogInitialProps: AddSalesDialogProps = {
  open: false,
};

export default function Dashboard() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [entryDialog, setEntryDialog] = useState<EntryDialogProps>({ ...entryDialogInitialProps });
  const [addSalesDialog, setAddSalesDialog] = useState<AddSalesDialogProps>({ ...addSalesDialogInitialProps });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const server = useSelector(serverSelector);

  const { isLoading: isAuthLoading, user } = useSelector(authSelector);
  // const analytics = useSelector(analyticsSelector);
  // const cashflow = useSelector(cashflowSelector);
  const pendingSurveys = useSelector(pendingSurveysSelector);

  const dispatch = useDispatch();

  const isServerOn = server.on;

  const firstLoad = useCallback(() => {
    if (localStorage.getItem('token') !== null) {
      dispatch(loadCompanies);
      dispatch(loadPendingSurveys());
      dispatch(loadUser());
      dispatch(setDate());
      dispatch(loadEntries());
      dispatch(loadFromCashflow('wallets'));
    }
  }, [dispatch]);

  useEffect(() => {
    isServerOn && firstLoad();
  }, [isServerOn, firstLoad]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseEntryDialog = () => {
    setEntryDialog({ ...entryDialogInitialProps });
  };
  const handleCloseAddSalesDialog = () => {
    setAddSalesDialog({ ...addSalesDialogInitialProps });
  };

  const switchRoutes = (
    <Switch>
      {dashboardRoutes.map((prop, index) => {
        const { component: Component, icon: Icon } = prop;

        if (prop.redirect && prop.to) {
          return <Redirect from={prop.path} to={prop.to} key={index} />;
        }

        if (prop.permissions) {
          if ((!user || (user && !isPermitted(prop.permissions))) && prop.to !== '/home') {
            return <Redirect from={prop.path} to="/home" key={index} />;
          }
        }

        if (Component && Icon) {
          return (
            <Route
              path={prop.path}
              render={() => {
                return <Component pageTitle={prop.pageTitle} pageIcon={<Icon />} />;
              }}
              key={index}
            />
          );
        }

        return null;
      })}
    </Switch>
  );

  if (!user) {
    return null;
  }
  if (isAuthLoading) {
    return <Preloader />;
  }

  const confirming_wallet = {}; //cashflow.wallets.find((wallet) => wallet.slug === 'confirming');
  const default_wallet = {}; //cashflow.wallets.find((wallet) => wallet.is_default === true);

  return (
    <Fragment>
      <Header handleToggleSidebar={handleToggleSidebar} />

      <Sidebar routes={dashboardRoutes} sidebarOpen={isSidebarOpen} handleToggleSidebar={handleToggleSidebar} />

      <div className="dashboard-panel">
        <div className="content">
          <div className="section">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <DateSetter />
              </Grid>
              <Grid item xs={12}>
                {switchRoutes}
              </Grid>
            </Grid>
          </div>
        </div>

        <Fab
          color="primary"
          aria-label="Add"
          className="dashboard-panel__add-icon"
          onClick={(e) => {
            if (user.groups.indexOf('Admins') >= 0) {
              handleClick(e);
            } else {
              setAddSalesDialog({ open: true });
            }
          }}
        >
          <AddIcon />
        </Fab>

        <Menu id="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} disableAutoFocusItem={true}>
          <MenuItem
            onClick={() => {
              setAddSalesDialog({ open: true });
              handleClose();
            }}
          >
            Venda
          </MenuItem>
          <Divider />
          <ListSubheader>Cashflow</ListSubheader>
          <MenuItem
            className="submenu__item"
            onClick={() => {
              setEntryDialog({
                open: true,
                wallet_source: default_wallet,
              });
              handleClose();
            }}
          >
            Despesa
          </MenuItem>
          <MenuItem
            className="submenu__item"
            onClick={() => {
              setEntryDialog({
                open: true,
                is_income: true,
                wallet_source: default_wallet,
              });
              handleClose();
            }}
          >
            Entrada
          </MenuItem>
          {confirming_wallet && (
            <MenuItem
              className="submenu__item"
              onClick={() => {
                setEntryDialog({
                  open: true,
                  wallet_source: confirming_wallet,
                  dialogTitle: 'Pagamento de Confirming',
                });
                handleClose();
              }}
            >
              Pagar Confirming
            </MenuItem>
          )}
          <MenuItem
            className="submenu__item"
            onClick={() => {
              setEntryDialog({
                open: true,
                is_income: true,
                is_forecast: true,
                wallet_source: default_wallet,
              });
              handleClose();
            }}
          >
            Previsão de venda
          </MenuItem>
        </Menu>
      </div>

      <AddSalesDialog {...addSalesDialog} handleCloseDialog={handleCloseAddSalesDialog} />
      <EntryDialog {...entryDialog} handleCloseDialog={handleCloseEntryDialog} />

      {pendingSurveys.map((survey, index) => (
        <SurveyDialog key={index} survey={survey} />
      ))}
    </Fragment>
  );
}
