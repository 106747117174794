import React, { Component } from 'react'
import {
  GetApp as DownloadIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons'
import { Grid, IconButton } from '@material-ui/core'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

class PreviewFileByUrl extends Component {
  state = {
    photoSrc: '',
    openLightBox: false,
  }
  handleOpenLightBox = (src) => {
    this.setState({
      photoSrc: src,
      openLightBox: true,
    })
  }
  handleCloseLightBox = () => {
    this.setState({
      photoSrc: '',
      openLightBox: false,
    })
  }
  render() {
    const {
        thumb,
        url,
        width = 'auto',
        height = 'auto',
        maxHeight = 250,
        handleDelete,
      } = this.props,
      { openLightBox, photoSrc } = this.state

    let thumbUrl = thumb ? thumb : url
    let ext = url.split('.').pop().toLowerCase()
    let filename = thumbUrl.substring(thumbUrl.lastIndexOf('/') + 1)

    const imgTypes = ['jpg', 'jpeg', 'gif', 'png', 'bmp']

    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          {imgTypes.includes(ext) || thumbUrl.startsWith('data:image') ? (
            <img
              src={thumbUrl}
              alt="prévia"
              style={{ width: width, height: height, maxHeight: maxHeight }}
              onClick={() => this.handleOpenLightBox(url)}
            />
          ) : (
            <a href={url} style={{ fontSize: 14 }}>
              <DownloadIcon style={{ verticalAlign: 'middle' }} /> {filename}
            </a>
          )}
        </Grid>
        <Grid item>
          <IconButton onClick={handleDelete}>
            <DeleteIcon fontSize="small" className="text-color danger" />
          </IconButton>
        </Grid>

        {openLightBox && (
          <Lightbox
            mainSrc={photoSrc}
            onCloseRequest={this.handleCloseLightBox}
          />
        )}
      </Grid>
    )
  }
}

export default PreviewFileByUrl
