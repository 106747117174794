import { Middleware } from 'redux';
import ListCompaniesUseCase from '../../../useCases/GetCompaniesData.useCase';
import { CompaniesActions, loadCompaniesSuccess } from '../actions/companiesActions';

export const loadCompaniesMiddleware: Middleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === CompaniesActions.LOAD_COMPANIES) {
      try {
        const listCompanies = new ListCompaniesUseCase();
        const companies = await listCompanies.execute();
        dispatch(loadCompaniesSuccess(companies));
      } catch (error) {
        console.log('Middleware error');
      }
    }
  };
