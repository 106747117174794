import { AnyAction } from 'redux';
import { CompaniesActions } from '../actions/companiesActions';

const initialState = {
  isLoading: false,
  errors: [],
  list: [],
  shifts: [],
  employees: [],
};

export function companiesReducer(state = { ...initialState }, action: AnyAction) {
  switch (action.type) {
    case CompaniesActions.LOAD_COMPANIES: {
      return { ...state, isLoading: true, errors: null };
    }
    case CompaniesActions.LOAD_COMPANIES_FAILURE: {
      return { ...state, isLoading: false, errors: action.payload };
    }
    case CompaniesActions.LOAD_COMPANIES_SUCCESS: {
      const { companies, shifts, employees } = action.payload;
      return { ...state, isLoading: false, list: companies, shifts, employees };
    }
    default:
      return state;
  }
}
