import React from 'react';
import 'moment/locale/pt';
import { Area, ResponsiveContainer, AreaChart, XAxis, Tooltip, CartesianGrid, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import withCompanySetter from './hoc/withCompanySetter';
import { Grid } from '@material-ui/core';
import CompaniesButtons from './CompaniesButtons';
import TabSwiper from '../utils/TabSwiper';
import { currencyFormatDE } from '../utils/currency';
import { filterData, filterUntilNow } from '../utils/functions';

function SalesHomeChart({ data, CID, handleSelectCompany }) {
  if (!CID) {
    return null;
  }

  const journal = filterData(data, CID);
  const journalCalc = filterUntilNow(journal);
  const totalGoal = Object.keys(journal).reduce((total, key) => total + journal[key]['goal'], 0);
  const totalSales = Object.keys(journalCalc).reduce((total, key) => total + journalCalc[key]['sum_sales'], 0);
  const workDays = Object.keys(journalCalc).length;
  const workDaysGoal = Object.keys(journal).length;
  const totalVisits = Object.keys(journalCalc).reduce((total, key) => total + journalCalc[key]['entries'], 0);
  const avgDailyGoal = totalGoal > 0 ? totalGoal / workDaysGoal : 0;
  const avgDailySales = totalSales > 0 ? totalSales / workDays : 0;
  const efficiency = totalSales > 0 && totalVisits > 0 ? totalSales / totalVisits : 0;

  return (
    <div className="panel">
      <div className="panel__header">
        <h1 className="panel__title">Visão geral</h1>
        <CompaniesButtons data={data} selectedCompanyId={CID} handleSelectCompany={handleSelectCompany} />
      </div>

      <div className="card__body">
        <div style={{ width: '100%', height: 200 }}>
          <ResponsiveContainer>
            <AreaChart data={journal} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
              <XAxis tick={{ fontSize: 11 }} dataKey="xAxis" stroke="#333" tickLine={false} />
              <YAxis tick={{ fontSize: 11 }} width={45} stroke="#999" tickLine={false} />
              <defs>
                <linearGradient id="colorGoal" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ea5b3a" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#ea5b3a" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Tooltip content={<CustomTooltip />} />
              <CartesianGrid stroke="#F0F0F0" />
              <Area type="monotone" name="Vendas" connectNulls dataKey="sum_sales" unit="€" fill="#56CCF2" />
              <Area
                type="monotone"
                connectNulls
                name="Objectivo"
                dataKey="goal"
                stroke="#ea5b3a"
                fillOpacity={0.3}
                fill="url(#colorGoal)"
                unit="€"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <TabSwiper tabsLabels={['Vendas', 'Visitas']}>
          <div className="wrapper wrapper--hidden">
            <p className="--ta-center">Média diária</p>
            <Grid container justifyContent="center" spacing={5}>
              <Grid item className="--ta-center">
                <p className="big --t-bold --t-alert --nomargin">{currencyFormatDE(avgDailyGoal, true, 0)}</p>
                <p className="small text-mutted --t-bold">objectivo</p>
              </Grid>
              <Grid item className="--ta-center">
                <p className="big --t-bold --t-success --nomargin">{currencyFormatDE(avgDailySales, true, 0)}</p>
                <p className="small text-mutted --t-bold">vendas</p>
              </Grid>
            </Grid>
          </div>

          <div className="wrapper wrapper--hidden">
            <p className="--ta-center">No mês</p>
            <Grid container justifyContent="center" spacing={5}>
              <Grid item className="--ta-center">
                <p className="big --t-bold --t-visits --nomargin">{totalVisits}</p>
                <p className="small text-mutted --t-bold">clientes</p>
              </Grid>
              <Grid item className="--ta-center">
                <p className="big --t-bold --t-success --nomargin">{currencyFormatDE(efficiency)}</p>
                <p className="small text-mutted --t-bold">eficiência</p>
              </Grid>
            </Grid>
          </div>
        </TabSwiper>
      </div>
    </div>
  );
}

export default withCompanySetter(SalesHomeChart);
