const initialState = {
  isLoading: false,
  errors: null,
  pendingSurveys: [],
}

export default function teamReducer(
  state = JSON.parse(JSON.stringify(initialState)),
  action
) {
  let started = ['_LOADING', '_START'].find((item) =>
      action.type.includes(item)
    ),
    finished = ['_LOADED', '_FINISHED'].find((item) =>
      action.type.includes(item)
    )

  switch (action.type) {
    case 'PENDING_SURVEYS_LOADED':
      Object.assign(state, {
        pendingSurveys: action.payload,
      })
      break

    default:
      break
  }

  if (started) Object.assign(state, { isLoading: true, errors: null })
  if (finished) Object.assign(state, { isLoading: false })
  return { ...state }
}
