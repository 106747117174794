import React from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Input,
  IconButton,
  withWidth,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import NumberFormat from 'react-number-format'

const Installment = ({
  index,
  date,
  amount,
  handleDelete,
  handleChange,
  wallet_destination,
  wallets,
  width,
}) => {
  const confirming_wallet = wallets.find(
    (wallet) => wallet.slug === 'confirming'
  )
  return (
    <div className="installment">
      <Grid
        container
        spacing={width === 'xs' ? 8 : 16}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={1}>
          <p className="small">#</p>
          <p>
            <b>{index + 1}</b>
          </p>
        </Grid>
        <Grid item xs={3}>
          <p className="smaller">Data</p>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              fullWidth
              name="date"
              //   error={errors && errors['date'] ? true : false}
              //   helperText={errors && errors['date']}
              showTodayButton={true}
              todayLabel="Hoje"
              value={date}
              format={`${width === 'xs' ? 'DD/MM/YYYY' : 'DD MMM YYYY'}`}
              onChange={(date) => {
                handleChange()(null, 'date', date, 'date')
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={3} className="--ta-right">
          <p className="smaller">Valor</p>
          <NumberFormat
            name="amount"
            value={amount}
            onChange={(e) => handleChange()(e, null, null, 'number')}
            customInput={Input}
            inputProps={{
              style: { textAlign: 'right' },
            }}
            decimalSeparator=","
            thousandSeparator="."
            decimalScale={2}
          />
        </Grid>
        <Grid item xs={3} className="--ta-right">
          <FormControlLabel
            control={
              <Checkbox
                checked={wallet_destination}
                onChange={(e) =>
                  handleChange()(e, 'wallet_destination', null, 'checkbox')
                }
                color="primary"
                value={`${confirming_wallet.id}`}
              />
            }
            label="Confirming"
            labelPlacement="top"
          />
        </Grid>
        <Grid item xs={2} className="--ta-right">
          <IconButton onClick={handleDelete}>
            <DeleteIcon fontSize="small" className="text-color danger" />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    wallets: state.cashflow.wallets,
  }
}
export default connect(mapStateToProps)(withWidth()(Installment))
