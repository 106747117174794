import axios from 'axios'

import moment from 'moment'

import { BASE_API_URL } from '../urls'
import { setHeadersToken } from '../../utils/helpers/store'
import { handleErrors } from '../../utils/helpers/errors'

export const loadEntries = (range = null, getCompare = false) => {
  return (dispatch, getState) => {
    dispatch({ type: 'ENTRIES_LOAD' })

    let { currentRange } = getState().analytics

    if (!range) range = currentRange
    if (!range) console.log('Error: loadEntries whithout range')

    let url = `${BASE_API_URL}entries/get/${range[0]}/${range[1]}/`

    /**
     *  If getCompare, means the journal and accumulated have already been loaded
     */

    if (!getCompare) {
      dispatch({ type: 'CLEAR_COMPARE' })
      dispatch({ type: 'LOAD_COMPARE', payload: false })
      axios
        .get(url, { headers: setHeadersToken() })
        .then(res => {
          dispatch({ type: 'ENTRIES_LOAD_SUCCESS', payload: res.data })
        })
        .catch(error => {
          handleErrors(error, dispatch, 'ENTRIES_LOAD_FAILURE')
        })
    }

    /**
     * getCompare will only load the compare and loadCompare means
     * everytime that loads the journal, the compare will be loaded too
     */
    if (!(getState().analytics.journal.compare.length > 0)) {
      let compare_range,
        compare_url,
        { loadCompare } = getState().analytics
      if (getCompare || loadCompare) {
        compare_range = [
          moment(range[0], 'YYYY-MM-DD')
            .subtract(1, 'years')
            .format('YYYY-MM-DD'),
          moment(range[1], 'YYYY-MM-DD')
            .subtract(1, 'years')
            .format('YYYY-MM-DD')
        ]
        compare_url = `${BASE_API_URL}journal/${compare_range[0]}/${
          compare_range[1]
        }/`

        axios
          .get(compare_url, { headers: setHeadersToken() })
          .then(res => {
            dispatch({ type: 'JOURNAL_COMPARE_LOADED', payload: res.data })
          })
          .catch(error => {
            handleErrors(error, dispatch)
          })
      }
    }
  }
}

export const deleteEntry = (id, deleteNextEntries) => {
  return (dispatch, getState) => {
    dispatch({ type: 'DELETE_ENTRY_START' })
    axios
      .delete(
        `${BASE_API_URL}entries/${id}/${
          deleteNextEntries ? 'deleteNextEntries/' : ''
        }`,
        {
          headers: setHeadersToken()
        }
      )
      .then(res => {
        dispatch({ type: 'DELETE_ENTRY_SUCCESS', payload: res.data })
        dispatch(loadEntries())
      })
      .catch(error => {
        handleErrors(error, dispatch, 'DELETE_ENTRY_FAILED')
      })
  }
}

export const loadFromCashflow = what => {
  return (dispatch, getState) => {
    if (typeof what !== 'string') return console.log('Not a string')

    let what_prefix = what.toUpperCase()
    dispatch({ type: what_prefix + '_LOAD' })
    axios
      .get(`${BASE_API_URL}${what}/`, {
        headers: setHeadersToken()
      })
      .then(res => {
        dispatch({ type: what_prefix + '_LOAD_SUCCESS', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch, what_prefix + '_LOAD_FAILURE')
      })
  }
}
