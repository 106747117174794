import { Grid } from '@material-ui/core';

import { Journal as JournalType } from '../../../@core/domain/entities/Journal';
import { filterData, filterUntilNow } from '../../utils/functions';

import { JournalEntry } from './JournalEntry';

type JournalProps = {
  data: JournalType[];
  CID: number;
};
export function Journal({ data, CID }: JournalProps) {
  const filteredData = filterData(data, CID, true);
  const journal = filterUntilNow(filteredData).sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

  return (
    <Grid container spacing={5} className="journal__data">
      {journal.map(
        (item, index) => {
          return item.sum_sales !== null && <JournalEntry key={index} journal={item} />}
      )}
    </Grid>
  );
}
