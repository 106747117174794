const initialState = {
  isLoading: false,
  errors: [],
  on: false,
};

export default function serverReducer(state = initialState, action) {
  function setErrors(errors) {
    let localErrors = [];
    errors.forEach((error) => localErrors.push({ ...error }));
    return {
      ...state,
      on: false,
      isLoading: false,
      errors: [...errors],
    };
  }
  switch (action.type) {
    case 'SERVER_OK':
    case 'INTERNET_OK':
      return { ...state, errors: [], on: true, isLoading: false };

    case 'SAGE_SERVER_ERROR':
      return setErrors(action.payload.map(({ service, error }) => ({ code: service, content: error })));

    case 'SERVER_ERROR_UNKNOWN':
      return setErrors([{ code: '666', content: 'Erro desconhecido.' }]);

    case 'SERVER_ERROR_500':
      return setErrors([{ code: '500', content: 'Erro de servidor' }]);

    case 'OFFLINE':
      return setErrors([{ code: 'off', content: 'Você está sem conexão.' }]);

    case 'ONLINE':
      return { ...initialState };

    default:
      return state;
  }
}
