import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Hidden, MenuItem, IconButton, Menu } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import { companiesSelector } from '../../@core/infrastructure/redux/selectors';

const CompaniesButtons = React.memo(({ selectedCompanyId, handleSelectCompany, data }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { list: companies } = useSelector(companiesSelector);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <Hidden xsDown>
        <div>
          <Grid container spacing={1} justifyContent="flex-end">
            {Object.values(companies).map((company, index) => (
              <Grid item key={index}>
                <Button
                  color="primary"
                  disabled={selectedCompanyId === company.id && true}
                  className={selectedCompanyId === company.id ? 'btn--company selected' : ''}
                  onClick={() => handleSelectCompany(company.id)}
                >
                  {company.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="btns">
          <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
          <Menu id="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
            {Object.values(companies).map((company, index) => (
              <MenuItem
                selected={selectedCompanyId === company.id && true}
                key={index}
                onClick={() => {
                  handleSelectCompany(company.id);
                  setAnchorEl(null);
                }}
              >
                {company.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Hidden>
    </Fragment>
  );
});

export default CompaniesButtons;
