import React from 'react'
import { connect } from 'react-redux'
import { withWidth } from '@material-ui/core'
import { currencyFormatDE } from '../utils/currency'

const EntryItem = ({
  entry,
  handleEdit,
  width,
  isLoading,
  handleOpenDelete,
  extraClass,
  hideActionButtons
}) => {
  const {
    activity_type,
    is_forecast,
    amount,
    category,
    account,
    wallet_source,
    wallet_destination,
    is_tax
  } = entry

  let accountName = is_forecast ? 'Vendas' : account ? account.name : ''
  if (accountName.length > 15)
    accountName = accountName.substring(0, 15) + '...'
  return (
    <div
      className={`entry entry-cel ${extraClass} ${
        activity_type === 2 ? (is_forecast ? 'predict' : 'income') : ''
      }`}
    >
      <div
        className={`container ${!is_tax ? 'clickable' : ''} ${
          !hideActionButtons ? 'md-11' : ''
        }`}
        onClick={() => !is_tax && handleEdit()}
      >
        <div className="entry__item xs-8">
          <div className="entry__col">
            <p className="entry__account">{accountName}</p>
            <p className="entry__category">
              {wallet_destination &&
              wallet_destination.slug === 'confirming' ? (
                category && category.name ? (
                  <span>
                    <s>{category.name}</s> Confirming
                  </span>
                ) : (
                  'Confirming'
                )
              ) : (
                category && category.name
              )}
              {is_forecast && 'Previsão'}
              {wallet_source &&
                wallet_source.slug === 'confirming' &&
                'Pgto de confirming'}
            </p>
          </div>
        </div>
        <div className="entry__item xs-4">
          <div className="entry__col">
            <p className="entry__amount">
              {currencyFormatDE(amount, false, 0, true)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoading: state.cashflow.isLoading
  }
}
export default connect(mapStateToProps)(withWidth()(EntryItem))
