import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated || localStorage.getItem('token') !== null)
          return <Component {...props} />

        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(PrivateRoute)
