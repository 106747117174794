import React, { Component } from 'react'

import SalesReport from '../../../components/Analytics/SalesReport'

class Reports extends Component {
  render() {
    return <SalesReport />
  }
}

export default Reports
