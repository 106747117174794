import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { Button, Grid } from '@material-ui/core';

import Preloader from '../utils/Preloader';
import ResponsiveDialog from '../utils/ResponsiveDialog';
import { setHeadersToken } from '../utils/helpers/store';
import { companiesSelector } from '../../@core/infrastructure/redux/selectors';

import { setDate } from './actions/analyticsActions';
import { BASE_API_URL as SALES_URL } from './urls';
import { MonthSalesListContext } from './MonthSalesList/MonthSalesList';

export function AddDepositPhotoDialog({ open, journal }) {
  const [photo, setPhoto] = useState(null);
  const [photoPreviewUrl, setPhotoPreviewUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [errors, setErrors] = useState({});
  const { list: companies } = useSelector(companiesSelector);

  const dispatch = useDispatch();

  const { handleCloseAddDepositPhotoDialog } = useContext(MonthSalesListContext);

  function handleChangeImage(e) {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        setPhoto(file);
        setPhotoPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPhoto('');
      setPhotoPreviewUrl('');
    }
  }

  function handleClose() {
    handleCloseAddDepositPhotoDialog();
    // setPhoto(null)
    // setPhotoPreviewUrl(null)
    // setIsLoading(false)
    // setSuccessMessage('')
    // setFailedMessage('')
    // setErrors('')
  }

  function handleSubmit() {
    const data = {
      id: journal.id,
      photo,
    };

    setIsLoading(true);

    successMessage && setSuccessMessage('');
    failedMessage && setFailedMessage('');
    errors.length > 0 && setErrors({});

    const formData = new FormData();
    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    axios
      .post(`${SALES_URL}journal/update/`, formData, {
        headers: setHeadersToken(),
      })
      .then((res) => {
        setSuccessMessage(res.data.msg);
        dispatch(setDate());
      })
      .catch((error) => {
        if (error.response.data) {
          let errors = {};
          Object.keys(error.response.data).forEach((field) => (errors[field] = error.response.data[field]));

          setErrors({ ...errors });
        }
        if (error.response.data.msg) {
          setFailedMessage(error.response.data.msg);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  if (!journal) {
    return null;
  }

  const company = companies.find((company) => company.id === journal.company);

  return (
    <ResponsiveDialog
      open={open}
      scroll="body"
      handleCloseDialog={handleClose}
      dialogTitle={'Envio de foto'}
      failedMessage={failedMessage}
      successMessage={successMessage}
      actions={
        isLoading ? (
          <Preloader />
        ) : (
          <Button
            onClick={handleSubmit}
            className="action-btn"
            color="primary"
            variant="contained"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            disabled={!photo ? true : false}
          >
            Registar
          </Button>
        )
      }
    >
      <Grid item xs={12} className="--ta-center">
        <div className="grid__container grid__container--column spacing-32">
          <div className="grid__item">
            <div className="grid__container grid__container--column spacing-16">
              <div className="grid__item">
                <label className="overline">Data</label>
                <p className="h6">{moment(journal.date).format('l')}</p>
              </div>
              <div className="grid__item">
                <label className="overline">Loja</label>
                <p className="h6">{company.name}</p>
              </div>
            </div>
          </div>
          <div className="grid__item">
            <input
              accept="image/*"
              id="photo"
              type="file"
              name="photo"
              style={{ display: 'none' }}
              onChange={handleChangeImage}
            />
            <label className="btn btn--primary outlined rounded" htmlFor="photo">
              <div className="chip grid__container --align-center spacing-8">
                <span className="material-icons">{!photo ? 'camera_alt' : 'sync'}</span>

                <div className="grid__item">{!photo ? 'Inserir' : 'Trocar'} foto</div>
              </div>
            </label>
          </div>
          {photoPreviewUrl && (
            <div className="grid__item">
              <img src={photoPreviewUrl} alt="Prévia" style={{ width: 'auto', height: 'auto', maxHeight: 250 }} />
            </div>
          )}
        </div>
      </Grid>
    </ResponsiveDialog>
  );
}
