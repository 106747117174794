import React, { Fragment } from 'react';
import { Dialog, DialogTitle, Grid, IconButton, Slide, Button, useMediaQuery, useTheme } from '@material-ui/core';
import { Close as CloseIcon, Done as SuccessIcon, Warning as FailIcon } from '@material-ui/icons';
import PreloaderOverlay from './PreloaderOverlay';

const Transition = React.forwardRef((props, ref) => {
  return <Slide ref={ref} direction="up" {...props} />;
});

export default function ResponsiveDialog({
  failedMessage,
  failedComponent,
  successMessage,
  successComponent,
  header,
  dialogTitle,
  dialogSubTitle,
  open,
  handleCloseDialog,
  hideCloseButton,
  children,
  isLoading,
  actions,
  scroll = 'paper',
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth
      scroll={scroll}
      open={open}
      onClose={!hideCloseButton ? handleCloseDialog : null}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog"
      PaperProps={{
        className: 'dialog__container',
      }}
    >
      {isLoading && !failedMessage && !successMessage && <PreloaderOverlay />}

      {!hideCloseButton && (
        <div className="close-btn">
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      )}

      {dialogTitle && !header && (
        <div className="dialog__header">
          <DialogTitle id="alert-dialog-title" className="dialog__header">
            {
              <Grid container alignItems="center" justifyContent="space-between" direction="row">
                <Grid item>
                  <h1 className="dialog__title">{dialogTitle}</h1>
                  {dialogSubTitle && <p className="dialog__subtitle">{dialogSubTitle}</p>}
                </Grid>
              </Grid>
            }
          </DialogTitle>
        </div>
      )}
      {header}
      {successComponent || failedComponent ? (
        <>
          {successComponent}
          {failedComponent}
        </>
      ) : failedMessage || successMessage ? (
        <div className="--ta-center">
          <div className="dialog__body">
            <div className="dialog__icon">
              {successMessage && <SuccessIcon className="--t-success" />}
              {failedMessage && <FailIcon className="--t-warning" />}
            </div>
            {successMessage && typeof successMessage === 'object' ? (
              successMessage
            ) : (
              <p dangerouslySetInnerHTML={{ __html: successMessage }} />
            )}
            {failedMessage && typeof failedMessage === 'object' ? (
              failedMessage
            ) : (
              <p dangerouslySetInnerHTML={{ __html: failedMessage }} />
            )}
          </div>
        </div>
      ) : (
        <div className="dialog__body">{children}</div>
      )}

      <div className={`dialog__action-btns ${failedMessage || successMessage ? '--justify-center' : ''}`}>
        {failedMessage || successMessage ? (
          <Button className="action-btn" color="primary" onClick={handleCloseDialog}>
            Ok
          </Button>
        ) : (
          actions
        )}
      </div>
    </Dialog>
  );
}
