import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import { currencyFormatDE } from '../utils/currency'
import Compare from './Compare'

const currentShifts = ({ data, allShifts }) => {
  let shifts = []
  data.forEach(item => {
    Object.keys(item).forEach(key => {
      /** Check current or compare in each journal item */
      if (['current', 'compare'].includes(key)) {
        /** Check if there are sales */
        if (item[key]['sales'] && item[key]['sales'].length > 0) {
          /** Foreach sale check if the array already has the shift */
          item[key]['sales'].forEach(sale => {
            let idx = shifts.findIndex(shift => shift.id === sale.shift.id)
            /** If not, get the shift from global state */
            if (idx < 0) {
              let shift = allShifts.find(
                shift => shift.id === sale.shift.id
              ) || {
                ...sale.shift,
                name: 'Turno(s) Desativado(s)'
              }
              shifts.push({ ...shift })
            }
          })
        }
      }
    })
  })
  // console.log(shifts)
  shifts = shifts.sort((a, b) =>
    a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0
  )
  return shifts
}

class TableAccumulated extends Component {
  render() {
    const { data } = this.props
    let shiftsHeading = [...currentShifts(this.props)]

    let accumulated = { current: 0, compare: 0 }

    const rows = data.map((item, index) => {
      let sumSales = { current: null, compare: null },
        goal = 0,
        diff = 0
      Object.keys(item).forEach(key => {
        if (['current', 'compare'].includes(key)) {
          if (item[key]['sum_sales']) {
            sumSales[key] = item[key]['sum_sales']
            accumulated[key] += item[key]['sum_sales']
          }
          if (item['current']) {
            if (item['current']['goal']) {
              goal = item['current']['goal']
            }
            if (item['current']['sum_sales'] && item['current']['goal']) {
              diff = item['current']['sum_sales'] - item['current']['goal']
            }
          }
        }
      })

      return (
        <TableRow key={index} className="list__item">
          {/* xAxis  */}
          <TableCell scope="row" style={{ whiteSpace: 'nowrap' }}>
            {item.xAxis}
          </TableCell>

          {/* shifts */}
          {shiftsHeading.map((shift, index) => {
            let shiftSale = { current: null, compare: null }

            Object.keys(item).forEach(key => {
              if (['current', 'compare'].includes(key)) {
                if (item[key]['sales'].length > 0) {
                  shiftSale[key] = item[key]['sales'].reduce((acc, sale) => {
                    if (sale.shift.id === shift.id) return sale.amount + acc
                    return acc
                  }, 0)
                }
              }
            })
            return (
              <TableCell key={index} align="right">
                <Compare
                  value={shiftSale.current}
                  valueString={currencyFormatDE(
                    shiftSale.current,
                    false,
                    0,
                    true
                  )}
                  justifyContent="end"
                  compare={shiftSale.compare}
                />
              </TableCell>
            )
          })}

          {/* xAxis Total  */}
          <TableCell align="right">
            <Compare
              value={sumSales.current}
              valueString={currencyFormatDE(sumSales.current, false, 0, true)}
              justifyContent="end"
              compare={sumSales.compare}
            />
          </TableCell>

          {/* goal  */}
          <TableCell align="right">
            {currencyFormatDE(goal, false, 0, true)}
          </TableCell>

          {/* difference  */}
          <TableCell align="right">
            {currencyFormatDE(diff, false, 0, true, '(', ')')}
          </TableCell>

          {/* accumulated  */}
          <TableCell align="right">
            <Compare
              value={accumulated.current}
              valueString={currencyFormatDE(
                accumulated.current,
                false,
                0,
                true
              )}
              justifyContent="end"
              compare={accumulated.compare}
            />
          </TableCell>
        </TableRow>
      )
    })

    const headRows = (
      <TableRow className="list__item list__item--title">
        <TableCell />
        {shiftsHeading.map((shift, idx) => (
          <TableCell key={idx} align="right">
            {shift.name}
          </TableCell>
        ))}
        <TableCell align="right">TOTAL</TableCell>
        <TableCell align="right">Objectivo</TableCell>
        <TableCell align="right">Diferença</TableCell>
        <TableCell align="right">Acumulado</TableCell>
      </TableRow>
    )

    return (
      <div style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>{headRows}</TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    allShifts: state.companies.shifts
  }
}
export default connect(mapStateToProps)(TableAccumulated)
