import React, { Component } from 'react'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { Grid, IconButton, Collapse } from '@material-ui/core'

class Collapsible extends Component {
  state = {
    expanded: true,
  }
  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }
  render() {
    const { title, collapsed, children, icon } = this.props
    let { expanded } = this.state

    if (collapsed === true) expanded = !expanded

    return (
      <div className="wrapper wrapper--hidden">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems="center" spacing={1}>
              {icon && (
                <Grid item className="panel__icon">
                  {icon}
                </Grid>
              )}
              <Grid item>
                {typeof title === 'string' ? (
                  <h2 className="panel__subtitle panel__subtitle--collapse">
                    {title}
                  </h2>
                ) : (
                  title
                )}
              </Grid>
              <Grid item>
                <IconButton
                  onClick={this.handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Detalhes"
                >
                  <ExpandMoreIcon
                    className={`expand-icon ${expanded ? 'expanded' : ''} `}
                    fontSize="small"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse
              className="card__body-wrapper"
              in={expanded}
              timeout="auto"
              unmountOnExit
            >
              {children}
            </Collapse>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Collapsible
