import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Grid, Collapse, IconButton } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import CompaniesButtons from '../CompaniesButtons';
import withCompanySetter from '../hoc/withCompanySetter';
import { AddDepositPhotoDialog } from '../AddDepositPhotoDialog';
import { Journal } from './Journal';

export const MonthSalesListContext = React.createContext({
  handleOpenLightBox: (src) => {},
  handleCloseLightBox: () => {},
  handleOpenUploadPhoto: (journalId) => {},
  handleCloseAddDepositPhotoDialog: () => {},
});

function MonthSalesList({ CID, handleSelectCompany, data }) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [photoSrc, setPhotoSrc] = useState('');
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const [isSddDepositPhotoDialogOpen, setIsAddDepositPhotoDialogOpen] = useState(false);
  const [selectedJournal, setSelectedJournal] = useState(null);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenLightBox = (src) => {
    setPhotoSrc(src);
    setIsOpenLightBox(true);
  };
  const handleCloseLightBox = () => {
    setPhotoSrc('');
    setIsOpenLightBox(false);
  };

  const handleOpenUploadPhoto = (journalId) => {
    const journal = data.find((item) => item.id === journalId);
    if (journal) {
      setIsAddDepositPhotoDialogOpen(true);
      setSelectedJournal(journal);
    }
  };
  const handleCloseAddDepositPhotoDialog = () => {
    setIsAddDepositPhotoDialogOpen(false);
  };

  if (!CID) {
    return null;
  }

  return (
    <MonthSalesListContext.Provider
      value={{ handleOpenLightBox, handleCloseLightBox, handleOpenUploadPhoto, handleCloseAddDepositPhotoDialog }}
    >
      <AddDepositPhotoDialog open={isSddDepositPhotoDialogOpen} journal={selectedJournal} />
      <div className="card">
        <div className="card__header">
          <div>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <h1 className="panel__title">Lançamentos</h1>
              </Grid>
              <Grid item>
                <IconButton onClick={handleExpandClick} aria-expanded={isExpanded} aria-label="Detalhes">
                  <ExpandMore className={`expand-icon ${isExpanded ? 'expanded' : ''} `} fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <CompaniesButtons data={data} selectedCompanyId={CID} handleSelectCompany={handleSelectCompany} />
        </div>
        <Collapse className="card__body-wrapper" in={isExpanded} timeout="auto" unmountOnExit>
          <div className="card__body">
            <Journal data={data} CID={CID} />
          </div>
        </Collapse>
      </div>
      {isOpenLightBox && <Lightbox mainSrc={photoSrc} onCloseRequest={handleCloseLightBox} />}
    </MonthSalesListContext.Provider>
  );
}

export default withCompanySetter(MonthSalesList);
