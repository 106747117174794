import moment from 'moment';
import { store } from '../../index';
import { format_number } from './currency';

/**
 * Filter raw data coming from analytics.journal
 *
 * @param {object} data data coming from analytics.journal
 * @param {int} CID company id
 * @param {boolean} workDays work days means days with sales
 */
export function filterData(data, CID = null, workDays = false) {
  let temp = JSON.parse(JSON.stringify(data));

  return Object.values(temp).filter((item) => {
    item.xAxis = moment(item.date).format('DD');

    if (workDays && !(item.sales.length > 0)) return false;
    if (CID && item.company !== CID) return false;
    if (moment(item.date).isoWeekday() === 7) return false;

    return item;
  });
}

/**
 * Function to check if the date is equal or smaller than now
 *
 * When isComparing current to previous year the current year will be the
 * same as previous and check date and month
 *
 * @param {string|moment} date
 * @param {bool} isComparing
 */
export function isDateUntilNow(date, period = 'current') {
  let now = moment(),
    compare = moment(date);

  if (period === 'compare') {
    if (Math.round(moment.duration(now.diff(compare)).asYears()) >= 2) return true;

    now.year(compare.year());
    if (compare.month() === now.month() && compare.date() > now.date()) return false;
  }

  return compare <= now;
}

/**
 * Filter until now the given raw data using the isDateUntilNow function
 * Used to calculate the accumulated
 *
 * @param {object} data
 */
export function filterUntilNow(data) {
  let temp = JSON.parse(JSON.stringify(data));
  return Object.values(temp).filter((item) => isDateUntilNow(item.date));
}

export function filterJournal(journal, CID = null) {
  let filteredData = {};
  Object.entries(journal).forEach((entry) => {
    const period = entry[0],
      data = entry[1];
    filteredData[period] = filterData(data, CID);
  });
  return filteredData;
}

/**
 * Mount the base chart with xAxis and xAxisDate to be populated from the
 * analytics.currentRange goten from store
 *
 * @param {string} periodicity 'daily','weekly', 'monthly'
 */
function mountChartData(periodicity = 'daily') {
  const { rangeStart, rangeEnd } = getRange();
  let chartData = [],
    loopDate = moment(rangeStart);

  switch (periodicity) {
    case 'quarterly':
      for (let i = 0; i <= rangeEnd.diff(rangeStart, 'quarters'); i++) {
        chartData.push({
          month: loopDate.month() + 1,
          year: loopDate.year(),
          xAxis: `${loopDate.format('MMM')} - ${moment(loopDate)
            .add(1, 'quarters')
            .subtract(1, 'month')
            .format('MMM')}`,
          xAxisDate: loopDate.format('YYYY-MM-DD'),
        });
        loopDate.add(1, 'quarters');
      }
      break;

    case 'monthly':
      for (let i = 0; i <= rangeEnd.diff(rangeStart, 'months'); i++) {
        chartData.push({
          month: loopDate.month() + 1,
          year: loopDate.year(),
          xAxis: `${loopDate.format('MMM')}`,
          xAxisDate: loopDate.format('YYYY-MM-DD'),
        });
        loopDate.add(1, 'months');
      }
      break;

    case 'weekly':
      loopDate = loopDate.isoWeekday(1);
      if (rangeEnd.isoWeekday(7).isoWeek() === 1) rangeEnd.subtract(1, 'weeks');

      for (let i = 0; i <= rangeEnd.isoWeekday(7).diff(rangeStart.isoWeekday(1), 'weeks'); i++) {
        chartData.push({
          week: loopDate.isoWeek(),
          xAxisDate: loopDate.format('YYYY-MM-DD'),
        });
        loopDate.add(1, 'weeks');
      }

      break;

    default:
      // daily
      for (let i = 0; i <= rangeEnd.diff(rangeStart, 'days'); i++) {
        if (rangeStart.month() === rangeEnd.month()) {
          chartData.push({
            xAxis: loopDate.format('DD'),
            xAxisDate: loopDate.format('YYYY-MM-DD'),
          });
        } else {
          chartData.push({
            xAxis: loopDate.format('DD/MM'),
            xAxisDate: loopDate.format('YYYY-MM-DD'),
          });
        }
        loopDate.add(1, 'day');
      }
      break;
  }

  return chartData;
}

function getChartDataIndex(chartData, item, periodicity, period = null) {
  let idx = -1;

  function isSame(granularity, xAxisDate, itemDate, period) {
    let chartDate = moment(xAxisDate);
    if (period === 'compare') chartDate.subtract(1, 'years');
    return chartDate.isSame(itemDate, granularity);
  }

  switch (periodicity) {
    case 'weekly':
      let week = item.isoweek,
        firstDayOfWeek = moment(item.date).isoWeekday(1),
        lastDayOfWeek = moment(item.date).isoWeekday(7);

      idx = chartData.findIndex((it) => it.week === week);

      let xAxis = `${moment(firstDayOfWeek).format('D')} - ${moment(lastDayOfWeek).format('D MMM')}`;
      if (moment(firstDayOfWeek).month() !== moment(lastDayOfWeek).month())
        xAxis = `${moment(firstDayOfWeek).format('D MMM')} - ${moment(lastDayOfWeek).format('D MMM')}`;

      if (idx < 0) break;

      if (chartData[idx]['xAxis']) {
        if (chartData[idx]['xAxis'] === '()') chartData[idx]['xAxis'] = `(${xAxis})`;
        if (!chartData[idx]['xAxis'].includes(xAxis)) chartData[idx]['xAxis'] += ` (${xAxis})`;
      } else {
        chartData[idx]['xAxis'] = xAxis;
      }
      chartData.sort((a, b) => a.week - b.week);

      break;

    case 'monthly':
      idx = chartData.findIndex((it) => isSame('month', it.xAxisDate, item.date, period));
      break;
    case 'quarterly':
      idx = chartData.findIndex((it) => isSame('quarter', it.xAxisDate, item.date, period));
      break;

    default:
      // daily
      idx = chartData.findIndex((it) => {
        return isSame('day', it.xAxisDate, item.date, period);
      });
      break;
  }
  return idx;
}

/**
 * setReportData
 * Organize src (state.journal) into a object to be used on charts
 *
 * @param {object} src {current:[...], compare:[...]}
 * @param {*} periodicity
 * @param {*} filterUntilNow
 */
export function setReportData(src, periodicity, filterUntilNow = false) {
  let chartData = [...mountChartData(periodicity)];

  Object.entries(src).forEach((entry) => {
    let period = entry[0], // current or compare
      data = entry[1]; // array of jounral entries

    data.forEach((item) => {
      if (filterUntilNow && !isDateUntilNow(item.date, period)) return;

      let idx = getChartDataIndex(chartData, item, periodicity, period);

      if (idx < 0) return;

      /**
       * Update chartData with values
       */

      if (!chartData[idx][period])
        Object.assign(chartData[idx], {
          [period]: {
            goal: 0,
            goal_secondary: 0,
            profit_goal: 0,
            profit_goal_secondary: 0,
            sum_sales: 0,
            sum_sales_net: 0,
            sum_sales_cost: 0,
            sum_sales_profit: 0,
            entries: 0,
            visits: { by_hour: [], by_shift: [] },
            sales: [],
            sales_by_hour: [],
          },
        });
      [
        'goal',
        'goal_secondary',
        'profit_goal',
        'profit_goal_secondary',
        'sum_sales',
        'sum_sales_net',
        'sum_sales_cost',
        'sum_sales_profit',
        'entries',
      ].forEach((it) => (chartData[idx][period][it] += item[it] || 0));
      let zero_test = [
        chartData[idx][period]['sum_sales_net'] - chartData[idx][period]['sum_sales_cost'] !== 0,
        chartData[idx][period]['sum_sales_net'] !== 0,
      ];
      chartData[idx][period]['profit_pct'] = zero_test.every(Boolean)
        ? ((chartData[idx][period]['sum_sales_net'] - chartData[idx][period]['sum_sales_cost']) /
            chartData[idx][period]['sum_sales_net']) *
          100
        : 0;
      /**
       * Visits
       */
      item.visits['by_hour'].forEach((visit) => {
        let index = chartData[idx][period]['visits']['by_hour'].findIndex((it) => it.hour === visit.hour);
        if (index < 0) {
          index = chartData[idx][period]['visits']['by_hour'].push({
            hour: visit.hour,
            entries: 0,
          });
          index--;
        }
        chartData[idx][period]['visits']['by_hour'][index]['entries'] += visit.entries;
      });
      item.visits['by_shift'].forEach((visit) => {
        let index = chartData[idx][period]['visits']['by_shift'].findIndex((it) => it.shift === visit.shift);
        if (index < 0) {
          index = chartData[idx][period]['visits']['by_shift'].push({
            shift: visit.shift,
            entries: 0,
          });
          index--;
        }
        chartData[idx][period]['visits']['by_shift'][index]['entries'] += visit.entries;
      });

      chartData[idx][period]['efficiency'] =
        chartData[idx][period]['entries'] > 0
          ? chartData[idx][period]['sum_sales'] / chartData[idx][period]['entries']
          : 0;

      /**
       * Sales
       */
      item.sales.forEach((sale) => {
        let idxSaleShift = chartData[idx][period]['sales'].findIndex((it) => it.id === sale.id);
        if (idxSaleShift < 0) {
          idxSaleShift = chartData[idx][period]['sales'].push({
            shift: sale.shift,
            amount: 0,
            amountNet: 0,
            amountCost: 0,
          });
          idxSaleShift--;
        }
        chartData[idx][period]['sales'][idxSaleShift]['amount'] += sale.amount;
        chartData[idx][period]['sales'][idxSaleShift]['amountCost'] += sale.amount_cost;
        chartData[idx][period]['sales'][idxSaleShift]['amountNet'] += sale.amount_net;
        if (periodicity === 'daily') chartData[idx][period]['sales'][idxSaleShift]['sellers'] = sale.sellers;
      });
      /**
       * SAGE Sales
       * It's already grouped by hour on django
       */
      item.sales_by_hour &&
        item.sales_by_hour.forEach((sale) => {
          let index = chartData[idx][period]['sales_by_hour'].findIndex((it) => it.hour === sale.hour);
          if (index < 0) {
            index = chartData[idx][period]['sales_by_hour'].push({
              hour: sale.hour,
              amount: 0,
            });
            index--;
          }
          chartData[idx][period]['sales_by_hour'][index]['amount'] += sale.amount;
        });
      chartData[idx][period]['sales_by_hour'].sort((a, b) => (a.hour > b.hour ? 1 : -1));
    });
  });

  return chartData;
}

export function setCompaniesData(src, periodicity, filterUntilNow = false) {
  let chartData = [...mountChartData(periodicity)];

  // Loop through 'current' and 'compare'
  Object.entries(src).forEach((entry) => {
    let period = entry[0],
      data = entry[1];

    data.forEach((item) => {
      if (filterUntilNow && !isDateUntilNow(item.date, period)) return;

      let idx = getChartDataIndex(chartData, item, periodicity, period);

      if (idx < 0) return;

      /**
       * Update chartData with values
       */
      const companyBase = {
        company: item.company,
        goal: 0,
        sum_sales: 0,
        entries: 0,
        sum_sales_net: 0,
        sum_sales_cost: 0,

        visits: { by_hour: [], by_shift: [] },
        sales: [],
      };

      if (!chartData[idx][period]) Object.assign(chartData[idx], { [period]: [] });

      let idxCompany = chartData[idx][period].findIndex((it) => it.company && it.company === item.company);
      if (idxCompany < 0) {
        idxCompany = chartData[idx][period].push({ ...companyBase });
        idxCompany--;
      }

      ['goal', 'sum_sales', 'sum_sales_net', 'sum_sales_cost', 'entries'].forEach(
        (it) => (chartData[idx][period][idxCompany][it] += item[it] || 0)
      );

      chartData[idx][period] = chartData[idx][period].sort((a, b) => b.sum_sales - a.sum_sales);
    });
  });
  return chartData;
}

export function setEmployeeData(employee, src, periodicity, filterUntilNow = false) {
  let chartData = [...mountChartData(periodicity)];

  Object.entries(src).forEach((entry) => {
    let period = entry[0],
      data = entry[1];

    data.forEach((item) => {
      if (filterUntilNow && !isDateUntilNow(item.date)) return;

      let idx = getChartDataIndex(chartData, item, periodicity, period);

      if (idx < 0) return;

      /**
       * Update chartData with values
       */

      if (!chartData[idx][period])
        Object.assign(chartData[idx], {
          [period]: { sum_sales: 0 },
        });

      chartData[idx][period]['sum_sales'] += item.sales.reduce(
        (total, sale) => (sale.employee === employee.id ? total + sale.amount : total),
        0
      );
    });
  });

  return chartData;
}

/**
 *
 * @param {*} index journalEmployeesBars index
 * @param {*} key current or compare
 * @param {*} item journal item that has the current or compare keys
 * @param {*} employees from mapStateToProps
 * @param {*} journalShiftsBars the items that will be updated
 * @param {*} journalShiftsPie the items that will be updated
 */
export function updateShiftsData(index, key, item, journalShiftsBars, journalShiftsPie) {
  if (!isDateUntilNow(item[key]['date'])) return;

  /**
   * Order sales from greater to lower to display on the chart tooltip
   */
  Object.assign(journalShiftsBars[index], { [key]: { sales: {} } });
  item[key]['sales'] = item[key]['sales'].sort((b, a) => (a.amount > b.amount ? 1 : b.amount > a.amount ? -1 : 0));
  /**
   * Work on individual sales
   */
  item[key]['sales'].forEach((sale) => {
    const shift = sale.shift;

    let slug = shift.slug,
      shiftData = {
        [shift.slug]: {
          shift: { ...shift },
          amount: sale.amount,
          sellers: sale.sellers,
        },
      };
    /**
     * Group inactive shifts to "Others"
     */
    if (!shift.is_active) {
      slug = 'outros';
      shiftData = {
        [slug]: {
          shift: {
            name: 'Turno(s) Desativado(s)',
            bg_color: '#333',
            slug: slug,
          },
          amount: sale.amount,
          sellers: sale.sellers,
        },
      };
    }

    if (!journalShiftsBars[index][key]['sales'][slug]) {
      Object.assign(journalShiftsBars[index][key]['sales'], {
        ...shiftData,
      });
    } else {
      journalShiftsBars[index][key]['sales'][slug]['amount'] += sale.amount;
    }
    // Pie Chart Data
    let idxShiftPie = journalShiftsPie.findIndex((idx) => idx.shift && idx.shift.slug === slug);
    if (idxShiftPie < 0) {
      idxShiftPie = journalShiftsPie.push({
        [key]: { amount: sale.amount },
        shift: { ...shiftData[slug]['shift'] },
      });
      idxShiftPie--;
    } else {
      if (!journalShiftsPie[idxShiftPie][key]) {
        Object.assign(journalShiftsPie[idxShiftPie], {
          [key]: { amount: sale.amount },
        });
      } else {
        journalShiftsPie[idxShiftPie][key]['amount'] += sale.amount;
      }
    }
  });
}
/**
 *
 * @param {*} index journalEmployeesBars index
 * @param {*} key current or compare
 * @param {*} item journal item that has the current or compare keys
 * @param {*} employees from mapStateToProps
 * @param {*} journalCompaniesBars the items that will be updated
 * @param {*} journalCompaniesPie the items that will be updated
 */
export function updateCompaniesData(index, period, item, companies, journalCompaniesBars, journalCompaniesPie) {
  Object.values(item[period]).forEach((data) => {
    const company = companies.find((company) => company.id === data.company);
    /**
     * Order sales from greater to lower to display on the chart tooltip
     */
    if (!journalCompaniesBars[index][period])
      Object.assign(journalCompaniesBars[index], {
        [period]: {},
      });
    Object.assign(journalCompaniesBars[index][period], {
      [data.company]: {
        company: { ...company },
        sum_sales: data.sum_sales,
        sum_sales_cost: data.sum_sales_cost,
        sum_sales_net: data.sum_sales_net,
      },
    });
    let zero_test = [
      journalCompaniesBars[index][period][data.company]['sum_sales_net'] -
        journalCompaniesBars[index][period][data.company]['sum_sales_cost'] !==
        0,
      journalCompaniesBars[index][period][data.company]['sum_sales_net'] !== 0,
    ];
    journalCompaniesBars[index][period][data.company]['profit_pct'] = zero_test.every(Boolean)
      ? format_number(
          ((journalCompaniesBars[index][period][data.company]['sum_sales_net'] -
            journalCompaniesBars[index][period][data.company]['sum_sales_cost']) /
            journalCompaniesBars[index][period][data.company]['sum_sales_net']) *
            100,
          2,
          true
        )
      : 0;

    // Pie Chart Data
    let idxCompanyPie = journalCompaniesPie.findIndex((idx) => idx.company && idx.company.slug === company.slug);
    if (idxCompanyPie < 0) {
      idxCompanyPie = journalCompaniesPie.push({
        company: { ...company },
      });
      idxCompanyPie--;
    }

    if (!journalCompaniesPie[idxCompanyPie][period]) {
      Object.assign(journalCompaniesPie[idxCompanyPie], {
        [period]: {
          amount: data.sum_sales,
          amountCost: data.sum_sales_cost,
          amountNet: data.sum_sales_net,
        },
      });
    } else {
      journalCompaniesPie[idxCompanyPie][period]['amount'] += data.sum_sales;
      journalCompaniesPie[idxCompanyPie][period]['amountCost'] += data.sum_sales_cost;
      journalCompaniesPie[idxCompanyPie][period]['amountNet'] += data.sum_sales_net;
    }
  });
}

/**
 * Function to get the percentage from two values
 * @param {float} current current value
 * @param {float} compare compare value (base for comparison)
 * @returns {float}
 */
export function getPct(current, compare) {
  let result = compare !== 0 ? ((current - compare) / compare) * 100 : 0;

  if (result < 0) result *= -1;
  return result;
}

/**
 * Get current range from store state
 * @returns {object} {rangeStart: moment(), rangeEnd: moment()}
 */
function getRange() {
  let range = store.getState().analytics.currentRange;
  return { rangeStart: moment(range[0]), rangeEnd: moment(range[1]) };
}

/**
 * Function to group itens in array or object
 * @param {array|object} data
 * @param {string} prop property to group (e.g. group by 'company')
 * @returns {object}
 */
export function groupBy(data, prop) {
  let tempData = [];
  if (data instanceof Array) tempData = [...data];
  if (data instanceof Object) tempData = Object.values(data).map((item) => item);

  return tempData.reduce((groups, item) => {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
}
/**
 * Function to return if current user is Admin or Manager
 *
 * @returns {boolean}
 */
export function isAdmin() {
  let user = store.getState().auth.user;
  return user.groups.some((el) => ['Admins', 'Managers'].includes(el));
}
/**
 * Function to return if current user belongs to some group
 * @param {array} groups
 * @returns {boolean}
 */
export function isPermitted(groups) {
  let user = store.getState().auth.user;
  return user.groups.some((el) => groups.includes(el));
}

/**
 * Function to add class text-mutted to texts
 *
 * @param {string} id element id
 */
export function muteHelper(id) {
  let el = document.getElementById('helper-' + id),
    elClasses = el.classList;

  if (el && !elClasses.contains('text-mutted')) {
    el.classList.add('text-mutted');
  }
}

/**
 * Function to add infinite animation from Animate.css but
 * with delay between animation like: shake on every 3s
 *
 * @param {string} id element id
 * @param {string} animation name of animation
 * @param {int} duration
 * @param {int} delay
 */
export function animateInfinite(id, animation, duration, delay) {
  let el = document.getElementById(id),
    timer;
  if (el) {
    el.classList.add('animated');
    function addClass() {
      el.classList.add(animation);
    }
    function removeClass() {
      el.classList.remove(animation);
    }
    setTimeout(() => {
      clearTimeout(timer);
      addClass();
      timer = setTimeout(removeClass, duration);
    }, duration + delay);
  }
}

/**
 * Like python converts first letter to Uppercase
 */
export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
