import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import GridItemAnimated from '../utils/GridItemAnimated'
import Preloader from '../utils/Preloader'
import {
  Grid,
  withWidth,
  Button,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core'
import EntryItem from './EntryItem'
import ResponsiveDialog from '../utils/ResponsiveDialog'
import { deleteEntry } from './actions/cashflowActions'
import EntryDialog from './EntryDialog'
import Accumulated from './Accumulated'
import EntriesForecast from './EntriesForecast'
import EntriesTaxes from './EntriesTaxes'
import EntriesMonth from './EntriesMonth'
import RowEntries from './RowEntries'
import RowMonth from './RowMonth'
import { setDate } from '../Analytics/actions/analyticsActions'

class Entries extends Component {
  state = {
    selectedEntry: null,
    openDeleteDialog: false,
    openEntryDialog: false,
    openRowEntries: true,
    wallets: '',
  }

  toggleRow = (name) => {
    this.setState({
      [`openRow${name}`]: !this.state[`openRow${name}`],
    })
  }

  handleOpenDialog = ({ dialog, open = true, entry = null }) => {
    let state = {
      [`open${dialog}Dialog`]: open,
    }
    if (entry) {
      Object.assign(state, {
        selectedEntry: entry,
      })
    } else {
      Object.assign(state, {
        selectedEntry: null,
        successMessage: null,
        failedMessage: null,
      })
    }
    this.setState({ ...state })
  }

  confirmDelete = ({ deleteNextEntries = false }) => {
    this.props.deleteEntry(this.state.selectedEntry.id, deleteNextEntries)
    if (this.props.cashflow.errors) {
      this.setState({
        failedMessage: this.props.cashflow.errors,
      })
    } else {
      this.setState({
        successMessage: 'Registo excluído.',
        selectedEntry: null,
        openEntryDialog: false,
      })
    }
  }

  render() {
    const { cashflow, width, currentRange } = this.props
    const {
      openDeleteDialog,
      openEntryDialog,
      selectedEntry,
      successMessage,
      failedMessage,
    } = this.state

    if (!cashflow.entries.items) return <Preloader />

    const entries = JSON.parse(
      JSON.stringify(cashflow.entries.items)
    ).filter((entry) =>
      moment(entry.date).isBetween(currentRange[0], currentRange[1], null, '[]')
    )

    const { wallets } = cashflow
    const rangeStart = moment(currentRange[0]),
      rangeEnd = moment(currentRange[1])
    const accumulated = cashflow.entries.accumulated.filter((item) =>
      moment(item.date).isBetween(rangeStart, rangeEnd, null, '[]')
    )

    let months = []
    let loopDate = moment(rangeStart)
    while (loopDate <= rangeEnd) {
      let currentItem = accumulated.find(
        (item) =>
          item.year === loopDate.year() && item.month === loopDate.month() + 1
      )
      currentItem &&
        months.push({
          date: currentItem.date,
          initialBalance: currentItem.initial_balance,
          sales: currentItem.sales,
          expenses: currentItem.expenses,
          expensesPlusTaxes: currentItem.expenses_plus_taxes,
          taxes: currentItem.previous_quarter_taxes,
          entries: entries.filter(
            (entry) =>
              moment(entry.date).year() === loopDate.year() &&
              moment(entry.date).month() === loopDate.month()
          ),
          monthBalance: currentItem.month_balance,
          balance: currentItem.balance,
        })
      loopDate.add(1, 'months')
    }

    let monthsWithIVA = months.map((month) => {
        let item = { ...month }
        item.entries = month.entries.filter(
          (entry) =>
            entry.tax_pct &&
            entry.wallet_source &&
            entry.wallet_source.is_default === true
        )
        return item
      }),
      monthsWithoutIVA = months.map((month) => {
        let item = { ...month }
        item.entries = month.entries.filter(
          (entry) =>
            !entry.tax_pct &&
            entry.wallet_source &&
            entry.wallet_source.is_default === true
        )
        return item
      })

    return (
      <div className="panel">
        <div className="panel__header">
          <h1 className="panel__title">Cashflow</h1>
        </div>
        <div className="panel__body">
          <Grid
            container
            spacing={5}
            direction={`${
              ['xs', 'sm'].includes(width) ? 'column-reverse' : 'row'
            }`}
          >
            <Grid item xs={12} md={8} lg={9}>
              {moment(currentRange[0]).month() ===
              moment(currentRange[1]).month() ? (
                <Grid container spacing={5}>
                  <EntriesForecast
                    entries={entries}
                    handleOpenDialog={this.handleOpenDialog}
                  />
                  <EntriesMonth
                    entries={entries}
                    title="Receitas"
                    handleOpenDialog={this.handleOpenDialog}
                    isIncome
                  />
                  <EntriesMonth
                    entries={entries}
                    title="Salários"
                    handleOpenDialog={this.handleOpenDialog}
                    include={['salarios']}
                  />
                  <EntriesMonth
                    entries={entries.filter((entry) => !entry.tax_pct)}
                    title="Custos S/IVA"
                    handleOpenDialog={this.handleOpenDialog}
                    exclude={['compras', 'leasing', 'salarios']}
                  />
                  <EntriesMonth
                    entries={entries.filter((entry) => entry.tax_pct)}
                    title="Custos C/IVA"
                    handleOpenDialog={this.handleOpenDialog}
                    exclude={['compras', 'leasing', 'salarios']}
                  />
                  <EntriesMonth
                    entries={entries}
                    title="Compras"
                    handleOpenDialog={this.handleOpenDialog}
                    include={['compras']}
                  />
                  <EntriesMonth
                    entries={entries}
                    title="Leasing"
                    handleOpenDialog={this.handleOpenDialog}
                    include={['leasing']}
                  />
                  <EntriesMonth
                    entries={entries}
                    title="Confirming"
                    handleOpenDialog={this.handleOpenDialog}
                    walletSlug="confirming"
                  />
                  <EntriesTaxes entries={entries} />
                </Grid>
              ) : (
                <div className="table-scroller-wrapper">
                  <div className="table-scroller">
                    <Table>
                      <TableHead>
                        <RowMonth
                          title=""
                          months={months}
                          field="date"
                          isDate
                          isHead
                          setDate={this.props.setDate}
                        />
                      </TableHead>
                      <TableBody>
                        <RowMonth
                          title="Balanço Inicial"
                          months={months}
                          field="initialBalance"
                          isNumber
                        />
                        <RowEntries
                          rowTitle="Entradas"
                          handleOpenDialog={this.handleOpenDialog}
                          months={months}
                          isIncome
                        />
                        <RowMonth
                          title="Vendas"
                          months={months}
                          field="sales"
                          isNumber
                        />

                        <RowEntries
                          rowTitle="Salários C/Imp."
                          handleOpenDialog={this.handleOpenDialog}
                          months={monthsWithoutIVA}
                          include={['salarios']}
                          isExpense
                        />
                        <RowEntries
                          rowTitle="Custos S/IVA"
                          handleOpenDialog={this.handleOpenDialog}
                          months={monthsWithoutIVA}
                          exclude={['compras', 'salarios', 'leasing']}
                          isExpense
                        />
                        <RowEntries
                          rowTitle="Custos C/IVA"
                          handleOpenDialog={this.handleOpenDialog}
                          months={monthsWithIVA}
                          exclude={['compras']}
                          isExpense
                        />
                        <RowEntries
                          rowTitle="Compras"
                          handleOpenDialog={this.handleOpenDialog}
                          months={months}
                          include={['compras']}
                          isExpense
                        />
                        <RowEntries
                          rowTitle="Leasing"
                          handleOpenDialog={this.handleOpenDialog}
                          months={months}
                          include={['leasing']}
                          isExpense
                        />
                        <RowMonth
                          title="Impostos"
                          months={months}
                          field="taxes"
                          isNumber
                          isExpense
                        />
                        <RowEntries
                          rowTitle="Confirming"
                          handleOpenDialog={this.handleOpenDialog}
                          months={months}
                          walletSlug="confirming"
                        />
                        <RowMonth
                          title="Saldo do Mês"
                          months={months}
                          field="monthBalance"
                          isNumber
                          bgColor="rgba(0,0,0,0.05)"
                          negativeRed
                        />
                        <RowMonth
                          title="Saldo Final"
                          months={months}
                          field="balance"
                          isNumber
                          negativeRed
                        />
                      </TableBody>
                    </Table>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Grid container spacing={5}>
                <GridItemAnimated index={1}>
                  <Accumulated />
                </GridItemAnimated>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <ResponsiveDialog
          handleCloseDialog={() =>
            this.handleOpenDialog({ dialog: 'Delete', open: false })
          }
          open={openDeleteDialog}
          failedMessage={failedMessage}
          successMessage={successMessage}
          isLoading={cashflow.isLoading}
          actions={
            selectedEntry &&
            selectedEntry.recurrence_id &&
            !selectedEntry.is_installment ? (
              <Fragment>
                <Button
                  className="action-btn"
                  color="primary"
                  onClick={() =>
                    this.confirmDelete({ deleteNextEntries: true })
                  }
                >
                  Este e os próximos
                </Button>
                <Button
                  className="action-btn"
                  color="primary"
                  onClick={this.confirmDelete}
                >
                  Apenas este
                </Button>
              </Fragment>
            ) : (
              <Button
                className="action-btn"
                color="primary"
                onClick={this.confirmDelete}
              >
                Sim
              </Button>
            )
          }
        >
          {selectedEntry && (
            <Fragment>
              <p>Você tem certeza que deseja excluir o lançamento?</p>
              <EntryItem
                entry={selectedEntry}
                extraClass="entry--dialog"
                hideActionButtons
              />
            </Fragment>
          )}
        </ResponsiveDialog>

        <EntryDialog
          open={openEntryDialog}
          entry={selectedEntry}
          wallets={wallets}
          handleCloseDialog={() =>
            this.handleOpenDialog({
              dialog: 'Entry',
              open: false,
            })
          }
          handleDelete={() =>
            this.handleOpenDialog({
              dialog: 'Delete',
              entry: selectedEntry,
            })
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cashflow: state.cashflow,
    journal: state.analytics.journal.current,
    currentRange: state.analytics.currentRange,
  }
}
const mapsDispatchToProps = (dispatch) => {
  return {
    deleteEntry: (id, deleteNextEntries) =>
      dispatch(deleteEntry(id, deleteNextEntries)),
    setDate: (range) => dispatch(setDate(range)),
  }
}
export default connect(
  mapStateToProps,
  mapsDispatchToProps
)(withWidth()(Entries))
