import axios from 'axios';
import { handleErrors } from '../../utils/helpers/errors';

export function checkServer(callback = null) {
  return (dispatch, getState) => {
    axios
      .get('/ping/')
      .then((res) => {
        axios
          .get('/sage/server-status/')
          .then((res) => {
            let errors = [];

            Object.entries(res.data).forEach((service) => {
              const key = service[0];
              const { connected, error } = service[1];
              if (!connected) errors.push({ service: key, error: error });
            });

            if (errors.length > 0) {
              dispatch({ type: 'SAGE_SERVER_ERROR', payload: errors });
            } else {
              dispatch({ type: 'SERVER_OK' });
              if (typeof callback === 'function') callback();
            }
          })
          .catch((error) => handleErrors(error, dispatch));
      })
      .catch((error) => handleErrors(error, dispatch));
  };
}

export const setInternetConnection = (status) => {
  return (dispatch, getState) => {
    dispatch({ type: status });
  };
};
