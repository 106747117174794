import React, { Fragment } from 'react'
import moment from 'moment'
import { TableRow, TableCell } from '@material-ui/core'
import { currencyFormatDE } from '../utils/currency'

const RowMonth = ({
  title,
  months,
  field,
  isDate,
  isNumber,
  isHead,
  isExpense,
  setDate,
  bgColor,
  negativeRed = false
}) => {
  return (
    <TableRow
      className="table__row"
      hover={true}
      style={{ backgroundColor: bgColor ? bgColor : '#fff' }}
    >
      <TableCell className={`table__cell ${!title ? 'transparent' : ''}`}>
        {title}
      </TableCell>
      {months.length > 0 &&
        months.map((month, index) => (
          <TableCell
            key={index}
            className={`table__cell ${isDate ? 'clickable' : ''}`}
            style={{ minWidth: 125 }}
            onClick={() => {
              isDate &&
                setDate([
                  moment(month.date)
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                  moment(month.date)
                    .endOf('month')
                    .format('YYYY-MM-DD')
                ])
            }}
          >
            <p
              className={`${
                isHead ? '--ta-center' : isNumber ? 'entry__amount' : ''
              } ${negativeRed && month[field] < 0 ? '--t-danger' : ''}`}
            >
              {month[field] && (
                <Fragment>
                  {isNumber && currencyFormatDE(month[field], false, 0, true)}
                  {isDate && moment(month.date).format('MMM YYYY')}
                </Fragment>
              )}
            </p>
          </TableCell>
        ))}
    </TableRow>
  )
}

export default RowMonth
