import React, { Component } from 'react'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Grid } from '@material-ui/core'

class TabSwiper extends Component {
  state = {
    index: 0,
  }
  handleChange = (event, value) => {
    this.setState({
      index: value,
    })
  }

  handleChangeIndex = (index) => {
    this.setState({
      index,
    })
  }
  render() {
    const { index } = this.state,
      { tabsLabels, fullWidth, children } = this.props,
      tabsButtons =
        tabsLabels &&
        tabsLabels.map((tab, idx) => (
          <Tab
            key={idx}
            className={`tabs__button ${
              idx === index ? 'tabs__button--selected' : ''
            }`}
            label={tab}
          />
        ))
    return (
      <Grid container direction="column" spacing={3} justifyContent="center">
        <Grid item className="--ta-center">
          <Tabs
            indicatorColor="primary"
            TabIndicatorProps={{
              className: 'tabs__indicator',
            }}
            textColor="primary"
            value={index}
            variant={fullWidth ? 'fullWidth' : 'standard'}
            centered
            onChange={this.handleChange}
          >
            {tabsButtons}
          </Tabs>
        </Grid>
        <Grid item>
          <SwipeableViews
            axis={'x'}
            index={index}
            onChangeIndex={this.handleChangeIndex}
            style={{ width: '100%' }}
          >
            {children}
          </SwipeableViews>
        </Grid>
      </Grid>
    )
  }
}

export default TabSwiper
