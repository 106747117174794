import moment from 'moment'
import 'moment/locale/pt'

const setDate = (range = null, unit = 'months') => {
  if (!range)
    range = [
      moment().startOf('month').format('YYYY-MM-DD'),
      moment().endOf('month').format('YYYY-MM-DD'),
    ]

  return {
    currentRange: [...range],
    prevRange: [
      moment(range[0]).subtract(1, unit).format('YYYY-MM-DD'),
      moment(range[1]).subtract(1, unit).format('YYYY-MM-DD'),
    ],
    nextRange: [
      moment(range[0]).add(1, unit).format('YYYY-MM-DD'),
      moment(range[1]).add(1, unit).format('YYYY-MM-DD'),
    ],
  }
}

const initialState = {
  isLoading: false,
  isLoadingAccumulated: false,
  visits: null,
  journal: { current: [], compare: [] },
  accumulated: [],
  loadCompare: false,
  year_visits: null,
  year_sales: null,
  errors: null,
  ...setDate(),
}

export default function analyticsReducer(
  state = JSON.parse(JSON.stringify(initialState)),
  action
) {
  let started = ['_LOADING', '_START', '__START'].find((item) =>
      action.type.includes(item)
    ),
    finished = ['_FINISHED'].find((item) => action.type.includes(item))

  switch (action.type) {
    case 'SET_RANGE':
      Object.assign(state, { ...setDate(action.payload) })
      break
    case 'LOAD_COMPARE':
      state['loadCompare'] = action.payload
      break
    case 'CLEAR_COMPARE':
      state['journal']['compare'] = []
      break
    case 'ACCUMULATED_LOADED':
      state['accumulated'] = action.payload
      state['isLoadingAccumulated'] = false
      break
    case 'JOURNAL_LOADED':
      state['journal']['current'] = [...action.payload]
      break

    case 'JOURNAL_COMPARE_LOADED':
      state['journal']['compare'] = [...action.payload]
      break

    default:
      break
  }

  if (started)
    Object.assign(state, {
      isLoading: true,
      isLoadingAccumulated: true,
      errors: null,
    })
  if (finished) Object.assign(state, { isLoading: false })
  return { ...state }
}
