import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Collapse, TableRow, TableCell } from '@material-ui/core'
import EntryRowItem from './EntryRowItem'
import { currencyFormatDE } from '../utils/currency'

class RowEntries extends Component {
  state = {
    expanded: false
  }
  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  filterEntries = entries => {
    return entries.filter(entry => {
      let walletSlug = this.props.walletSlug,
        walletSource = entry.wallet_source ? entry.wallet_source.slug : null,
        walletDestination = entry.wallet_destination
          ? entry.wallet_destination.slug
          : null

      if (walletSlug && !walletSource && !walletDestination) {
        return null
      } else if (walletSlug) {
        let wallet = this.props.wallets.find(
          wallet => walletSlug && wallet.slug === walletSlug
        )
        if (walletSlug && !wallet) return null
        if (walletSlug && (entry.is_tax || entry.is_forecast)) return null
        if (wallet.slug !== walletSource) return null
      }

      const activityType = this.props.isIncome
        ? 2
        : this.props.isExpense
        ? 1
        : null
      if (
        entry.activity_type &&
        (activityType ? entry.activity_type === activityType : true) &&
        !entry.is_tax &&
        !entry.is_forecast
      ) {
        if (this.props.include && !this.props.exclude) {
          if (
            this.props.include &&
            entry.category &&
            this.props.include.includes(entry.category.slug)
          )
            return entry
          return null
        }
        if (!this.props.include && this.props.exclude) {
          if (
            this.props.exclude &&
            entry.category &&
            this.props.exclude.includes(entry.category.slug)
          )
            return null
          return entry
        }

        return entry
      }

      return null
    })
  }

  getEntries = entries => {
    entries = this.filterEntries(entries)
    return (
      <div className="list">
        {entries.map(
          (entry, index) =>
            !entry.has_installments && (
              <EntryRowItem
                entry={entry}
                handleOpenDelete={() =>
                  this.props.handleOpenDialog({
                    dialog: 'Delete',
                    entry: entry
                  })
                }
                handleEdit={() =>
                  this.props.handleOpenDialog({
                    dialog: 'Entry',
                    entry: entry.is_installment
                      ? entry.is_installment_of
                      : entry
                  })
                }
                extraClass="entry--list-item"
                key={index}
                hideActionButtons
              />
            )
        )}
      </div>
    )
  }

  render() {
    const { months, rowTitle } = this.props
    let { expanded } = this.state
    let totalRow = 0

    return (
      <Fragment>
        <TableRow
          className="table__row clickable"
          hover={true}
          onClick={this.handleExpandClick}
        >
          <TableCell className="table__cell">{rowTitle}</TableCell>
          {months.length > 0 &&
            months.map((month, index) => {
              let total = this.filterEntries(month.entries).reduce(
                (total, entry) => entry.amount + total,
                0
              )
              totalRow += total
              let total_month = this.filterEntries(month.entries).reduce(
                (total, entry) => entry.amount + total,
                0
              )
              return (
                <TableCell key={index} className="table__cell">
                  <p className="entry__amount">
                    {currencyFormatDE(total_month, false, 0, true)}
                  </p>
                </TableCell>
              )
            })}
          <TableCell className="table__cell">
            <p>
              <b>{currencyFormatDE(totalRow, false, 0, true)}</b>
            </p>
          </TableCell>
        </TableRow>
        <TableRow className={`table__row sub ${expanded ? '' : 'collapsed'}`}>
          <td className="table__cell" />
          {months.length > 0 &&
            months.map((month, index) => (
              <td
                className="table__cell"
                key={index}
                style={{ verticalAlign: 'top' }}
              >
                <Collapse in={expanded}>
                  {this.getEntries(month.entries)}
                </Collapse>
              </td>
            ))}
        </TableRow>
      </Fragment>
    )
  }
}
const mapStateToProps = state => {
  return {
    wallets: state.cashflow.wallets
  }
}
export default connect(mapStateToProps)(RowEntries)
