import React from 'react'
import { Grid } from '@material-ui/core'

const GridItemAnimated = ({
  index = 0,
  animate = true,
  animation = 'fadeInUp',
  speed = 'faster',
  children,
  ...restProps
}) => {
  return (
    <Grid
      item
      className={animate ? `animated ${animation} ${speed}` : ''}
      style={{ animationDelay: `${index * 0.1}s` }}
      {...restProps}
    >
      {children}
    </Grid>
  )
}

export default GridItemAnimated
