import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { logout } from '../../components/Auth/actions/authActions'

class LogoutPage extends Component {
  componentDidMount() {
    this.props.logout()
  }

  render() {
    return <Redirect to="/login" />
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(
  null,
  mapDispatchToProps
)(LogoutPage)
