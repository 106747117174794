import { store } from '../../../index';

/*
Used in all API calls to prevent CSRF blocks
Access the store state to get auth token and return headers['Authorization'] 
*/
export function setHeadersToken() {
  let state = store.getState(),
    token = state.auth.token;
  if (token) {
    return { Authorization: `Token ${token}` };
  }
  return null;
}
