import React from 'react'
import 'moment/locale/pt'
import CustomComposedChart from './CustomComposedChart'
import { Line, Bar } from 'recharts'

const SalesChart = ({ data }) => {
  return (
    <CustomComposedChart data={data} syncId="sales" height={250}>
      {data.find(item => item.compare) && (
        <Bar name="Clientes (anterior)" dataKey="compare.entries" fill="#999" />
      )}
      <Bar name="Clientes" dataKey="current.entries" fill="#BB6BD9" />
      <Line
        connectNulls
        type="monotone"
        name="Eficiência"
        dataKey="current.efficiency"
        stroke="#57C754"
        unit="€€"
        dot={false}
      />
    </CustomComposedChart>
  )
}

export default SalesChart
