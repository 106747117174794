import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'

import { Menu, MenuItem, IconButton, Hidden, Divider } from '@material-ui/core'
import {
  Menu as MenuIcon,
  Person as UserIcon,
  Settings,
} from '@material-ui/icons'
import SetGoalsDialog from '../Config/SetGoalsDialog'
import { isAdmin } from '../utils/functions'

const HeaderNav = (props) => {
  const MENU_INI = { menu: '', anchorEl: null }
  const [openMenu, setOpenMenu] = useState({ ...MENU_INI })
  const [isGoalsDialogOpen, setIsGoalsDialogOpen] = useState(false)

  const handleOpenMenu = (e, menu) => {
    setOpenMenu({
      ...openMenu,
      menu: menu,
      anchorEl: e.currentTarget,
    })
  }

  const handleCloseMenu = () => {
    setOpenMenu({ ...MENU_INI })
  }

  const { user } = props.auth

  return (
    <>
      <IconButton
        color="inherit"
        onClick={(e) => handleOpenMenu(e, 'user')}
        style={{ padding: 7 }}
      >
        <UserIcon />
      </IconButton>
      <IconButton
        color="inherit"
        onClick={(e) => handleOpenMenu(e, 'config')}
        style={{ padding: 7 }}
      >
        <Settings />
      </IconButton>

      <Menu
        anchorEl={openMenu.menu === 'user' ? openMenu.anchorEl : null}
        open={openMenu.menu === 'user'}
        onClose={handleCloseMenu}
      >
        <div style={{ padding: '0 15px 15px' }}>
          <small className="caption">
            {user.employee ? 'Colaborador' : 'Usuário'}
          </small>
          <p className="small">
            {user.employee
              ? user.employee.first_name
              : user.name
              ? user.name
              : user.email}
          </p>
        </div>
        <MenuItem>
          <NavLink to="/logout">Logout</NavLink>
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={openMenu.menu === 'config' ? openMenu.anchorEl : null}
        open={openMenu.menu === 'config'}
        onClose={handleCloseMenu}
      >
        <MenuItem
          disabled={!isAdmin()}
          onClick={() => setIsGoalsDialogOpen(true)}
        >
          Definir Objectivos
        </MenuItem>
        <MenuItem disabled={true}>Upload de Visitas</MenuItem>
        <Divider />
        <MenuItem onClick={() => window.location.reload(true)}>
          Atualizar Sistema
        </MenuItem>
      </Menu>
      <Hidden lgUp>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.handleToggleSidebar}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>

      <SetGoalsDialog
        open={isGoalsDialogOpen}
        handleClose={() => setIsGoalsDialogOpen(false)}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(HeaderNav)
