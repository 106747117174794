import { Grid, IconButton, Typography } from '@material-ui/core';
import { Attachment } from '@material-ui/icons';
import moment from 'moment';
import { useContext, useState } from 'react';

import { Journal } from '../../../@core/domain/entities/Journal';
import { currencyFormatDE } from '../../utils/currency';

import { ToggleExpandIcon } from './ExpandIcon';
import { MonthSalesListContext } from './MonthSalesList';
import { SageSales } from './SageSales';
import { SaleByShift } from './SaleByShift';

type JournalEntryProps = {
  journal: Journal;
};

export function JournalEntry({ journal }: JournalEntryProps) {
  const [isSageSalesExpanded, setIsSageSalesExpanded] = useState(false);

  const { handleOpenLightBox, handleOpenUploadPhoto } = useContext(MonthSalesListContext);

  const { id, date, sales, company, deposit_photo, sage_sales, sum_sales } = journal;
  const hasSageSales = sage_sales.length > 0;

  return (
    <Grid item xs={12} className="journal__data-item">
      <h2 className="journal__date">{moment(date, 'YYYY-MM-DD').format('DD MMM')}</h2>
      <Grid container spacing={3}>
        {Object.values(sales).map((sale, index) => (
          <SaleByShift key={index} sale={sale} handleOpenLightBox={handleOpenLightBox} />
        ))}
        <Grid item xs={12} className="--ta-right">
          <div className="grid">
            <div className="grid__container --space-between">
              {id && company !== 3 && (
                <div className="grid__item">
                  <div className="grid__container --align-center">
                    <small className="material-icons text-mutted mr-16">account_balance</small>
                    {deposit_photo ? (
                      <IconButton className="sale__icon" onClick={() => handleOpenLightBox(deposit_photo)}>
                        <Attachment />
                      </IconButton>
                    ) : (
                      <button className="btn btn--primary outlined rounded" onClick={() => handleOpenUploadPhoto(id)}>
                        Anexar foto do depósito
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div className="grid__item ml-auto">
                <Grid container alignItems="center">
                  <Grid item>
                    <Typography>
                      <b>{currencyFormatDE(sum_sales, true, 0, true)}</b>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ToggleExpandIcon
                      isExpanded={isSageSalesExpanded}
                      setIsExpanded={setIsSageSalesExpanded}
                      isDisabled={!hasSageSales}
                    />
                  </Grid>
                </Grid>
              </div>
              <SageSales saleList={sage_sales} isExpanded={isSageSalesExpanded} />
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
