import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { BASE_API_URL } from './urls'
import ResponsiveDialog from '../utils/ResponsiveDialog'
import {
  TextField,
  Grid,
  Button,
  IconButton,
  FormControl,
  Input,
  InputAdornment,
} from '@material-ui/core'
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons'
import Preloader from '../utils/Preloader'
import { setHeadersToken } from '../utils/helpers/store'
import Compare from '../Analytics/Compare'
import { currencyFormatDE, format_number } from '../utils/currency'
import { loadPendingSurveys } from './actions/teamActions'

class SurveyDialog extends Component {
  state = {
    isLoading: false,
    open: true,
    survey: null,
    answers: [],
    failedMessage: null,
    successMessage: null,
  }
  componentDidMount() {
    let answers = []
    this.props.survey.survey_template.questions.forEach((question) => {
      let text_lines = []
      if (question.answer_in_lines && question.answer_in_lines > 0) {
        for (let i = 0; i < question.answer_in_lines; i++) {
          text_lines.push('')
        }
      }
      answers.push({
        question_id: question.id,
        survey_id: this.props.survey.id,
        question: question.text,
        text_lines: [...text_lines],
        min_chars: question.min_chars,
        text: '',
      })
    })
    this.setState({ answers: [...answers] })
  }
  handleClose = () => {
    this.props.loadPendingSurveys()
    this.setState({ open: false })
  }

  handleChangeAnswer =
    (question_id, index = -1) =>
    (event) => {
      let answers = this.state.answers.map((answer) => {
        if (answer.question_id === question_id) {
          if (index >= 0) {
            answer.text_lines[index] = event.target.value
          } else {
            answer.text = event.target.value
          }
        }
        return answer
      })
      return this.setState({ answers: answers })
    }

  addTextLine = (question_id) => {
    let answers = this.state.answers.map((answer) => {
      if (answer.question_id === question_id) {
        answer.text_lines.push('')
      }
      return answer
    })
    return this.setState({ answers: answers })
  }
  removeTextLine = (question_id, index) => {
    let answers = this.state.answers.map((answer) => {
      if (answer.question_id === question_id && answer.text_lines.length > 1)
        answer.text_lines.splice(index, 1)
      return answer
    })
    return this.setState({ answers: answers })
  }

  handleSubmit = (e) => {
    let answers = [...this.state.answers]
    const data = {
      answers: answers.map((answer) => {
        if (answer.text_lines?.filter((line) => line !== '').length > 0)
          answer['text'] = answer.text_lines
            .filter((line) => line !== '')
            .join('\n')
        delete answer['text_lines']
        return answer
      }),
    }
    this.setState(
      {
        isLoading: true,
        failedMessage: null,
        successMessage: null,
        errors: null,
      },
      () => {
        axios
          .post(
            `${BASE_API_URL}`,
            { ...data },
            {
              headers: setHeadersToken(),
            }
          )
          .then((res) => {
            this.setState({ successMessage: res.data.msg })
          })
          .catch((error) => {
            console.log(error.response.data)
            if (error.response.data) {
              let errors = {}
              Object.keys(error.response.data).forEach(
                (field) => (errors[field] = error.response.data[field])
              )
              this.setState({ ...this.state, errors: errors, isLoading: false })
            }
            if (error.response.data.msg) {
              this.setState({
                ...this.state,
                failedMessage: error.response.data.msg,
              })
            }
          })
      }
    )
  }

  disableForm = () => {
    return (
      this.state.answers.filter(
        (answer) => answer.text.length < answer.min_chars
      ).length > 0
    )
  }

  render() {
    const { open, answers, isLoading, failedMessage, successMessage } =
      this.state
    let customAttributes = this.props.survey.custom_attributes

    return (
      <ResponsiveDialog
        open={open}
        scroll="body"
        handleCloseDialog={this.handleClose}
        dialogTitle={this.props.survey.survey_template.name}
        failedMessage={failedMessage}
        successMessage={successMessage}
        actions={
          isLoading ? (
            <Preloader />
          ) : (
            <Button
              onClick={this.handleSubmit}
              className="action-btn"
              color="primary"
              variant="contained"
              disabled={this.disableForm()}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              Concluir
            </Button>
          )
        }
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div style={{ marginBottom: 32 }}>
              <Grid container spacing={5} justify={'center'}>
                <Grid item xs={12} className="--ta-center">
                  <h1 className="card__title --nomargin">Resultados</h1>
                  <p className="small --t-bold text-mutted">
                    {customAttributes.period_formatted}
                  </p>
                </Grid>
                <Grid item className="--ta-center">
                  <Compare
                    value={format_number(customAttributes.total_sales, 2, true)}
                    justifyContent="center"
                    valueComponent={
                      <p
                        className="--nomargin bigger --t-primary"
                        title={currencyFormatDE(
                          customAttributes.total_sales,
                          false
                        )}
                      >
                        <b>
                          {currencyFormatDE(
                            customAttributes.total_sales,
                            true,
                            0,
                            true
                          )}
                        </b>
                      </p>
                    }
                    valueString={currencyFormatDE(
                      customAttributes.total_sales,
                      true,
                      0,
                      true
                    )}
                    compare={format_number(
                      customAttributes.prev_total_sales,
                      2,
                      true
                    )}
                  />

                  <p className="small --t-bold text-mutted">vendas</p>
                </Grid>
                <Grid item className="--ta-center">
                  <p
                    className="--nomargin bigger"
                    title={currencyFormatDE(customAttributes.total_goal, false)}
                  >
                    <b>
                      {currencyFormatDE(
                        customAttributes.total_goal,
                        true,
                        0,
                        true
                      )}
                    </b>
                  </p>
                  <p className="small --t-bold text-mutted">objectivo</p>
                </Grid>
              </Grid>
            </div>
          </Grid>

          {answers.map((answer, index) => (
            <Grid item xs={12} key={index}>
              <div dangerouslySetInnerHTML={{ __html: answer.question }} />
              {answer.text_lines && answer.text_lines.length > 0 ? (
                <Grid container>
                  {answer.text_lines.map((line, idx) => (
                    <Grid item key={idx} xs={12}>
                      <FormControl fullWidth margin="normal">
                        <Input
                          value={line}
                          onChange={this.handleChangeAnswer(
                            answer.question_id,
                            idx
                          )}
                          endAdornment={
                            answer.text_lines.length > 1 && (
                              <InputAdornment position="end">
                                <IconButton
                                  className="action-btn"
                                  onClick={() =>
                                    this.removeTextLine(answer.question_id, idx)
                                  }
                                >
                                  <DeleteIcon
                                    fontSize="small"
                                    className="text-color danger"
                                  />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                  ))}
                  <Grid itemxs={12}>
                    <IconButton
                      onClick={() => this.addTextLine(answer.question_id)}
                    >
                      <AddIcon className="--t-primary" />
                    </IconButton>
                  </Grid>{' '}
                </Grid>
              ) : (
                // <TextArea
                //   value={answer.text}
                //   onChange={this.handleChangeAnswer(answer.question_id)}
                //   showCount={true}
                // />
                <TextField
                  multiline
                  fullWidth
                  value={answer.text}
                  rowsMax="10"
                  rows="1"
                  onChange={this.handleChangeAnswer(answer.question_id)}
                  margin="normal"
                  helperText={
                    answer.min_chars
                      ? `Min ${answer.min_chars} chars. Current: ${answer.text.length}`
                      : ''
                  }
                />
              )}
            </Grid>
          ))}
        </Grid>
      </ResponsiveDialog>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    loadPendingSurveys: () => dispatch(loadPendingSurveys()),
  }
}
export default connect(null, mapDispatchToProps)(SurveyDialog)
