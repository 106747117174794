import React from 'react'
import 'moment/locale/pt'
import CustomComposedChart from './CustomComposedChart'
import { Line, Area, Bar } from 'recharts'
import { format_number } from '../utils/currency'

export function SalesChart({ data }){
  let dataCleaned = []
  data.forEach((item) => {
    let idx = dataCleaned.findIndex((it) => it.xAxis === item.xAxis)

    if (idx < 0) {
      idx = dataCleaned.push({ xAxis: item.xAxis })
      idx--
    }

    Object.entries(item).forEach((entry) => {
      let key = entry[0],
        data = entry[1]

      if (['current', 'compare'].includes(key)) {
        const parsedData = {
          sum_sales:
            data instanceof Array
              ? data.reduce((total, it) => total + it.sum_sales, 0)
              : data['sum_sales'],
          sum_sales_cost:
            data instanceof Array
              ? data.reduce((total, it) => total + it.sum_sales_cost, 0)
              : data['sum_sales_cost'],
          sum_sales_net:
            data instanceof Array
              ? data.reduce((total, it) => total + it.sum_sales_net, 0)
              : data['sum_sales_net'],
          profit_pct: 0,
          goal:
            data instanceof Array
              ? data.reduce((total, it) => total + it.goal, 0)
              : data['goal'],
          goal_secondary:
            data instanceof Array
              ? data.reduce((total, it) => total + it.goal_secondary, 0)
              : data['goal_secondary'],
          profit_goal:
            data instanceof Array
              ? data.reduce((total, it) => total + it.profit_goal, 0) /
                data.length
              : data['profit_goal'],
          profit_goal_secondary:
            data instanceof Array
              ? data.reduce(
                  (total, it) => total + it.profit_goal_secondary,
                  0
                ) / data.length
              : data['profit_goal_secondary'],
        }

        let zero_test = [
          parsedData['sum_sales_net'] - parsedData['sum_sales_cost'] !== 0,
          parsedData['sum_sales_net'] !== 0,
        ]
        parsedData['profit_pct'] = zero_test.every(Boolean)
          ? format_number(
              ((parsedData['sum_sales_net'] - parsedData['sum_sales_cost']) /
                parsedData['sum_sales_net']) *
                100,
              2,
              true
            )
          : 0
        Object.assign(dataCleaned[idx], {
          [key]: { ...parsedData },
        })
      }
    })
  })

  return (
    <CustomComposedChart data={dataCleaned} syncId="sales">
      <defs>
        <linearGradient id="colorGoal" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#ea5b3a" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#ea5b3a" stopOpacity={0} />
        </linearGradient>
      </defs>
      {data.find((item) => item.compare) && (
        <Bar
          name="Vendas (anterior)"
          dataKey="compare.sum_sales"
          fill="#999"
          stackId="cost_prev"
          unit="€"
        />
      )}
      {data.find((item) => item.compare) && (
        <Bar
          name="ML (anterior)"
          dataKey="compare.profit_pct"
          fill="#999"
          stackId="cost_prev"
          unit="%"
        />
      )}
      <Bar
        name="Vendas"
        dataKey="current.sum_sales"
        fill="#56CCF2"
        stackId="profit_and_cost"
        unit="€"
      />
      <Bar
        name="ML"
        dataKey="current.profit_pct"
        fill="#6ae490"
        stackId="profit_and_cost"
        unit="%"
      />
      <Area
        name="Objectivo"
        type="monotone"
        connectNulls
        dataKey="current.goal"
        unit="€"
        stroke="#ea5b3a"
        fillOpacity={0.3}
        fill="url(#colorGoal)"
      />
      <Line
        name="hide"
        connectNulls
        type="monotone"
        dataKey="current.sum_sales"
        stroke="#56CCF2"
        dot={false}
      />
    </CustomComposedChart>
  )
}