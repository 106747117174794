import React, { Component } from 'react'
import { connect } from 'react-redux'
import Preloader from '../utils/Preloader'
import EntryItem from './EntryItem'
import CashflowCollapsible from './CashflowCollapsible'
import GridItemAnimated from '../utils/GridItemAnimated'

class EntriesMonth extends Component {
  filterEntries = entries => {
    return entries.filter(entry => {
      let walletSlug = this.props.walletSlug,
        walletSource = entry.wallet_source ? entry.wallet_source.slug : null,
        walletDestination = entry.wallet_destination
          ? entry.wallet_destination.slug
          : null
      if (walletSlug && !walletSource && !walletDestination) {
        return null
      } else if (walletSlug) {
        let wallet = this.props.wallets.find(
          wallet => walletSlug && wallet.slug === walletSlug
        )
        if (walletSlug && !wallet) return null
        if (walletSlug && (entry.is_tax || entry.is_forecast)) return null
        if (wallet.slug !== walletSource) return null
      }
      const activityType = this.props.isIncome
        ? 2
        : this.props.isExpense
        ? 1
        : null
      if (
        entry.activity_type &&
        (activityType ? entry.activity_type === activityType : true) &&
        !entry.is_tax &&
        !entry.is_forecast
      ) {
        if (this.props.include && !this.props.exclude) {
          if (
            this.props.include &&
            entry.category &&
            this.props.include.includes(entry.category.slug)
          )
            return entry
          return null
        }
        if (!this.props.include && this.props.exclude) {
          if (
            this.props.exclude &&
            entry.category &&
            this.props.exclude.includes(entry.category.slug)
          )
            return null
          return entry
        }

        return entry
      }

      return null
    })
  }
  render() {
    const { entries, handleOpenDialog, animateIndex, title } = this.props
    if (!entries) return <Preloader />

    const entries_filtered = this.filterEntries(entries)
    let total = entries_filtered.reduce(
      (total, entry) => entry.amount + total,
      0
    )

    return (
      <GridItemAnimated index={animateIndex} xs={12} animation="fadeInDown">
        <CashflowCollapsible title={title} total={total} collapsed>
          <div className="list">
            {entries_filtered.map(
              (entry, index) =>
                !entry.has_installments && (
                  <EntryItem
                    entry={entry}
                    handleOpenDelete={() =>
                      handleOpenDialog({
                        dialog: 'Delete',
                        entry: entry
                      })
                    }
                    handleEdit={() =>
                      handleOpenDialog({
                        dialog: 'Entry',
                        entry: entry.is_installment
                          ? entry.is_installment_of
                          : entry
                      })
                    }
                    extraClass="entry--list-item"
                    key={index}
                  />
                )
            )}
          </div>
        </CashflowCollapsible>
      </GridItemAnimated>
    )
  }
}
const mapStateToProps = state => {
  return {
    wallets: state.cashflow.wallets
  }
}
export default connect(mapStateToProps)(EntriesMonth)
