import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
  withWidth
} from '@material-ui/core'

import { isPermitted } from '../utils/functions'

class Sidebar extends Component {
  render() {
    const { routes, width, sidebarOpen, handleToggleSidebar } = this.props
    const getBreakpoint = () => {
      switch (width) {
        case 'xs':
        case 'sm':
        case 'md':
          return {
            variant: 'temporary',
            anchor: 'right',
            open: sidebarOpen,
            onClose: handleToggleSidebar
          }
        case 'lg':
        default:
          return {
            anchor: 'left',
            variant: 'persistent',
            open: true
          }
      }
    }
    const drawerProps = getBreakpoint()

    var links = (
      <List className="sidebar__links" style={{ padding: 0 }}>
        {routes.map((prop, index) => {
          if (prop.redirect) return null
          if (prop.permissions && !isPermitted(prop.permissions)) return null

          return (
            <NavLink
              to={prop.path}
              className="sidebar__link animated fadeInUp faster"
              activeClassName="active"
              key={index}
              onClick={handleToggleSidebar}
              style={{ animationDelay: `.${index + 1}s` }}
            >
              <ListItem button>
                <ListItemIcon className="list-item__icon">
                  {typeof prop.icon === 'string' ? (
                    <Icon>{prop.icon}</Icon>
                  ) : (
                    <prop.icon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={prop.navbarName}
                  className="list-item__text"
                />
              </ListItem>
            </NavLink>
          )
        })}
      </List>
    )
    return (
      <Drawer {...drawerProps} className="drawer">
        <div className="sidebar-wrapper">
          <div className="sidebar animated fadeIn faster">
            <div
              className="sidebar__logo animated fadeInUp faster"
              style={{ animationDelay: '.1s' }}
            >
              <img src="static/assets/img/logo-white.png" alt="LOGO" />
            </div>
            {links}
          </div>
        </div>
      </Drawer>
    )
  }
}

export default withWidth()(Sidebar)
