import { IconButton } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

type ToggleExpandIconProps = {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  isDisabled?: boolean;
};
export function ToggleExpandIcon({ isExpanded, setIsExpanded, isDisabled = false }: ToggleExpandIconProps) {
  return (
    <IconButton
      onClick={() => setIsExpanded(!isExpanded)}
      aria-expanded={isExpanded}
      aria-label="Expandir vendas do Sage"
      size="small"
      disabled={isDisabled}
    >
      <ExpandMore className={`expand-icon ${isExpanded ? 'expanded' : ''} `} fontSize="small" />
    </IconButton>
  );
}
