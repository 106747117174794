import React from 'react'
import Preloader from '../utils/Preloader'
import EntryItem from './EntryItem'
import CashflowCollapsible from './CashflowCollapsible'
import GridItemAnimated from '../utils/GridItemAnimated'

const EntriesForecast = ({
  entries,
  sales,
  handleOpenDialog,
  animateIndex
}) => {
  if (!entries) return <Preloader />

  const forecast = entries.filter(
    entry => entry.is_forecast && entry.activity_type === 2
  )
  const total = forecast.reduce((total, entry) => entry.amount + total, 0)

  if (total <= 0) return <div />
  return (
    <GridItemAnimated index={animateIndex} xs={12} animation="fadeInDown">
      <CashflowCollapsible title="Previsão de Vendas" total={total} collapsed>
        {total > 0 && (
          <div className="list">
            {forecast.map(
              (entry, index) =>
                !entry.has_installments && (
                  <EntryItem
                    entry={entry}
                    handleOpenDelete={() =>
                      handleOpenDialog({
                        dialog: 'Delete',
                        entry: entry
                      })
                    }
                    handleEdit={() =>
                      handleOpenDialog({
                        dialog: 'Entry',
                        entry: entry.is_installment
                          ? entry.is_installment_of
                          : entry
                      })
                    }
                    extraClass="entry--list-item"
                    key={index}
                  />
                )
            )}
          </div>
        )}
      </CashflowCollapsible>
    </GridItemAnimated>
  )
}

export default EntriesForecast
