import React, { Component } from 'react'
import { Grid, Collapse } from '@material-ui/core'
import { currencyFormatDE } from '../utils/currency'

class CashflowCollapsible extends Component {
  state = {
    expanded: true,
  }
  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }
  render() {
    const { title, collapsed, children, icon, total } = this.props
    let { expanded } = this.state

    if (collapsed === true) expanded = !expanded

    return (
      <div className="wrapper wrapper--hidden">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ borderBottom: '1px solid rgba(0,0,0,0.15)' }}
            className="clickable"
            onClick={this.handleExpandClick}
          >
            <Grid container alignItems="center" spacing={1}>
              {icon && (
                <Grid item className="panel__icon">
                  {icon}
                </Grid>
              )}
              <Grid item>{title}</Grid>

              <Grid item style={{ marginLeft: 'auto' }}>
                <b>{currencyFormatDE(total, false, 0, true)}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse
              className="card__body-wrapper"
              in={expanded}
              timeout="auto"
              unmountOnExit
            >
              {children}
            </Collapse>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default CashflowCollapsible
