import React from 'react'
import { AppBar, Toolbar, withWidth } from '@material-ui/core'

import HeaderNav from './HeaderNav'

const Header = ({ ...props }) => {
  return (
    <AppBar
      position={`${
        ['xs', 'sm', 'md'].includes(props.width) ? 'fixed' : 'absolute'
      }`}
      className="topnav animated slideInDown faster"
    >
      <Toolbar
        variant="dense"
        style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
        className="topbar"
      >
        <HeaderNav {...props} />
      </Toolbar>
    </AppBar>
  )
}
export default withWidth()(Header)
