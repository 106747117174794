import React from 'react'
import {
  InputAdornment,
  FormControl,
  InputLabel,
  Input
} from '@material-ui/core'
import InputMask from 'react-input-mask'

const CustomInput = ({
  // Input props
  name,
  type,
  required,
  autoFocus,
  placeholder,
  value,
  handleChange,
  // FormControl props
  className,
  fullWidth,
  errors,
  helperText,
  label,
  icon,
  // Mask
  mask
}) => {
  const inputProps = {
    type: type ? type : 'text',
    name: name,
    value: value,
    onChange: handleChange(name),
    placeholder: placeholder,
    required: required ? true : false,
    autoFocus: autoFocus ? true : false
  }
  const formControlProps = {
    className: className ? className : 'form-group',
    fullWidth: fullWidth ? fullWidth : true,
    icon: icon,
    label: label,
    helperText: helperText,
    errors: errors
  }
  if (mask)
    return (
      <InputMask mask={mask} alwaysShowMask={false} maskChar="" {...inputProps}>
        {inputProps => CustomFormControl(inputProps, formControlProps)}
      </InputMask>
    )
  return CustomFormControl(inputProps, formControlProps)
}

export default CustomInput

const CustomFormControl = (inputProps, formControlProps) => {
  const {
    icon,
    label,
    helperText,
    errors,
    className,
    fullWidth
  } = formControlProps
  return (
    <FormControl
      className={className}
      fullWidth={fullWidth}
      error={errors && errors[inputProps.name] ? true : false}
    >
      {label && (
        <InputLabel
          htmlFor={inputProps.name}
          shrink={inputProps.placeholder && label && true}
          className="form-control__label"
        >
          {label}
        </InputLabel>
      )}
      <Input
        {...inputProps}
        className={`form-control__input ${icon && 'with-icon with-icon--end'}`}
        endAdornment={
          icon && (
            <InputAdornment variant="filled" position="end" className="icon">
              {icon}
            </InputAdornment>
          )
        }
      />
      {(helperText || (errors && errors[inputProps.name])) && (
        <p
          className={`input-helper ${errors &&
            errors[inputProps.name] &&
            '--t-danger'}`}
        >
          {errors && errors[inputProps.name]
            ? errors[inputProps.name]
            : helperText}
        </p>
      )}
    </FormControl>
  )
}
