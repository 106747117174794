import axios from 'axios'

import { BASE_API_URL } from '../urls'
import { setHeadersToken } from '../../utils/helpers/store'
import { handleErrors } from '../../utils/helpers/errors'

export const loadPendingSurveys = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CHECK_PENDING_SURVEYS' })

    axios
      .get(`${BASE_API_URL}pending-surveys/`, { headers: setHeadersToken() })
      .then(res => {
        dispatch({ type: 'PENDING_SURVEYS_LOADED', payload: res.data })
      })
      .catch(error => {
        handleErrors(error, dispatch)
      })
  }
}
