import React from 'react'
import { Bar } from 'recharts'
import CustomComposedChart from './CustomComposedChart'

const SalesShiftChart = ({ data }) => {
  let shiftsData = []

  Object.values(data).forEach(item =>
    Object.keys(item).forEach(key => {
      if (['current', 'compare'].includes(key))
        Object.values(item[key].sales).length > 0 &&
          Object.values(item[key].sales).forEach(sale => {
            let idx = shiftsData.findIndex(
              shift => shift.slug === sale.shift.slug
            )
            if (idx < 0) shiftsData[sale.shift.slug] = sale
          })
    })
  )

  const shiftsBars = []
  Object.values(shiftsData).forEach(item => {
    let keys = ['compare', 'current']
    keys.forEach((key, index) => {
      let color = item.shift.bg_color,
        name = item.shift.name
      if (key === 'compare') {
        color = '#999'
        name += ' (anterior)'
      }
      shiftsBars.push(
        <Bar
          key={`${key}.sales.${item.shift.slug}.amount`}
          name={name}
          dataKey={`${key}.sales.${item.shift.slug}.amount`}
          fill={color}
          unit="€"
        />
      )
    })
  })

  return (
    <CustomComposedChart data={data} syncId="sales">
      {[...shiftsBars]}
    </CustomComposedChart>
  )
}

export default SalesShiftChart
